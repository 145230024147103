export const dateSplitter=(date:string)=>{
    return date.split("T")[0]
}


export const changeDateInArray=(array:any)=>{
    return array!== "empty" && array.map((customer:any)=>{
         customer.createAt=dateSplitter(customer.createAt)
         return customer
     })
 
 }

 export const changeDateInArrayInEvaluate=(array:any)=>{
    return array.map((customer:any)=>{
         customer.createdAt=dateSplitter(customer.createdAt)
         return customer
     })
 
 }


 const dateSplitterForRandevu=(date:string, part:number)=>{
    const dateSplit=date.split("T")
    if(part==1){
      return dateSplit[0]
    }else{
      return dateSplit[1]
    }
}


export const dateSplitterForRandevuArray=(rands:any)=>{
return rands.map((rand:any)=>{
rand.gun=dateSplitterForRandevu(rand.date, 1)
rand.saat=dateSplitterForRandevu(rand.date, 2)
return rand
})
}