import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllElans,
    getRegisteredElans,
    getUnregisteredElans,
    getElansforToday,
    getPaginateElanApi,
    getElansforChange,
} from "../../redux/actions";
import { DateFormat } from "../DateFormat";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { changeDateInArray } from "../functions";
import {
    ExcelExport,
    ExcelExportColumn,
} from "@progress/kendo-react-excel-export";

import { process } from "@progress/kendo-data-query";

export const Announce = () => {
    let dispatch = useDispatch();
    const { elanlar, paginateElanlar } = useSelector(
        (state: any) => state.data
    );

    const status = {
        0: "Təsdiq gözləyir",
        1: "Təsdiq edilmədi",
        2: "Təsdiq edildi",
        3: "Satıldı",
    }

    const [all, setAll] = useState(true);
    const [registered, setRegistered] = useState(false);
    const [nonRegister, setNonRegister] = useState(false);
    const [today, setToday] = useState(false);
    const [elanState, setElanState] = useState<
        "pending" | "reject" | "accept" | "sold" | null
    >(null);

    useEffect(() => {
        dispatch(getAllElans());
        dispatch(getPaginateElanApi(0));
        return () => {};
    }, []);

    const getRegisterElans = () => {
        setToday(false);
        setAll(false);
        setNonRegister(false);
        setRegistered(true);
        dispatch(getElansforChange(true, false, false, elanState));
    };

    const getNonRegisterElan = () => {
        setToday(false);
        setAll(false);
        setRegistered(false);
        setNonRegister(true);
        dispatch(getElansforChange(false, true, false, elanState));
    };

    const getElans = () => {
        setRegistered(false);
        setNonRegister(false);
        setToday(false);
        setAll(true);
        dispatch(getAllElans());
        dispatch(getElansforChange(false, false, false, elanState));
    };

    const buraxilishIliTaker = (dinamicField: any) => {
        let year;
        dinamicField.forEach((element: any) => {
            if (element.name == "Buraxilisili") {
                year = element.value;
            }
        });
        return year;
    };

    const getElansOfToday = () => {
        setAll(false);
        setRegistered(false);
        setNonRegister(false);
        setToday(true);
        dispatch(getElansforChange(false, false, true, elanState));
    };

    const paginate = (e: any) => {
        let currentPage = e.selected * 10 + 1;
        dispatch(getPaginateElanApi(currentPage));
    };

    const elanlarYearAdder = (elanlar: any) => {
        return elanlar.map((element: any) => {
            element["buraxilisIli"] = buraxilishIliTaker(
                element.dynamicFiledLists
            );
            return element;
        });
    };

    const data = process(elanlarYearAdder(changeDateInArray(elanlar)), {}).data;

    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
            _exporter.current.save();
        }
    };
    const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.currentTarget.value == "all") {
            setElanState(null);
            dispatch(getElansforChange(registered, nonRegister, today, null));
            return;
        }
        setElanState(
            e.currentTarget.value as
                | "pending"
                | "reject"
                | "accept"
                | "sold"
                | null
        );
        dispatch(
            getElansforChange(
                registered,
                nonRegister,
                today,
                e.currentTarget.value as
                    | "pending"
                    | "reject"
                    | "accept"
                    | "sold"
            )
        );
    };

    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
                <span className="title">Elanlar</span>
                <div className="nav-small">
                    <ul className="d-flex p-0 flex-wrap mt-lg-0 mt-3">
                        <li
                            className={all ? "active_nav" : ""}
                            onClick={getElans}
                        >
                            All
                        </li>
                        <li
                            className={registered ? "active_nav" : ""}
                            onClick={getRegisterElans}
                        >
                            Registered
                        </li>
                        <li
                            className={nonRegister ? "active_nav" : ""}
                            onClick={getNonRegisterElan}
                        >
                            Non-Registered
                        </li>
                        <li
                            className={today ? "active_nav" : ""}
                            onClick={getElansOfToday}
                        >
                            Today
                        </li>

                        <li className="elanSelect">
                            <select onChange={selectChange} name="" id="">
                                <option value="all">Hamısı</option>
                                <option value="1">Təstiq edilmədi</option>
                                <option value="2">Təstiq edildi</option>
                                <option value="3">Satıldı</option>
                                <option value="0">Təstiq gözləyir</option>
                            </select>
                        </li>
                        <div>
                            <button className="k-button" onClick={exportExcel}>
                                Export to Excel
                            </button>
                            <ExcelExport
                                data={data}
                                fileName="Elanlar.xlsx"
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                    field="id"
                                    title="ID"
                                    locked={true}
                                    width={60}
                                />
                                <ExcelExportColumn
                                    field="fullName"
                                    title="İstifadeci"
                                    width={100}
                                />

                                <ExcelExportColumn
                                    field="phoneNumber"
                                    title="Əlaqə nömrəsi"
                                    width={100}
                                />

                                <ExcelExportColumn
                                    field="title"
                                    title="marka adı"
                                    width={150}
                                />
                                <ExcelExportColumn
                                    field="buraxilisIli"
                                    title="buraxılış ili"
                                    width={80}
                                />
                                <ExcelExportColumn
                                    field="price"
                                    title="Qiyməti"
                                    cellOptions={{ format: "$#,##0.00" }}
                                    width={80}
                                    footerCellOptions={{
                                        wrap: true,
                                        textAlign: "center",
                                    }}
                                    groupFooterCellOptions={{
                                        textAlign: "right",
                                    }}
                                />
                                <ExcelExportColumn
                                    field="createAt"
                                    title="Yaranma tarixi"
                                    width={200}
                                />
                                <ExcelExportColumn
                                    field="Discontinued"
                                    title="Discontinued"
                                    hidden={true}
                                />
                            </ExcelExport>
                        </div>
                    </ul>
                </div>

                <table className="table table-sm table-bordered text-center">
                    <thead>
                        <tr>
                            <th scope="col">№</th>
                            <th scope="col">Tarix </th>
                            <th scope="col">Ad, Soyad</th>
                            <th scope="col">Əlaqə nömrəsi</th>
                            <th scope="col">Marka</th>
                            <th scope="col">İstehsal ili</th>
                            <th scope="col">Qiymət</th>
                            <th scope="col">Status</th>
                            <th scope="col">Əməliyyatlar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginateElanlar &&
                            paginateElanlar.map((elan: any, index: number) => (
                                <tr key={elan.id}>
                                    <th scope="row">{elan.id}</th>
                                    <td>
                                        <DateFormat date={elan.createAt} />
                                    </td>
                                    <td>{elan.fullName}</td>
                                    <td>{elan.phoneNumber}</td>
                                    {elan.productId != null && (
                                        <td>
                                            {" "}
                                            <a
                                                target="_blank"
                                                className="prName__anchor"
                                                href={
                                                    "https://otomall.az" +
                                                    `/${elan.markaName}/${elan.modelName}/${elan.productId}`
                                                }
                                            >
                                                {elan.markaName}{" "}
                                                {elan.modelName}
                                            </a>
                                        </td>
                                    )}
                                    {elan.productId == null && (
                                        <td>
                                            {" "}
                                            <p className="prName__anchor">
                                                {elan.markaName}{" "}
                                                {elan.modelName}
                                            </p>{" "}
                                        </td>
                                    )}
                                    <td>
                                        {buraxilishIliTaker(
                                            elan.dynamicFiledLists
                                        )}
                                    </td>
                                    <td>{elan.price}</td>
                                    <td>
                                        {elan.status == 0 && (
                                            <span>{status[0]}</span>
                                        )}
                                        {elan.status == 1 && (
                                            <span>{status[1]}</span>
                                        )}
                                        {elan.status == 2 && (
                                            <span>{status[2]}</span>
                                        )}
                                        {elan.status == 3 && (
                                            <span>{status[3]}</span>
                                        )}
                                    </td>
                                    <Link
                                        to={`/crm/elan/${elan.id}`}
                                        style={{
                                            color: "#212529",
                                            textDecoration: "none",
                                        }}
                                    >
                                        <td>
                                            <i className="fas fa-user-cog"></i>
                                        </td>
                                    </Link>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {elanlar.length > 10 ? (
                    <ReactPaginate
                        previousLabel={"<<<"}
                        nextLabel={">>>"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(elanlar.length / 10)}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={6}
                        onPageChange={(e) => paginate(e)}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-itme"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        activeClassName="active"
                    />
                ) : null}
            </div>
        </>
    );
};
