import React, {useEffect,useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllRandevuConfigApi, getAllRandevuAddress } from "../../redux/actions";
import { DateFormat } from "../DateFormat";


import '../../styles/randevuConfig.css'

export const RandevuConfig = () => {
    
    const dispatch = useDispatch();

    const {address,randevuConfig} = useSelector((state:any) => state.data)

    const [addressId, setAddressId] = useState(0)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [interval, setInterval] = useState(0)
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [breakStartTime, setBreakStartTime] = useState('')
    const [breakEndTime, setBreakEndTime] = useState('')


    useEffect(() => {
        dispatch(getAllRandevuAddress());
        dispatch(getAllRandevuConfigApi());
        return () => {
            
        }
    }, [])

    const selectStartDate = (e:any) => {
        setStartDate(e.target.value)
    }

    const selectEndDate = (e:any) => {
        setEndDate(e.target.value)
    }
    
    const selectInterval = (e:any) => {
        setInterval(e.target.value)
    }
    
    
    const selectStartTime = (e:any) => {
        setStartTime(e.target.value)
    }
    
    const selectEndTime = (e:any) => {
        setEndTime(e.target.value)
    }

    const breakstartTime = (e:any) => {
        setBreakStartTime(e.target.value)
    }
    
    const breakendTime = (e:any) => {
        setBreakEndTime(e.target.value)
    }

    const selectAddress = (e:any) => {
        setAddressId(e.target.value)
    }
    
    
    const Yarat = () => {
        let today = new Date()
        if (today.getMonth()+1 <10) {
            if (today.getDate() < 10) {
                var date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-0'+today.getDate();
            }else{
                var date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
            }
        }else{
            if (today.getDate() < 10) {
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-0'+today.getDate();
            }else{
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            }
        }

        var content = {
            'startDate':startDate,
            'endDate':endDate,
            'addressId':addressId,
            'interval':interval,
            'startTime':startTime,
            'endTime':endTime,
            'BreakstartTime':breakStartTime,
            'BreakEndTime':breakEndTime
        }

        console.log('form data =>', content);
        let data = {
            "startDate": startDate,
            "endDate": endDate,
            "startHour": date + 'T' +startTime,
            "endHours": date + 'T' +endTime,
            "startBreakTime": date + 'T' +breakStartTime,
            "endBreakTime": date + 'T' +breakStartTime,
            "timeInterval": Number(interval),
            "randevuAddressId": 1
          }
        

 
        fetch('https://otomallbusiness.digimall.az/api/RandevuConfiguration/CreateNewRandevuConfiguration',{
            method:"POST",
            headers:{
                'Content-Type': 'application/json',
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            },
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then(data=>console.log(data))

    }

    const deleteConfig = (id:any) => {

 
try{
       fetch(`https://otomallbusiness.digimall.az/api/RandevuConfiguration/DeleteRandevuConfiguration?Id=${id}`,{
          method:'DELETE',
          headers:{
            'Content-Type': 'application/json',
            'api-key':'e4246C59-4357-4C5E-A5A9-AF4307C4F751'
        }
      }).then(res=>res.json()).then(data=>{
          dispatch(getAllRandevuConfigApi())
         console.log(data)}).catch((err)=>{
             console.log(err, 'errr');
             
         })
         }catch(err){
             console.log(err);
          }
     };
    
    
    

    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
                <span className="title"> Randevu configuration</span>

                <div className="calendar__configuration mt-5 d-flex">

                    <div className="config__firstTab mx-3">
                        <div className="choose__address">
                            <label> Choose address: </label>
                            <select className="select text-center" onClick={selectAddress} aria-label="Default select example">
                                {address && address.map((address:any,index:any) => (
                                    <>
                                    <option key={index} value={address.id}>{address.adress}</option>
                                    </>
                                ))}
                            </select>
                        </div>
                        <div className="starting__date">
                            <label> Starting date: </label>
                            <input type="date" name="" id="" onChange={(e)=>selectStartDate(e)} className='text-center'/>
                        </div>
                        <div className="ending__date">
                            <label> Ending date: </label>
                            <input type="date" name="" id="" onChange={(e)=>selectEndDate(e)} className='text-center'/>
                        </div>
                        <div className="time__interval">
                            <label> Time interval: </label>
                            <input type="text" className='text-center' onChange={(e)=>selectInterval(e)} maxLength={2}/>
                        </div>
                    </div>

                    <div className="config__secondTab mx-3">
                        <div className="starting__time">
                            <label> Starting time: </label>
                            <input type="time" className='text-center without_ampm w-50' onChange={(e)=>selectStartTime(e)} placeholder='09:00' maxLength={5}/>
                        </div>
                        <div className="ending__time">
                            <label> Ending time: </label>
                            <input type="time" className='text-center without_ampm w-50' onChange={(e)=>selectEndTime(e)} placeholder='18:00' maxLength={5}/>
                        </div>
                        <div className="break__start--time">
                            <label> Break start time: </label>
                            <input type="time" className='text-center without_ampm w-50' onChange={(e)=>breakstartTime(e)} placeholder='13:00' maxLength={5}/>
                        </div>
                        <div className="break__end--time">
                            <label> Break end time: </label>
                            <input type="time" className='text-center without_ampm w-50' onChange={(e)=>breakendTime(e)} placeholder='14:00' maxLength={5}/>
                        </div>
                        <button type="button" onClick={Yarat} className="btn btn-danger btn-sm mx-3">Yarat</button>
                    </div>
                </div>

                <div className="mt-5">
                    
                    <h3>Randevular</h3>
                </div>
                <table className="table table-sm table-bordered text-center">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Address</th>
                        <th scope="col">Start date</th>
                        <th scope="col">End date</th>
                        <th scope="col">Time interval</th>
                        <th scope="col">Əməliyyatlar</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                        console.log(randevuConfig, 'randevuConfigrandevuConfig')
                        }
                        {randevuConfig && randevuConfig.map((config:any, index:any)=>(
                            <tr>
                                <th scope="row">{index+1}</th>
                                <td>{config.addressName}</td>
                                <td><DateFormat date={config.startDate}/></td>
                                <td><DateFormat date={config.endDate}/></td>
                                <td>{config.timeInterval}</td>
                                <td>
                                    <span>
                                    <i className="fas fa-trash-alt" style={{cursor:'pointer'}} onClick={()=>deleteConfig(config.id)}></i>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
