import React, { useState, useEffect } from 'react'
import { getDeyerlendirmeDetailApi } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import '../../styles/Product.css'
import '../../styles/ProductInfo.css'
import Rectangle from "../../icons/rectangle86.svg";
import GasStation from "../../icons/gas-station.svg";
import Calendar from "../../icons/calendar.svg";
import Gasoline from "../../icons/gasoline.svg";
import Shifter from "../../icons/shifter.svg";
import Speedometr from "../../icons/speedometer.svg";
import Speedometr2 from "../../icons/speedometer2.svg";
import Transmission from "../../icons/transmission.svg";
import CarPainting from "../../icons/car-painting.svg";
import TrafficJam from "../../icons/traffic-jam.svg";
import  Wheel  from "../../icons/wheel.svg";
import Abs from "../../icons/abs.svg";
import CarWash from "../../icons/car-wash.svg";
import Handbreak from "../../icons/handbrake.svg";
import Light from "../../icons/light.svg";
import Snowflake from "../../icons/snowflake.svg";
import Rightchevron from "../../icons/right-chevron.svg";
import FingerPrint from "../../icons/fingerprint 1.svg";
import LyukIcon from "../../icons/lift 1.svg";
import DeriSalonIcon from "../../icons/car 10.svg";
import Ventilyasiya from "../../icons/both 1.svg";
import Kamera from "../../icons/camera 1.svg";
import Start from "../../icons/carkey 1.svg";
import Perde from "../../icons/back 1.svg";
import Parking from "../../icons/parking.svg";
import Seat from "../../icons/seat 1.svg";


export const ValuationDetail = () => {

    let dispatch = useDispatch();
    const { deyerlendirme } = useSelector((state:any)=>state.data);
    const {id}:any = useParams();


    const [active, setActive] = useState(false);
    const [active2, setActive2] = useState(false)

    const [yungulLehm, setYungulLehm] = useState('false')
    const [abs, setAbs] = useState('false')
    const [yagisSensoru, setYagisSensoru] = useState('false')
    const [merkeziQapanma, setMerkeziQapanma] = useState('false')
    const [ksenon, setKsenon] = useState('false')
    const [kondisioner, setKondisioner] = useState('false')
    const [aciqlama, setAciqlama] = useState('');
    const [keylessGo, setKeylessGo] = useState('')
    const [lyuk, setLyuk] = useState('')
    const [deriSalon, setDeriSalon] = useState('')
    const [oturacaqlarinVentilyasiyasi, setOturacaqlarinVentilyasiyasi] = useState('');
    const [arxaKamera, setArxaKamera] = useState('')
    const [startStop, setStartStop] = useState('')
    const [yanPerdeler, setYanPerdeler] = useState('')
    const [parkRadari, setParkRadari] = useState('')
    const [monitor, setMonitor] = useState('')
    const [oturacaqlarinIsidilmesi, setOturacaqlarinIsidilmesi] = useState('')

    useEffect(() => {
    dispatch(getDeyerlendirmeDetailApi(id))
    }, [])
    

    

    const toggleClass2 = () => {

        deyerlendirme[0].dynamicFiledLists.forEach((element:any)=>{

            if(element.name=='Yüngül lehimli diskl?r'){
                setYungulLehm(element.value)
            }
            if(element.name=='M?rk?zi qapanma'){
                setYungulLehm(element.value)
            }
            if(element.name=='ABS'){
                setAbs(element.value)
            }
            if(element.name=='Ksenon lampalar'){
                setKsenon(element.value)
            }
            if(element.name=='Kondisioner'){
                setKondisioner(element.value)
            }
            if(element.name=='Park radarı'){
                setParkRadari(element.value)
            }
            if(element.name=='Monitor'){
                setMonitor(element.value)
            }
            if(element.name=='Oturacaqların isidilm?si'){
                setOturacaqlarinIsidilmesi(element.value)
            }

            if(element.name=='Yan p?rd?l?r'){
                setYanPerdeler(element.value)
            }
            if(element.name=='D?ri salon'){
                setDeriSalon(element.value)
            }
            if(element.name=='Yağış sensoru'){
                setYagisSensoru(element.value)
            }
            if(element.name=='Keyless Go'){
                setKeylessGo(element.value)
            }
            if(element.name=='Lyuk'){
                setLyuk(element.value)
            }
            if(element.name=='Oturacaqların ventilyasiyası'){
                setOturacaqlarinVentilyasiyasi(element.value)
            }
            if(element.name=='Arxa görüntü kamerası'){
                setArxaKamera(element.value)
            }
            if(element.name=='Start/Stop'){
                setStartStop(element.value)
            }

         })



        // setYungulLehm(deyerlendirme[0].dynamicFiledLists[22].value)
        // setMerkeziQapanma(deyerlendirme[0].dynamicFiledLists[19].value)
        // setAbs(deyerlendirme[0].dynamicFiledLists[10].value)
        // setKsenon(deyerlendirme[0].dynamicFiledLists[18].value)
        // setKondisioner(deyerlendirme[0].dynamicFiledLists[20].value)
        // (deyerlendirme[0].dynamicFiledLists[11].value)
        // (deyerlendirme[0].dynamicFiledLists[12].value)
        // (deyerlendirme[0].dynamicFiledLists[16].value)
        // (deyerlendirme[0].dynamicFiledLists[7].value)
        // (deyerlendirme[0].dynamicFiledLists[13].value)
        // (deyerlendirme[0].dynamicFiledLists[17].value)
        // (deyerlendirme[0].dynamicFiledLists[14].value)
        // setYanPerdeler(deyerlendirme[0].dynamicFiledLists[8].value)
        // setParkRadari(deyerlendirme[0].dynamicFiledLists[21].value)
        // setMonitor(deyerlendirme[0].dynamicFiledLists[15].value)
        // setOturacaqlarinIsidilmesi(deyerlendirme[0].dynamicFiledLists[9].value)

        setActive2(!active2);
        
    }

    const [solOnQanad, setSolOnQanad] = useState('')
    const [solOnQapi, setSolOnQapi] = useState('')
    const [solArxaQapi, setSolArxaQapi] = useState('')
    const [solArxaQanad, setSolArxaQanad] = useState('')
    const [onKapot, setOnKapot] = useState('')
    const [onBufer, setOnBufer] = useState('')
    const [damColor, setDamColor] = useState('')
    const [sagArxaQanad, setSagArxaQanad] = useState('')
    const [sagOnQanad, setSagOnQanad] = useState('')
    const [sagOnQapi, setSagOnQapi] = useState('')
    const [sagArxaQapi, setSagArxaQapi] = useState('')
    const [arxaKapot, setArxaKapot] = useState('')
    const [arxaBufer, setArxaBufer] = useState('')
    const [muherrik_, setMuherrik] = useState('')


    const valuFromDinamic=(valu:string, dinamic:any)=>{
     
        let returnValue=''
        dinamic.forEach((element:any) => {
             if(element.name==valu){
                returnValue=element.value
             }
    
        });
         return returnValue
    }

    const sol_on_qanad = deyerlendirme[0] ? valuFromDinamic("Sağ ön qanad",deyerlendirme[0].dynamicFiledLists)   : null
    const sol_on_qapi = deyerlendirme[0] ? valuFromDinamic("Sol ön qapı",deyerlendirme[0].dynamicFiledLists) : null
    const sag_arxa_qapi = deyerlendirme[0] ?  valuFromDinamic("Sağ arxa qapı",deyerlendirme[0].dynamicFiledLists) : null
    const on_kapot = deyerlendirme[0] ? valuFromDinamic("Ön kapot",deyerlendirme[0].dynamicFiledLists) : null
    // burdan
    const sol_arxa_qanad = deyerlendirme[0] ? valuFromDinamic("Sol arxa qanad",deyerlendirme[0].dynamicFiledLists) : null
    const on_bufer = deyerlendirme[0] ?  valuFromDinamic("Ön bufer",deyerlendirme[0].dynamicFiledLists) : null
    const dam = deyerlendirme[0] ? valuFromDinamic("dam",deyerlendirme[0].dynamicFiledLists) : null
    const sag_arxa_qanad = deyerlendirme[0] ?valuFromDinamic("Sağ arxa qanad",deyerlendirme[0].dynamicFiledLists) : null
    const sag_on_qanad = deyerlendirme[0] ?valuFromDinamic("Sağ ön qanad",deyerlendirme[0].dynamicFiledLists) : null
    const sag_on_qapi = deyerlendirme[0] ? valuFromDinamic("Sağ ön qapı",deyerlendirme[0].dynamicFiledLists) : null
    const sol_arxa_qapi = deyerlendirme[0] ? valuFromDinamic("Sol arxa qapı",deyerlendirme[0].dynamicFiledLists): null
    const arxa_kapot = deyerlendirme[0] ? valuFromDinamic("Arxa kapot",deyerlendirme[0].dynamicFiledLists) : null
    const arxa_bufer = deyerlendirme[0] ? valuFromDinamic("Arxa bufer",deyerlendirme[0].dynamicFiledLists) : null
    const muherrik = deyerlendirme[0] ? valuFromDinamic("Müh?rrik",deyerlendirme[0].dynamicFiledLists) : null
  

     const toggleClass = () => {

        if (sol_on_qanad == 'Rəngli') {
            setSolOnQanad('rengli')
        }else if(sol_on_qanad == 'Dəyişdirilib'){
            setSolOnQanad('deyisdirilib')
        }else{
            setSolOnQanad('orijinal')
        }

        if (muherrik == 'Rəngli') {
            setMuherrik('rengli')
        }else if(muherrik == 'Dəyişdirilib'){
            setMuherrik('deyisdirilib')
        }else{
            setMuherrik('orijinal')
        }

        if (sol_on_qapi == 'Rəngli') {
            setSolOnQapi('rengli')
        }else if(sol_on_qapi == 'Dəyişdirilib'){
            setSolOnQapi('deyisdirilib')
        }else{
            setSolOnQapi('orijinal')
        }

        if (sol_arxa_qapi == 'Rəngli') {
            setSolArxaQapi('rengli')
        }else if(sol_arxa_qapi == 'Dəyişdirilib'){
            setSolArxaQapi('deyisdirilib')
        }else{
            setSolArxaQapi('orijinal')
        }

        if (on_kapot == 'Rəngli') {
            setOnKapot('rengli')
        }else if(on_kapot == 'Dəyişdirilib'){
            setOnKapot('deyisdirilib')
        }else{
            setOnKapot('orijinal')
        }

        if (on_bufer == 'Rəngli') {
            setOnBufer('rengli')
        }else if(on_bufer == 'Dəyişdirilib'){
            setOnBufer('deyisdirilib')
        }else{
            setOnBufer('orijinal')
        }

        if (dam == 'Rəngli') {
            setDamColor('rengli')
        }else if(dam == 'Dəyişdirilib'){
            setDamColor('deyisdirilib')
        }else{
            setDamColor('orijinal')
        }

        if (sag_arxa_qanad == 'Rəngli') {
            setSagArxaQanad('rengli')
        }else if(sag_arxa_qanad == 'Dəyişdirilib'){
            setSagArxaQanad('deyisdirilib')
        }else{
            setSagArxaQanad('orijinal')
        }

        if (sol_arxa_qanad == 'Rəngli') {
            setSolArxaQanad('rengli')
        }else if(sol_arxa_qanad == 'Dəyişdirilib'){
            setSolArxaQanad('deyisdirilib')
        }else{
            setSolArxaQanad('orijinal')
        }

        if (sag_on_qapi == 'Rəngli') {
            setSagOnQapi('rengli')
        }else if(sag_on_qapi == 'Dəyişdirilib'){
            setSagOnQapi('deyisdirilib')
        }else{
            setSagOnQapi('orijinal')
        }

        if (sag_on_qanad == 'Rəngli') {
            setSagOnQanad('rengli')
        }else if(sag_on_qanad == 'Dəyişdirilib'){
            setSagOnQanad('deyisdirilib')
        }else{
            setSagOnQanad('orijinal')
        }

        if (sag_arxa_qapi == 'Rəngli') {
            setSagArxaQapi('rengli')
        }else if(sag_arxa_qapi == 'Dəyişdirilib'){
            setSagArxaQapi('deyisdirilib')
        }else{
            setSagArxaQapi('orijinal')
        }

        if (arxa_kapot == 'Rəngli') {
            setArxaKapot('rengli')
        }else if(arxa_kapot == 'Dəyişdirilib'){
            setArxaKapot('deyisdirilib')
        }else{
            setArxaKapot('orijinal')
        }

        if (arxa_bufer == 'Rəngli') {
            setArxaBufer('rengli')
        }else if(arxa_bufer == 'Dəyişdirilib'){
            setArxaBufer('deyisdirilib')
        }else{
            setArxaBufer('orijinal')
        }
        setActive(!active);
    }

    return (
        <>
   
        <div className="col-lg-10 col-12 p-0">
        <div className="product-info-container">
              <div className="info-top d-flex">
                  <h2 className="product-name">{deyerlendirme[0]? deyerlendirme[0].markaName: null} {deyerlendirme[0]? deyerlendirme[0].modelName: null}</h2>
<div className='d-flex align-center'>

<div className='d-flex'>
{deyerlendirme[0]&&
<p className='namee'>{deyerlendirme[0]['fullName']}</p>
}

{deyerlendirme[0] &&
   <p className='namee'>{deyerlendirme[0]['phoneNumber']}</p>}
</div>

                   <div className="product-price m-auto">
                      <img src={Rectangle}/>
                      <p className="product-price">{deyerlendirme[0]? deyerlendirme[0].price : null} 
                      <span className="currency-span">AZN</span>
                      </p>
 
                  </div>
                   </div>
               </div>
              <div className="product-tags">
                  { 
                    //   console.log(valuFromDinamic("Yanacaq tipi", deyerlendirme[0]), 'deyerlendirme[0]')
                  }
                  <ul className="tags-ul">
                       <li className="tag-li">
                          <img src={GasStation}/>
                           {deyerlendirme[0] && valuFromDinamic("Yanacaq tipi", deyerlendirme[0].dynamicFiledLists)}
                       </li>
                      <li className="tag-li">
                          <img src={Calendar}/>
                          {deyerlendirme[0] && deyerlendirme[0]['year']}
                       </li>
                      <li className="tag-li">
                          <img src={Gasoline}/>
                          {deyerlendirme[0] && valuFromDinamic("Müh?rrik h?cmi", deyerlendirme[0].dynamicFiledLists)} L
                      </li>

                      <li className="tag-li">
                          <img src={Shifter}/>
                          {deyerlendirme[0] && valuFromDinamic("Sür?tl?r qutusu", deyerlendirme[0].dynamicFiledLists)}
                      </li>

                      <li className="tag-li">
                          <img src={Speedometr}/>
                          {deyerlendirme[0] && valuFromDinamic("Yurus", deyerlendirme[0].dynamicFiledLists)} km
                      </li>
                      <li className="tag-li">
                          <img src={Transmission}/> 
                           {deyerlendirme[0] && valuFromDinamic("Ötürücü", deyerlendirme[0].dynamicFiledLists)}
                      </li>
                      <li className="tag-li">
                          <img src={CarPainting}/>
                          {deyerlendirme[0] && valuFromDinamic("R?ng", deyerlendirme[0].dynamicFiledLists)}
                      </li>
                      <li className="tag-li">
                      <img src={TrafficJam}/>
                      
                      {deyerlendirme[0] && valuFromDinamic("Ban növü", deyerlendirme[0].dynamicFiledLists)}
                      </li>
                  </ul>
              </div>
              </div>
              <div className="product-tabs">
     
              <div className="tab-group">
    <div className={!active ? "tab-item" : "tab-item active"} onClick={toggleClass}>
             <span>Vəziyyəti</span>
            <img src={Rightchevron}/>
         </div>
         <div className="tab-item-info mt-5">
        <div className="color-tags-container">
 
             <ul className="color-tags">
                 <li className={solOnQanad}>Sol ön qanad</li>
                 <li className={solOnQapi}>Sol ön qapı</li>
                 <li className={solArxaQapi}>Sol arxa qapı</li>
                 <li className={solArxaQanad}>Sol arxa qanad</li>
                 <li className={onKapot}>Ön kapot</li>
                 <li className={onBufer}>Ön bufer</li>
                 <li className={damColor}>Dam</li>
                 <li className={sagArxaQanad}>Sağ arxa qanad</li>
                 <li className={sagOnQanad}>Sağ ön qanad</li>
                 <li className={sagOnQapi}>Sağ ön qapı</li>
                 <li className={sagArxaQapi}>Sağ arxa qapı</li>
                 <li className={arxaKapot}>Arxa kapot</li>
                 <li className={arxaBufer}>Arxa bufer</li>
                 <li className={muherrik_}>Muherrik</li>
             </ul>
        </div>
         </div>
    </div>
    </div>

    <div className='product-tabs'>
              <div className="tab-group">
        <div className={!active2 ? "tab-item" : "tab-item active"} onClick={toggleClass2} style={{backgroundColor:'#f8f8f8'}}>
             <span>Avtomobil haqqında</span>
            <img src={Rightchevron}/>
         </div>
        <div className="tab-item-info">
       <p> {aciqlama}</p>
        <div className="product-tags">
                  <ul className="tags-ul">
                      {console.log('yungul lehm : ', yungulLehm)}
                  {
                          yungulLehm =='true' ? (
                            <li className="tag-li">
                                <img src={Wheel}/>
                                Yüngül lehimli disklər
                            </li>
                          ):null
                      }
                      {
                          abs == 'true' ? (
                            <li className="tag-li">
                                <img src={Abs}/>
                                ABS
                            </li>
                          ):null
                      }
                      {
                          yagisSensoru == 'true' ? (

                            <li className="tag-li">
                                <img src={CarWash}/>
                                Yağış sensoru
                            </li>
                          ):null
                      }
                      {
                          merkeziQapanma == 'true' ? (
                            <li className="tag-li">
                                <img src={Handbreak}/>
                                Mərkəzi qapanma
                            </li>
                          ):null
                      }
                      {
                          ksenon == 'true' ? (
                            <li className="tag-li">
                                <img src={Light}/>
                                Ksenon Lampalar
                            </li>
                          ):null
                      }
                      {
                        keylessGo == 'true' ? (
                          <li className="tag-li">
                              <img src={FingerPrint}/>
                              Keyless go
                          </li>
                        ):null
                    }
                        {
                          kondisioner == 'true' ? (
                            <li className="tag-li">
                                <img src={Snowflake}/>
                                Kondisioner
                            </li>
                          ):null
                      }
                      {
                          lyuk == 'true' ? (
                            <li className="tag-li">
                                <img src={LyukIcon}/>
                                Lyuk
                            </li>
                          ):null
                      }
                      {
                          deriSalon == 'true' ? (
                            <li className="tag-li">
                                <img src={DeriSalonIcon}/>
                                Deri Salon
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinVentilyasiyasi == 'true' ? (
                            <li className="tag-li">
                                <img src={Ventilyasiya}/>
                                Oturacaqlarin ventilyasiyasi
                            </li>
                          ):null
                      }
                      {
                          arxaKamera == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Arxa goruntu kamerasi
                            </li>
                          ):null
                      }
                      {
                          startStop == 'true' ? (
                            <li className="tag-li">
                                <img src={Start}/>
                                Start/Stop
                            </li>
                          ):null
                      }
                      {
                          yanPerdeler == 'true' ? (
                            <li className="tag-li">
                                <img src={Perde}/>
                                Yan perdeler
                            </li>
                          ):null
                      }
                      {
                          parkRadari == 'true' ? (
                            <li className="tag-li">
                                <img src={Parking}/>
                                Park radari
                            </li>
                          ):null
                      }
                      {
                          monitor == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Monitor
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinIsidilmesi == 'true' ? (
                            <li className="tag-li">
                                <img src={Seat}/>
                                Oturacaqlarin isidilmesi
                            </li>
                          ):null
                      }
                  </ul>
              </div>
        </div>
        </div>
</div>       
              </div>
              
              
              </>
    )
}
