import React from 'react'
import { useDispatch } from 'react-redux';
import { getCustomerConnections } from "../../redux/actions";
import { DateFormat } from "../DateFormat";

export const PhoneTable = ({customers}:any) => {
     let dispatch = useDispatch();

    const assignMe = (elan:any) => {
        let userId:any = localStorage.getItem('userId');
        let token:any = localStorage.getItem('token')
        
        fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}&userId=${userId}`,{
            method:'PUT',
            headers:{
              'Bearer':String(token),
              'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        }).then(res=>res.json()).then(data=>dispatch(getCustomerConnections()))
        
        
      };
  
  
      const unAssignMe = (elan:any) => {
          let userId:any = localStorage.getItem('userId');
          let token:any = localStorage.getItem('token')
          fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}&userId=${userId}`,{
            method:'PUT',
            headers:{
              'Bearer':String(token),
              'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        }).then(res=>res.json()).then(data=>dispatch(getCustomerConnections()))
          
      };
 
  return (
    <>
        {customers && customers.map((elan:any,index:any)=>(
             <tr key={elan.id}>
             <th scope="row">{index+1}</th>
             {elan.fullName ? (
                 <td>{elan.fullName}</td>

             ):(
                 <td>{elan.name} {elan.family}</td>
              )}
              {elan.phone?(
                 <td>{elan.phone}</td>
              ):(
                 <td>{elan.cellPhone}</td>
              )}
             <td> {elan.operatorName||elan.operator} </td>
             <td> <a target="_blank" className='prName__anchor' href={"https://otomall.az/"+`/${elan.markaName}/${elan.modelName}/${elan.productId}`}>{elan.productName} </a> </td>
          
             <td> <DateFormat date={elan.createAt} /> </td>
             <td> Sizə zəng edək </td>
             <td className='btn-group dropend'><i className="fas fa-user-cog dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ></i>
             {
                 elan.operatorName == null ?(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item' onClick={()=>assignMe(elan.id)}>təyin et</button>
                     </ul>
                 ):(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item' onClick={()=>unAssignMe(elan.id)}>təyinatı ləğv et</button>
                     </ul>
                 )
             }
             </td>
         </tr>
        ))}
    </>
  )
}
