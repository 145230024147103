import React, {useEffect, useState} from 'react'
import { DateFormat } from "../DateFormat";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerConnections,getCreditMuracietler,getWhatsappMuracietler ,getTestDriveApi, getSifarishApi, getOneMinuteMuracietler } from "../../redux/actions";
import { Modal,Button } from "react-bootstrap";

import { KreditTable } from "./KreditTable";
import { OrderTable } from "./OrderTable";
import { PhoneTable } from "./PhoneTable";
import { TestDriveTable } from "./TestDriveTable";
import { OneMinuteTable } from "./OneMinuteTable";
import { WhatsappTable } from "./WhatsappTable";
import {changeDateInArray} from "../functions"

import {
    ExcelExport,
    ExcelExportColumn
  } from "@progress/kendo-react-excel-export";
    
  import {
    process
  } from "@progress/kendo-data-query";



export const CustomerConnections = () => {
     let dispatch = useDispatch();
    const {customerConnections} = useSelector((state:any) => state.data)
  
    useEffect(() => {
        dispatch(getWhatsappMuracietler())
        return () => {
            
        }
    }, [])

    const [show, setShow] = useState(false);
    const [whatsapp, setWhatsapp] = useState(true)
    const [phone, setPhone] = useState(false)
    const [testDrive, setTestDrive] = useState(false);
    const [sifarish, setSifarish] = useState(false);
    const [credit, setCredit] = useState(false);
    const [oneMinute, setOneMinute] = useState(false);
    const [fileName, setFileName] = useState("WhatsappMuracietler");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const wpMuracietler = () => {
        dispatch(getWhatsappMuracietler())
        setWhatsapp(true)
        setPhone(false)
        setTestDrive(false)
        setCredit(false)
        setSifarish(false)
        setOneMinute(false)
        setFileName("WhatsappMuracietler")
    }

    const phoneMuracietler = () => {
        dispatch(getCustomerConnections())
        setPhone(true)
        setWhatsapp(false)
        setSifarish(false)
        setCredit(false)
        setTestDrive(false)
        setOneMinute(false)
        setFileName("telefonMuracietler")

    }
    
    const testDriveMuracietler = () => {
        dispatch(getTestDriveApi())
        setTestDrive(true)
        setPhone(false)
        setSifarish(false)
        setCredit(false)
        setWhatsapp(false)
        setOneMinute(false)
        setFileName("TestDriveMuracietler")

    }

    const sifarishMuracietler = () => {
        dispatch(getSifarishApi())
        setSifarish(true)
        setPhone(false)
        setWhatsapp(false)
        setCredit(false)
        setTestDrive(false)
        setOneMinute(false)
        setFileName("SifarishMuracietler")

    };

    const creditMuracietler = () => {
        dispatch(getCreditMuracietler())
        setSifarish(false)
        setPhone(false)
        setWhatsapp(false)
        setTestDrive(false)
        setCredit(true)
        setOneMinute(false)
        setFileName("CreditMuracietlet")
     };
    
const birDeqiqeClick=()=>{
    
    dispatch(getOneMinuteMuracietler())
    setSifarish(false)
    setPhone(false)
    setWhatsapp(false)
    setTestDrive(false)
    setCredit(false)
    setOneMinute(true)
    setFileName("BirdeqiqeMuraciet")
}
 
    const data = process(changeDateInArray(customerConnections), {
    }).data;


    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
        _exporter.current.save();
        }
    };
 

    const assignMe = (elan:any) => {
      let userId:any = localStorage.getItem('userId');
      let token:any = localStorage.getItem('token')
      if (whatsapp == true) {
          fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}&userId=${userId}`,{
              method:'PUT',
              headers:{
                'Bearer':String(token),
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
          }).then(res=>res.json()).then(data=>dispatch(getWhatsappMuracietler()))
      }else if(phone == true){
        fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}&userId=${userId}`,{
            method:'PUT',
            headers:{
              'Bearer':String(token),
              'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        }).then(res=>res.json()).then(data=>dispatch(getCustomerConnections()))
      }else if(testDrive == true){
          fetch(`https://otomallbusiness.digimall.az/api/VisitRequest/AssignVisitRequest`,{
                method:'PUT',
                headers:{
                    'Content-Type': 'application/json',
                    'Bearer':String(token),
                    'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
                },
                body:JSON.stringify({
                    "id": elan,
                    "userId": userId
                })
          }).then(res=>res.json()).then(data=>console.log(data))
      }
      
    };


    const unAssignMe = (elan:any) => {
        let userId:any = localStorage.getItem('userId');
        let token:any = localStorage.getItem('token')
        if (whatsapp ==true) {
            fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}`,{
              method:'PUT',
              headers:{
                'Bearer':String(token),
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
          }).then(res=>res.json()).then(data=>dispatch(getWhatsappMuracietler()))
        }else if (phone == true) {
            fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection?Id=${elan}`,{
            method:'PUT',
            headers:{
              'Bearer':String(token),
              'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        }).then(res=>res.json()).then(data=>dispatch(getCustomerConnections()))
        }else if (testDrive == true) {
            fetch(`https://otomallbusiness.digimall.az/api/VisitRequest/AssignVisitRequest`,{
                method:'PUT',
                headers:{
                    'Content-Type': 'application/json',
                    'Bearer':String(token),
                    'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
                },
                body:JSON.stringify({
                    "id": elan
                })
          }).then(res=>res.json()).then(data=>dispatch(getTestDriveApi()))
        }
    };
     
 
    return (
        <>
            <div className='col-lg-10 col-12 p-0 table-responsive'>
            <span className="title">Müraciətlər </span>
                <div className="nav-small">
                    <ul className="d-flex p-0 flex-wrap mt-lg-0 mt-3">
                        <li className={whatsapp ? 'active_nav' : ''} onClick={wpMuracietler} >
                            WP
                        </li>
                        <li className={phone ? 'active_nav' : ''} onClick={phoneMuracietler}>
                            Phone
                        </li>
              
                        <li className={credit ? 'active_nav' : ''} onClick={creditMuracietler}>
                            Kredit
                        </li>
                        <li className={oneMinute ? 'active_nav' : ''} onClick={birDeqiqeClick}>
                          Bir dəqiqə
                        </li>
                        <div>
                <button className="k-button" onClick={exportExcel}>
                    Export to Excel
                </button>

 

                 <ExcelExport
                    data={data}
                    fileName={fileName+".xlsx"}
                    ref={_exporter}
                >
                    <ExcelExportColumn
                    field="id"
                    title="ID"
                    locked={true}
                    width={60}
                    />
 
{(!sifarish && !testDrive) &&  <ExcelExportColumn
                    field="fullName"
                    title="Ad"
                    width={100}
                    />
}

            { (!sifarish && !testDrive) && <ExcelExportColumn
                    field="phone"
                    title="Əlaqə telefonu"
                    width={100}
                    />}
                    
                     <ExcelExportColumn
                    field="operatorName"
                    title="Operatr name"
                    width={100}
                    />
          { (sifarish || testDrive) && <ExcelExportColumn
                    field="cellPhone"
                    title="Əlaqə telefonu"
                    width={100}
                    />}
      { (credit || testDrive) && <ExcelExportColumn
                    field="markaName"
                    title="marka adı"
                    width={100}
                    />}
                    
{(credit || testDrive) && <ExcelExportColumn
                    field="modelName"
                    title="model adı"
                    width={100}
                    />}


{ (sifarish || testDrive) && <ExcelExportColumn
                    field="name"
                    title="adı"
                    width={100}
                    />}
               { (sifarish || testDrive) && <ExcelExportColumn
                    field="family"
                    title="Soyadı"
                    width={100}
                    />}
         {/* setTestDrive(true)
        setPhone(false)
        setSifarish(false)
        setCredit(false)
        setWhatsapp(false) */}
                   {phone &&
              <ExcelExportColumn
                    field="productName"
                    title="Marka"
                    width={100}
                    />}

                     <ExcelExportColumn field="createAt" title="tarix" />
                    <ExcelExportColumn
                    field="Discontinued"
                    title="Discontinued"
                    hidden={true}
                    />



                </ExcelExport>
                        </div>
                    </ul>
                </div>

                <table className="table table-sm table-bordered text-center">
                    <thead>

                        {
                            whatsapp ? (
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col">Ad, Soyad </th>
                                    <th scope="col">Əlaqə telefonu </th>
                                    <th scope="col">Operator</th>
                                    <th scope="col">Tarix</th>
                                    <th scope="col">Action</th>
                                </tr>
                            ):null
                        }
                        {
                            sifarish ? (
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col">Ad, Soyad </th>
                                    <th scope="col">Əlaqə telefonu</th>
                                    <th scope="col">Operator</th>
                                    <th scope="col">Tarix</th>
                                    <th scope="col">Sifariş ver</th>
                                    <th scope="col">Action</th>
                                </tr>
                            ):(
                                null
                            )
                        }
                        {
                            credit ? (
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col">Ad, Soyad </th>
                                    <th scope="col">Əlaqə telefonu</th>
                                    <th scope="col">Operator</th>
                                    <th scope="col">Marka</th>
                                    <th scope="col">Tarix</th>
                                    <th scope="col">Kredit sifariş edin</th>
                                    <th scope="col">Action</th>
                                </tr>
                            ):(null)
                        }
                        
                        {testDrive ? (
                            <tr>
                            <th scope="col">№</th>
                            <th scope="col">Ad, Soyad </th>
                            <th scope="col">Əlaqə telefonu</th>
                            <th scope="col">Operator</th>
                            <th scope="col">Marka</th>
                            <th scope="col">Model</th>
                            <th scope="col">Tarix</th>
                            <th scope="col">Action</th>
                        </tr>
                        ):null}

                        {
                            phone ? (
                                <tr>
                            <th scope="col">№</th>
                            <th scope="col">Ad, Soyad </th>
                            <th scope="col">Əlaqə telefonu</th>
                            <th scope="col">Operator</th>
                            <th scope="col">Marka</th>
                            <th scope="col">Tarix</th>
                            <th scope="col">Sizə zəng edək </th>
                            <th scope="col">Action</th>
                        </tr>
                            ):null
                        }
                    {
                            oneMinute ? (
                        <tr>
                            <th scope="col">№</th>
                            {/* <th scope="col"> Ad </th> */}
                            <th scope="col">Əlaqə telefonu</th>
                            <th scope="col">Sizə zəng edək </th>
                            <th scope="col">Action</th>
                        </tr>
                            ):null
                        }
                    </thead>
                    <tbody>
                        {/* {customerConnections && customerConnections.map((elan:any,index:number) =>(
                        <tr key={elan.id}>
                            <th scope="row">{index+1}</th>
                            {elan.fullName ? (
                                <td>{elan.fullName}</td>

                            ):(
                                <td>{elan.name} {elan.family}</td>

                            )}

                            {elan.phone?(
                                <td>{elan.phone}</td>

                            ):(
                                <td>{elan.cellPhone}</td>

                            )}
                            <td> {elan.operatorName||elan.operator} </td>
                            <td> marka </td>
                            <td> model </td>
                            <td> marka </td>
                            
                            <td className='btn-group dropend'><i className="fas fa-user-cog dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ></i>
                            {
                                elan.operatorName == null ?(
                                    <ul className='dropdown-menu'>
                                        <button className='dropdown-item' onClick={()=>assignMe(elan.id)}>təyin et</button>
                                    </ul>
                                ):(
                                    <ul className='dropdown-menu'>
                                        <button className='dropdown-item' onClick={()=>unAssignMe(elan.id)}>təyinatı ləğv et</button>
                                    </ul>
                                )
                            }
                            </td>
                        </tr>
                        ))} */}

                        {
                            whatsapp ? (
                                <WhatsappTable customers={customerConnections} />
                            ):null
                        }

                        {
                            credit ? (
                                <KreditTable customers={customerConnections} />
                            ):null
                        }

                        {
                            sifarish ? (
                                <OrderTable customers={customerConnections} />
                            ):null
                        }

                        {
                            phone ? (
                                <PhoneTable customers={customerConnections} />
                            ):null
                        }

                        {
                            testDrive ? (
                                <TestDriveTable customers={customerConnections}/>
                            ):null
                        }
                           {
                            oneMinute ? (
                                <OneMinuteTable customers={customerConnections}/>
                            ):null
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
