import React, { useState, useEffect } from "react";
import { Header } from "../header/Header";
import { Customers } from "../customers/Customers";
import { Announce } from "../announcements/Announce";
import { EditAnnounce } from "../announcements/EditAnnounce";
import { Valuations } from "../valuations/Valuations";
import { ValuationDetail } from "../valuations/ValuationDetail";
import { IndisatDetail } from "../indisat/IndisatDetail";
import { Indisat } from "../indisat/Indisat";
import { Randevu } from "../randevu/Randevu";
import { CustomerDetail } from "../customers/CustomerDetail";
import { RandevuConfig } from "../randevu/RandevuConfig";
import { Product } from "../announcements/products/Product";
import { CustomerConnections } from "../customerconnections/CustomerConnections";
import { useNavigate } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import TurboContainer from "../turbo/TurboContainer";
export const validUserIds = [
    "935a3271-3a3b-ee11-8d67-95b6e6b4f14b",
    "196185b8-3a3b-ee11-8d67-95b6e6b4f14b",
    "0c59f00c-fedb-ec11-9ab6-9747c6a2f472",
];
export const validTurboUserIds = [
    "935a3271-3a3b-ee11-8d67-95b6e6b4f14b",
    "196185b8-3a3b-ee11-8d67-95b6e6b4f14b",
];
export const SideNav = () => {
    const navigate = useNavigate();
    const logged = localStorage.getItem("token");
    const userId: any = localStorage.getItem("userId");
    useEffect(() => {
        !logged && navigate("/");
    }, []);
    const turboShow = validTurboUserIds.includes(userId);
    console.log(turboShow);

    const [customers, setCustomers] = useState(true);
    const [elanlar, setElanlar] = useState(false);
    const [connections, setConnections] = useState(false);
    const [turbo, setTurbo] = useState(false);

    const activeNav = (params: any) => {
        if (params == "customers") {
            setCustomers(true);
            setElanlar(false);
            setConnections(false);
            setTurbo(false);
        } else if (params == "turbo") {
            setCustomers(false);
            setElanlar(false);
            setConnections(false);
            setTurbo(true);
        } else if (params == "elanlar") {
            setCustomers(false);
            setElanlar(true);
            setConnections(false);
            setTurbo(false);
        } else if (params == "connections") {
            setCustomers(false);
            setElanlar(false);
            setConnections(true);
            setTurbo(false);
        }
    };

    return (
        <>
            <Header />

            <div className="dashboard-container">
                <div className="row m-0">
                    <div className="col-lg-2 p-0 col-12 ">
                        <div className="nav-menu">
                            <ul className="d-flex p-0 flex-lg-column">
                                <Link
                                    to="/crm"
                                    style={{
                                        color: "#212529",
                                        textDecoration: "none",
                                        width: "75px",
                                    }}
                                >
                                    <li
                                        className={
                                            customers ? "active_nav" : ""
                                        }
                                        onClick={() => activeNav("customers")}
                                    >
                                        <i className="far fa-user"></i>
                                        <p>Müştərilər</p>
                                    </li>
                                </Link>

                                <Link
                                    to="elanlar"
                                    style={{
                                        color: "#212529",
                                        textDecoration: "none",
                                        width: "75px",
                                    }}
                                >
                                    <li
                                        className={elanlar ? "active_nav" : ""}
                                        onClick={() => activeNav("elanlar")}
                                    >
                                        <i className="fas fa-bullhorn"></i>
                                        <p>Elanlar </p>
                                    </li>
                                </Link>

                                <Link
                                    to="connections"
                                    style={{
                                        color: "#212529",
                                        textDecoration: "none",
                                        width: "75px",
                                    }}
                                >
                                    <li
                                        className={
                                            connections ? "active_nav" : ""
                                        }
                                        onClick={() => activeNav("connections")}
                                    >
                                        <i className="fas fa-phone-alt"></i>
                                        <p>Müraciətlər</p>
                                    </li>
                                </Link>

                                {turboShow && (
                                    <Link
                                        to="turbo"
                                        style={{
                                            color: "#212529",
                                            textDecoration: "none",
                                            width: "75px",
                                        }}
                                    >
                                        <li
                                            className={
                                                turbo ? "active_nav" : ""
                                            }
                                            onClick={() => activeNav("turbo")}
                                        >
                                            <i className="fas fa-bullhorn"></i>
                                            <p>Turbo </p>
                                        </li>
                                    </Link>
                                )}
                            </ul>
                        </div>
                    </div>

                    <Routes>
                        <Route path="/" element={<Customers />} />
                        <Route
                            path="customer/:userId"
                            element={<CustomerDetail />}
                        />
                        <Route path="elanlar" element={<Announce />} />
                        <Route path="qiymetlendirme" element={<Valuations />} />
                        <Route
                            path="qiymetlendirme/:id"
                            element={<ValuationDetail />}
                        />
                        <Route path="indisat" element={<Indisat />} />
                        <Route path="indisat/:id" element={<IndisatDetail />} />
                        <Route path="randevu" element={<Randevu />} />
                        <Route
                            path="randevu-config"
                            element={<RandevuConfig />}
                        />
                        <Route path="elan/:id" element={<Product />} />
                        <Route
                            path="edit-elan/:id"
                            element={<EditAnnounce />}
                        />
                        <Route
                            path="connections"
                            element={<CustomerConnections />}
                        />
                        {turboShow && <Route path="turbo" element={<TurboContainer />} />}
                    </Routes>
                </div>
            </div>
        </>
    );
};
