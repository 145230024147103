import React from "react";
import image from "../../images/icon.svg";
import { useNavigate } from "react-router-dom";
 
export const Header = () => {

    const navigate = useNavigate()


    const logOut = () => {
        localStorage.setItem('token','')
        navigate('/')
    }
    

    return(
        <>
        <header>

    <div className="row m-0 d-flex align-items-center ">
        <div className="col-lg-6 col-8 head_title order-md-1 p-0">  
        <p>Otomall</p></div>
        <div className="col-lg-4  col-12 p-0 d-flex justify-content-lg-end justify-content-start mt-lg-0 mt-3 order-lg-2 order-3 ">
        <input className="search" placeholder="Axtarış" />
        </div>
        
        <div className="col-lg-2 col-4  d-flex justify-content-end align-items-center order-lg-3 order-2">
            <div className="notifications d-flex justify-content-center align-items-center">
                <img src={image} alt="" />
            </div>
            
            <div className="user_profile d-flex justify-content-center align-items-center">
                <span>MM</span></div>
                
                <i className="fas fa-sign-out-alt" style={{fontSize:'32px',marginLeft:"2rem",color:"rgba(0, 0, 0, 0.4)",cursor:"pointer"}} onClick={logOut}></i>

    </div>
    </div>

    </header>
    </>
    )
}