import React, { useState } from 'react'
import  LoginCar  from "../images/LoginCar.svg";
import { useNavigate } from "react-router-dom";

export const Login = () => {

    const navigate = useNavigate()
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')



    const LoginUser = (e:any) => {
        
        e.preventDefault()

        let data_ = {
            'userName':email,
            'password':password
        }

        fetch('https://identity.digimall.az/api/login',{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
                'api-key': "e4246c59-4357-4c5e-a5a9-af4307c4f751"
            },
            body:JSON.stringify(data_)
        }).then(res=>res.json()).then((data)=>{
            if (data.hasError == false) {
                localStorage.setItem('userId',data.message)
                localStorage.setItem('token',data.data.token)
                navigate('/crm')
            }
        })
    }
    

    return (
        <div className="mx-0">
        <div className='row'>
            <div className="col-8">
                <img src={LoginCar} alt="car" style={{width:'700px'}} />
            </div>
            <div className="col-4">
                <h1 className='my-5'><b>OTOMALL CRM</b></h1>
                <div className="my-5">
                    <h3><b>Login to your account</b></h3>
                    <div>
                        <form action="" className='d-flex flex-column' onSubmit={(e)=>LoginUser(e)}>
                            <label htmlFor="" className='mb-2'>
                                Username
                                <input className='d-block w-75' value={email} onChange={(e)=>setEmail(e.target.value)} type="text"/>
                            </label>
                            <label htmlFor="">
                                Password
                                <input className='d-block w-75' value={password} onChange={(e)=>setPassword(e.target.value)} type="password"/>
                            </label>
                            <button type="submit" className='btn btn-danger text-center w-75 my-4'> Login Now </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}
