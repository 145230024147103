import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getIndisatByPaginate, getTodayIndisat, getAllIndisatApi } from "../../redux/actions";
import { Link } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {changeDateInArrayInEvaluate} from "../functions"
import {
    ExcelExport,
    ExcelExportColumn
} from "@progress/kendo-react-excel-export";
    
import {
    process
} from "@progress/kendo-data-query";

import ReactPaginate from 'react-paginate';
import { DateFormat } from '../DateFormat';
export const Indisat = () => {

    let dispatch = useDispatch();
    const { indisatlar,paginateIndisatlar } = useSelector((state:any)=>state.data);
     const [lenPage, setLenPage] = useState(0)
    const [all, setAll] = useState(true)
    const [today, setToday] = useState(false)

    useEffect(() => {
        dispatch(getAllIndisatApi())
        setLenPage(indisatlar.length)
        dispatch(getIndisatByPaginate(0))
    },[])

    const paginate = (e:any) => {
       let currentPage = ((e.selected)*10)+1;
       dispatch(getIndisatByPaginate(currentPage))
    }


    const getIndisatForToday = () => {
        setAll(false)
        setToday(true)
        dispatch(getTodayIndisat())
    };

    const getAllIndisat = () => {
        setToday(false)
        setAll(true)
        dispatch(getIndisatByPaginate(0))
    }

    const data = process(changeDateInArrayInEvaluate(indisatlar), {
    }).data;

    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
        _exporter.current.save();
        }
    };
    
    

    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
                 <span className="title"> İndisat Dəyərləndirmələr </span>
                <div className="nav-small">
                    <ul className="d-flex p-0 flex-wrap mt-lg-0 mt-3">
                        <li className={all ? 'active_nav' : ''} onClick={getAllIndisat}>
                            All
                        </li>
                        <li className={today ? 'active_nav' : ''} onClick={getIndisatForToday}>
                            Today
                        </li>
                        <div>
                <button className="k-button" onClick={exportExcel}>
                    Export to Excel
                </button>

                <ExcelExport
                    data={data}
                    fileName="İndisatDəyərləndirmələr.xlsx"
                    ref={_exporter}
                >
                    <ExcelExportColumn
                    field="id"
                    title="ID"
                    locked={true}
                    width={60}
                    />
                    <ExcelExportColumn
                    field="fullName"
                    title="Ad, Soyad"
                    width={100}
                    />
                     <ExcelExportColumn
                    field="markaName"
                    title="marka adı"
                    width={100}
                    />
                      <ExcelExportColumn
                    field="modelName"
                    title="model adı"
                    width={100}
                    />
                       <ExcelExportColumn
                    field="phoneNumber"
                    title="Əlaqə nömrəsi"
                    width={100}
                    />

                 <ExcelExportColumn
                    field="year"
                    title="İstehsal ili"
                    width={100}
                    />
                    <ExcelExportColumn
                        field="price"
                        title="Qiyməti"
                        cellOptions={{ format: "$#,##0.00" }}
                        width={110}
                        footerCellOptions={{ wrap: true, textAlign: "center" }}
                        groupFooterCellOptions={{ textAlign: "right" }}
                    />
                    <ExcelExportColumn field="createdAt" title="Yaranma tarixi" width={200}/>
                     <ExcelExportColumn
                    field="Discontinued"
                    title="Discontinued"
                    hidden={true}
                    />
                </ExcelExport>
                        </div>
                    </ul>
                </div>

                <table className="table table-sm table-bordered text-center">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Tarix </th>
                        <th scope="col">Ad, Soyad</th>
                        <th scope="col">Əlaqə nömrəsi</th>
                        <th scope="col">Marka</th>
                        <th scope="col">Model</th>
                        <th scope="col">İstehsal ili</th>
                        <th scope="col">Əməliyyatlar</th>
                    </tr>
                    </thead>
                    <tbody>
                        {paginateIndisatlar && paginateIndisatlar.map((indisat :any, index:number) => (
                    <tr key={indisat.id}>
                        <th scope="row">{indisat.id}</th>
                        <td> <DateFormat date={indisat.createdAt}/> </td>
                        <td>{indisat.fullName}</td>
                        <td>{indisat.phoneNumber}</td>
                        <td>{indisat.markaName}</td>
                        <td> {indisat.modelName}</td>
                        <td> {indisat.year}</td>
                        <Link to={`/crm/indisat/${indisat.id}`} style={{color:'#212529', textDecoration:'none'}}>
                            <td><i className="fas fa-user-cog"></i></td>
                        </Link>
                    </tr>
                        ))}
                    </tbody>
                </table>
                
            </div>
        </>
    )
}
