import React from 'react'
import { DateFormat } from '../DateFormat'

export const OrderTable = ({customers}:any) => {
  return (
    <>
        {customers && customers.map((elan:any,index:any)=>(
             <tr key={elan.id}>
             <th scope="row">{index+1}</th>
             {elan.fullName ? (
                 <td>{elan.fullName}</td>

             ):(
                 <td>{elan.name} {elan.family}</td>

             )}

             {elan.phone?(
                 <td>{elan.phone}</td>

             ):(
                 <td>{elan.cellPhone}</td>

             )}
             <td> {elan.operatorName||elan.operator} </td>
             <td> <DateFormat date={elan.createAt}/> </td>
             <td> Sifaris ver </td>
             <td className='btn-group dropend'><i className="fas fa-user-cog dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ></i>
             {
                 elan.operatorName == null ?(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item'>təyin et</button>
                     </ul>
                 ):(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item'>təyinatı ləğv et</button>
                     </ul>
                 )
             }
             </td>
         </tr>
        ))}
    </>
  )
}
