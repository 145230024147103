import * as types from './actionType'
import axios from "axios"
const FIRST_URL = 'https://otomallbusiness.digimall.az'


// ELAN DISPATCH

const getAllElan = (elanlar: any) => ({
    type: types.GET_ALL_ELAN,
    payload: elanlar
})

const getAllRandevuConfig = (config:any) =>({
    type:types.GET_RANDEVU_CONFIG,
    payload:config
})

const getPaginateElan = (elanlar:any)=>({
    type:types.GET_PAGINATE_ELAN,
    payload:elanlar
})

const getElan = (elan:any) => ({
    type:types.GET_ELAN,
    payload:elan
})

const uploadDynamicFields = (field : any)=> ({
    type:types.GET_DYNAMIC_FIELDS,
    payload:field
})

// INDISAT DISPATCH

const getPaginateIndisat = (indisat:any) => ({
    type:types.GET_PAGINATE_INDISAT,
    payload:indisat
})

const getAllIndisat = (indisatlar:any) =>({
    type:types.GET_ALL_INDISAT,
    payload:indisatlar
})

const getindisatDetail = (indisat:any) => ({
    type:types.GET_INDISAT_DETAIL,
    payload:indisat
})


const getAllRandevu = (randevu:any) => ({
    type: types.GET_ALL_RANDEVU,
    payload: randevu
})


const getAddress = (address:any) => ({
    type:types.GET_RANDEVU_ADDRESS,
    payload:address
})

const getAllCustomer = (customers:any) =>({
    type:types.GET_ALL_CUSTOMERS,
    payload:customers
})

const getCustomerDetail = (customer:any) => ({
    type:types.GET_CUSTOMER_DETAIL,
    payload:customer
})

const getDeyerlendirme = (deyerlendirme:any) =>({
    type:types.GET_DEYERLENDIRMELER,
    payload:deyerlendirme
})

const getDeyerlendirmeDetail = (deyerlendirme:any) =>({
    type:types.GET_DEYERLENDIRME_DETAIL,
    payload:deyerlendirme
})

const getCustomerConnect = (connections:any) =>({
    type:types.GET_CUSTOMER_CONNECTIONS,
    payload:connections
})


// CUSTOMERS API

export const getAllCustomers = () => {
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/Providers/GetProvidersForOtomall?skip=0&take=100',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        })
        .then(res=>res.json()).then((data)=>{
             dispatch(getAllCustomer(data))
        })
    }
}

export const getTodayCustomers = () =>{
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/Providers/GetProvidersForOtomall?skip=0&take=100',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
            }
        }).then(res=>res.json())
        .then(
            (data)=>{
                console.log('data111->',data)
                let array = [];

                for (const i of data) {
                    var createdAt = i.createAt;
                    var currentYear = new Date().getDate();
                    var elansYear = new Date(createdAt).getDate();
                    if (currentYear == elansYear) {
                        array.push(i)
                    }
                }
                array.length === 0 ? getAllCustomer("empty") : dispatch(getAllCustomer(array))
                

                // data.length === 0 && getAllCustomer("empty")
            }
        )
    }
}

export const getCustomerDetailApi = (userId:any) => {
    var token = localStorage.getItem('token');
    return function(dispatch:any){
        console.log(userId, 'useElanlari olmali oldugu yer');
        
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/GetAllInfo?userId=${userId}&check=false&isIndiSat=false`,{
        method:"GET",
        headers:{
            'Bearer':String(token),
            'api-key':"e4246c59-4357-4c5e-a5a9-af4307c4f751"
        }
    }).then(res=>res.json()).then((data:any)=>{
        console.log(data)
        dispatch(getCustomerDetail(data))
    })
    }
}
  // ELAN API
 export const getAllElans = () => {
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=0&take=10000`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                "api-key":"e4246c59-4357-4c5e-a5a9-af4307c4f751"
            }
        })
        .then(res=>res.json())
        .then(
            (data)=>{
                console.log(data);
                dispatch(getAllElan(data))
                // dispatch(getPaginateElan(data))
            }
        )
    }
}

export const getPaginateElanApi = (skip:any) =>{
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=${skip}&take=10`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                "api-key":"e4246c59-4357-4c5e-a5a9-af4307c4f751"
            }
        }).then(res=>res.json())
        .then((data)=>{
            dispatch(getPaginateElan(data))
        })
    }
}




export const getRegisteredElans = (skip:any,take:any) => {
    var token = localStorage.getItem('token')
    return async function(dispatch:any){
        await fetch('https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=0&take=10',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            var array = []
            for (const d of data) {
                if (d.userId =='00000000-0000-0000-0000-000000000000') {
                    array.push(d)
                    console.log('array -> ',array)
                 }
            }
            dispatch(getAllElan(array))
        })
    }
}

export const getUnregisteredElans = (skip:any, take:any) => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=0&take=10',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            var array = []
            for (const d of data) {
                if (d.userId === null) {
                    array.push(d)
                    console.log('array -> ',array)
                    dispatch(getAllElan(array))
                }else{                    
                    dispatch(getAllElan([]))
                    
                }
            }
        })
    }
}

export const getElansforToday = () => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=0&take=10',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            console.log('today elan -> ', data)
            var array = []
            for (const d of data) {
                var createdAt = d.createAt;
                var currentYear = new Date().getDate();
                console.log('current date',currentYear)
                var elansYear = new Date(createdAt).getDate()
                console.log('elans date -> ', elansYear)
                if (elansYear == currentYear) {
                    array.push(d)
                    console.log(`array`, array)
                    dispatch(getAllElan(array))
                }else{
                    dispatch(getAllElan([]))
                    dispatch(getPaginateElan([]))
                }
            }
        })
    }
}
 
export const getElansforChange = (registered:any, nonRegister:any, today:any, elanState:"pending"|"reject"|"accept"|"sold"| null) => {
  
    
    var token = localStorage.getItem('token')

let registeredStateEnd=``
let elanStateEnd=``
 

if(registered){
    registeredStateEnd=`&registered=${true}`
 }
 if(nonRegister){
    registeredStateEnd=`&registered=${false}`
 }
 if(today){
    registeredStateEnd=`&today=${true}`
 }
 if(elanState){
    elanStateEnd=`&elanStatus=${elanState}`
 }

 
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/Elan/ElanListForOtomallCrmPanel?skip=0&take=10000'+registeredStateEnd+elanStateEnd ,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
             console.log(data, 'actiondaki dataaa');
             
            dispatch(getAllElan(data))
            dispatch(getPaginateElan(data.slice(0, 10)))
        })
    }
}



















export const getElanById = (id:any) => {
    var token = localStorage.getItem('token')
    return function (dispatch:any) {
        fetch(`https://otomallbusiness.digimall.az/api/Elan/GetElanById/${id}`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }})
        .then(res=>res.json())
        .then((data)=>{
            console.log(data, 'get elanda api olan yer');
            // if(data.status>=200 && data.status<=210){
                dispatch(getElan(data))
                // let emptyArray = [];
    
                // let array = data.dynamicFields && data.dynamicFields.split(';');
    
                // for (const d of array) {
                //     let a = d.split(':')
                //     emptyArray.push(a)
                // }
                dispatch(uploadDynamicFields(data.elanFields))
            // }
           
        })
    }
}


// INDISAT API

export const getAllIndisatApi = () => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/PricingList?isIndisat=true`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        }).then(res=>res.json()).then((data)=>{
            console.log(data)
            dispatch(getAllIndisat(data))
        })

    }
}

export const getIndisatByPaginate = (skip:any) => {
    var token = localStorage.getItem('token')

    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/PricingList?isIndisat=true`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            console.log(data)
            dispatch(getPaginateIndisat(data))
        })
    }
}


export const getTodayIndisat = () => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/PricingList?isIndisat=true&today=true`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
             dispatch(getPaginateIndisat(data))
        })
    }
}
 
export const getIndisatDetail = (id:any) => {
    var token = localStorage.getItem('token')
     
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/IndiSatPrices/IndiSatByIdForOtomallCrmPanel?id=${id}`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            console.log(data)
            dispatch(getindisatDetail(data[0]))
        })
    }
}


// RANDEVU API

export const getAllRandevuConfigApi = () => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/RandevuConfiguration/GetRandevuConfigurationList',{
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
              },
        })
        .then(res=>res.json())
        .then(data=>dispatch(getAllRandevuConfig(data)))
    }
};

 export const getPendingRandevuApi = () => {
    var token = localStorage.getItem('token')
    return function (dispatch : any) {
        let array:any = []
        let data = {
            "status": 0
        }
        fetch('https://otomallbusiness.digimall.az/api/Randevu/GetAllRandevu',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
              },
          
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then((data)=>{
            dispatch(getAllRandevu(data))
        })
    }
}


export const getCancelledRandevuApi = () => {
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        let array:any = []
        let data = {
            "status" : 1
        }
        fetch('https://otomallbusiness.digimall.az/api/Randevu/GetAllRandevu',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
              },
          
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then((data)=>{
            dispatch(getAllRandevu(data))
        })
    }
}





export const getActiveRandevuApi = () => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        let array:any = []
        let data = {
            "status" : 2
        }
        fetch('https://otomallbusiness.digimall.az/api/Randevu/GetAllRandevu',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
          
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then((data)=>{
            dispatch(getAllRandevu(data))
        })       
    }
}



export const getDoneRandevuApi = () => {
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        let array:any = []
        let data = {
            "status":3
        }
        fetch('https://otomallbusiness.digimall.az/api/Randevu/GetAllRandevu',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
          
            body:JSON.stringify(data)
        }).then(res=>res.json())
        .then((data)=>{
            dispatch(getAllRandevu(data))
        }) 
    }
}


export const getAllRandevuAddress = () => {
    var token = localStorage.getItem('token')
    return function (dispatch:any) {
        fetch('https://otomallbusiness.digimall.az/api/RandevuAdress/GetAllRandevuAdress',{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then(data => {
            console.log(data),
            dispatch(getAddress(data))
        })
    }
}

// DEYERLENDIRMELER


export const getDeyerlendirmeler = (skip:any,take:any) =>{
    var token = localStorage.getItem('token')
    return function (dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/PricingList?isIndisat=false`,{
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data : any)=>{
            console.log(data)
            let array = []
            for (const i of data) {
                if (i.isIndiSat == false) {
                    array.push(i)
                }
            }
            console.log(array, 'bura oradi')
            dispatch(getDeyerlendirme(array))
        })
    }
}

export const getDeyerlendirmeDetailApi = (id:any) => {
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/IndiSatPrices/IndiSatByIdForOtomallCrmPanel?id=${id}`,{
            method:"GET",
            headers:{
                'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        }).then(response=>response.json())
        .then(data=>{
            console.log('deyerlendirme detail -> ',data)
            dispatch(getDeyerlendirmeDetail(data))
        })
    }
}

export const getTodayDeyerlendirmeler = (skip:any, take:any) => {
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Integrated/PricingList?isIndisat=false&today=true`,{
            method:"GET",
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            dispatch(getDeyerlendirme(data))
        })
    }
}

export const getCustomerConnections = () =>{
    var token = localStorage.getItem('token')
    var array:any = []
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/CustomerConnection/GetAllConnection',
        {
            headers:{
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        })
        .then(res=>res.json())
        .then((data)=>{
            console.log(data, 'phoneolmalidi');
            
            for (const i of data) {
                if (i.connectType == 1) {
                    array.push(i)
                }
            }
            dispatch(getCustomerConnect(array))
        })
    }
}


export const putCustomerConnections = (id:any, user_id:any) =>{
    var token = localStorage.getItem('token')
    let dat = {
        'id':id,
        'userId':user_id
    }
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/CustomerConnection',{
            method:'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
              },
            body:JSON.stringify(dat)
        }).then(res=>res.json)
        .then(data=>console.log(data))
    }

}

export const getTestDriveApi = () =>{
    var token = localStorage.getItem('token')
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/VisitRequest/GetVisitRequest/0/100000000',{
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
              }
        }).then(res=>res.json()).then((data)=>{
            console.log(data);
            dispatch(getCustomerConnect(data))
        })
    }
}


export const getSifarishApi = () =>{
    var token = localStorage.getItem('token');
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/Importations/ImportationsList/0/10000000`,{
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        }).then(res=>res.json()).then(data=>dispatch(getCustomerConnect(data)))
    }
}

export const getWhatsappMuracietler = () =>{
    var token = localStorage.getItem('token');
    let array:any = []
    return function(dispatch:any){
        fetch(`https://otomallbusiness.digimall.az/api/CustomerConnection/GetAllConnection`,{
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        }).then(res=>res.json()).then(data=>{
            // console.log(data, 'whatsappdaki  gelen');
            for (const i of data) {
                if (i.connectType == 2) {
                    array.push(i);
                }
            }
            dispatch(getCustomerConnect(array))
        })
    }}

 export const getCreditMuracietler = () => {
    var token = localStorage.getItem('token');
    let array:any = []
    return function(dispatch:any){
        fetch('https://otomallbusiness.digimall.az/api/CustomerConnection/GetAllConnection',{
            headers: {
                'Content-Type': 'application/json',
                'Bearer':String(token),
                'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
            }
        }).then(res=>res.json()).then(data=>{
             for (const i of data) {
                if (i.connectType == 0) {
                    array.push(i)
                }
            }
            dispatch(getCustomerConnect(array))
        })
    }
}


export const getOneMinuteMuracietler = () => {
     var token = localStorage.getItem('token');
    let array:any = []
    return function(dispatch:any){
        // fetch('https://otomallbusiness.digimall.az/api/CustomerConnection/GetAllConnection',{
        //     headers: {
        //         'Content-Type': 'application/json',
        //          'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
        //     }
        // })
        
        axios.get("https://otomallbusiness.digimall.az/api/CustomerConnection/GetAllConnection",{
                 headers: {
                     'Content-Type': 'application/json',
                      'api-key': 'e4246c59-4357-4c5e-a5a9-af4307c4f751'
                 }
             } )
        
        .then(res=>res).then(data=>{
            console.log(data.data, 'datatatat');
            
             const array=data.data.filter((arr:any)=>arr.connectType==3)
          
              dispatch(getCustomerConnect(array))
        })
    }
}

