import React from 'react'

export const DateFormat = ({date}:any) => {
    const d = new Date(date)
    return (
        <>
            {
                d.getDate()+'.'+(d.getMonth() + 1)+'.'+d.getFullYear()
            }
        </>
    )
}
