import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDeyerlendirmeler, getTodayDeyerlendirmeler } from "../../redux/actions";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {  changeDateInArrayInEvaluate } from '../functions';
import {
    ExcelExport,
    ExcelExportColumn
} from "@progress/kendo-react-excel-export";
import {
    process
} from "@progress/kendo-data-query";
import { DateFormat } from '../DateFormat';


export const Valuations = () => {

    const dispatch = useDispatch();

    const {deyerlendirmeler} = useSelector((state:any) => state.data)

    const [all, setAll] = useState(true)
    const [today, setToday] = useState(false)
 

    useEffect(() => {
        dispatch(getDeyerlendirmeler(0,10))
        return () => {
            
        }
    }, [])


    const getAllDeyer = () => {
        setAll(true)
        setToday(false)
        dispatch(getDeyerlendirmeler(0,10))
    }
    
    const getTodayDeyer = () => {
        setToday(true)
        setAll(false)
        dispatch(getTodayDeyerlendirmeler(0,10))
         
    }

    const data = process(changeDateInArrayInEvaluate(deyerlendirmeler),{
    }).data;


    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
        _exporter.current.save();
        }
    };
 
    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
                 <span className="title"> Dəyərləndirmələr </span>
                <div className="nav-small">
                    <ul className="d-flex p-0 flex-wrap mt-lg-0 mt-3">
                        <li className={all ? 'active_nav' : ''} onClick={getAllDeyer}>
                            All
                        </li>
                        <li className={today ? 'active_nav' : ''} onClick={getTodayDeyer}>
                            Today
                        </li>
                        <button className="k-button" onClick={exportExcel}>
                            Export to Excel
                        </button>
                    </ul>
                    <div>

                    <ExcelExport
                    data={data}
                    fileName="Qiymetlendirmeler.xlsx"
                    ref={_exporter}
                >
                    <ExcelExportColumn
                    field="id"
                    title="ID"
                    locked={true}
                    width={60}
                    />
                    <ExcelExportColumn
                    field="fullName"
                    title="Müştəti adı"
                    width={100}
                    />
                   <ExcelExportColumn
                    field="markaName"
                    title="marka adı"
                    width={100}
                    />
                       <ExcelExportColumn
                    field="modelName"
                    title="model adı"
                    width={100}
                    />
                    <ExcelExportColumn
                    field="phoneNumber"
                    title="Əlaqə telefonu"
                    width={100}
                    />
                 <ExcelExportColumn
                    field="year"
                    title="Buraxılış ili"
                    width={100}
                 />
                   <ExcelExportColumn
                    field="createdAt"
                    title="Yaranma tarixi"
                    width={100}
                    />
                    <ExcelExportColumn
                        field="price"
                        title="Qiyməti"
                        cellOptions={{ format: "$#,##0.00" }}
                        width={110}
                        footerCellOptions={{ wrap: true, textAlign: "center" }}
                        groupFooterCellOptions={{ textAlign: "right" }}
                    />
                
                    <ExcelExportColumn field="status" title="Status" />
                    <ExcelExportColumn
                    field="Discontinued"
                    title="Discontinued"
                    hidden={true}
                    />
                </ExcelExport>
                        </div>
                </div>

                <table className="table table-sm table-bordered text-center">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Müştəri </th>
                        <th scope="col">Əlaqə telefonu</th>
                        <th scope="col">Avtomobil</th>
                        <th scope="col">Tarix</th>
                        <th scope="col">Buraxılış ili</th>
                        <th scope="col">Əməliyyatlar</th>
                    </tr>
                    </thead>
                    <tbody>
                    {deyerlendirmeler && deyerlendirmeler.map((deyer:any,index:any)=>(
                     <tr key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{deyer.fullName}</td>
                        <td>{deyer.phoneNumber}</td>
                        <td>{deyer.markaName} {deyer.modelName}</td>
                        <td><DateFormat date={deyer.createdAt}/></td>
                        <td>{deyer.year}</td>
                        <td>
                        <Link to={`/crm/qiymetlendirme/${deyer.id}`} style={{color:'#212529', textDecoration:'none'}}>
                            <i className="fas fa-user-cog"></i>
                        </Link>
                        </td>
                    </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
