import React, {useEffect,useState} from 'react'
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerDetailApi } from "../../redux/actions";
import { DateFormat } from "../DateFormat";
import { Link } from "react-router-dom";
import { OperationsPopUp } from "../OperationsPopUp";
 
export const CustomerDetail = () => {
    const {userId} = useParams();
    const dispatch = useDispatch();
    const {customer} = useSelector((state:any) => state.data)
    const [status, setStatus] = useState({
      0:"Təsdiq gözləyir",
      1:"Təsdiq edildi",
      2:"Təsdiq edilmədi",
      3:"Satıldı",
  })

    useEffect(() => {
      dispatch(getCustomerDetailApi(userId))
     }, [])

    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
        <div className="col-12">
                <span className="title"> {customer.name} {customer.family} </span>
                 <ul className="d-flex p-0 user_contact">
                    <li className="d-flex flex-column">
                        <label>Telefon nömrəsi:</label>
                        <span> {customer.cellPhone} </span>
                    </li>
                    <li className="d-flex flex-column">
                        <label>Email adress: </label>
                        <span> {customer.email} </span>
                    </li>
                </ul>
        </div>
           <div id="accordion" className='mt-4'>
          <div className="card mb-3">
                  <span className="" data-bs-toggle="collapse" data-bs-target="#elanlar" role="button" aria-expanded="false" aria-controls="elanlar">
                <div className="card-header" id="headinOne">
                    <div className="d-flex">
                <h5 className='my-auto'>
                    Elanlar
                 </h5>
                <div className="m-auto "/>
                <i className="my-auto fas fa-chevron-down"></i>
                </div>
                </div>
                </span>
                <div className="collapse" id="elanlar">
                <div className="card card-body">
                <table className="table table-sm table-bordered text-center">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Avtomobil (model, marka) </th>
                <th scope="col">Qiyməti</th>
                <th scope="col">Buraxılış ili</th>
                <th scope="col">Status</th>
                <th scope="col">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
              {customer.elans && customer.elans.map((elan:any,index:any)=>(
                  <tr key={index}>
                  <th scope="row">{index+1}</th>
                  <td>{elan.title}</td>
                  <td>{elan.price}</td>
                  <td>{elan.createAt}</td>
                  {
                    elan.status == 0 && (
                        <td>{status[0]}</td>

                    )
                }
                {
                    elan.status == 1 && (
                        <td>{status[1]}</td>

                    )
                }
                {
                    elan.status == 2 && (
                        <td>{status[2]}</td>

                    )
                }
                {
                    elan.status == 3 && (
                        <td>{status[3]}</td>
                     )
                }

                 <td>
                   <Link to={`/crm/elan/${elan.id}`}>
                   <i className="fas fa-user-cog"></i>
                   </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                </div>
            </div>
            </div>
            <div className="card mb-3">
                  <span className="" data-bs-toggle="collapse" data-bs-target="#deyerlendirme" role="button" aria-expanded="false" aria-controls="deyerlendirme">
                <div className="card-header" id="headinOne">
                    <div className="d-flex">
                <h5 className='my-auto'>
                    Dəyərləndirmələr
                </h5>
                <div className="m-auto "/>
                <i className="my-auto fas fa-chevron-down"></i>
                </div>
                </div>
                </span>
                <div className="collapse" id="deyerlendirme">
                <div className="card card-body">
                    <table className="table table-sm table-bordered text-center">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Müştəri  </th>
                <th scope="col">Əlaqə teleofnu</th>
                <th scope="col">Avtomobil</th>
                <th scope="col">Buraxılış ili</th>
                <th scope="col">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
               {
                customer.indiSatPrices && customer.indiSatPrices.map((deyer:any,index:any)=>(
                  deyer.isIndiSat ? null : (
              <tr key={deyer.id}>
                <th scope="row">{index+1}</th>
                <td>{deyer.fullName}</td>
                <td>{deyer.phoneNumber}</td>
                <td>{deyer.modelName}</td>
                <td>{deyer.year}</td>
                <td>
                   <Link to={`/crm/qiymetlendirme/${deyer.id}`}>
                   <i className="fas fa-user-cog"></i>
                   </Link>
                </td>
              </tr>
                   )
                ))}
            </tbody>
          </table>
                </div>
            </div>
            </div>
            <div className="card mb-3">
                  <span className="" data-bs-toggle="collapse" data-bs-target="#indisat" role="button" aria-expanded="false" aria-controls="indisat">
                <div className="card-header" id="headinOne">
                    <div className="d-flex">
                <h5 className='my-auto'>
                    İndisat Dəyərləndirmələr
                </h5>
                <div className="m-auto "/>
                <i className="my-auto fas fa-chevron-down"></i>
                </div>
                </div>
                </span>
                <div className="collapse" id="indisat">
                <div className="card card-body">
                    <table className="table table-sm table-bordered text-center">
            <thead>
            <tr>
                <th scope="col">№</th>
                <th scope="col">Müştəri  </th>
                <th scope="col">Əlaqə teleofnu</th>
                <th scope="col">Avtomobil</th>
                <th scope="col">Buraxılış ili</th>
                <th scope="col">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
   
              {
                customer.indiSatPrices && customer.indiSatPrices.map((indisat:any, index:any)=>(
                 <tr key={indisat.id}>
                <th scope="row">{index+1}</th>
                <td>{indisat.userName}</td>
                <td>{indisat.phoneNumber}</td>
                <td>{indisat.modelName}</td>
                <td>{indisat.year}</td>
                    <td>
                   <Link to={`/crm/indisat/${indisat.id}`}>
                   <i className="fas fa-user-cog"></i>
                   </Link>
                  </td>
                  </tr>
                 ))
              }
            </tbody>
          </table>
                </div>
            </div>
            </div>
            <div className="card mb-3">
                  <span className="" data-bs-toggle="collapse" data-bs-target="#randevu" role="button" aria-expanded="false" aria-controls="randevu">
                <div className="card-header" id="headinOne">
                    <div className="d-flex">
                <h5 className='my-auto'>
                    Randevular
                </h5>
                <div className="m-auto "/>
                <i className="my-auto fas fa-chevron-down"></i>
                </div>
                </div>
                </span>
                <div className="collapse" id="randevu">
                <div className="card card-body">
                    <table className="table table-sm table-bordered text-center">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Müştəri </th>
                <th scope="col">Avtomobil</th>
                <th scope="col">Tarix</th>
                <th scope="col">Ünvan</th>
                <th scope="col">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
              {
                customer.rendevus && customer.rendevus.map((randevu:any, index:any)=>(
              <tr key={randevu.id}>
                <th scope="row">{index+1}</th>
                <td>{randevu.userName}</td>
                <td>{randevu.indisatTitle}</td>
                <td> <DateFormat date={randevu.date}/> </td>
                <td>{randevu.adress}</td>
                <OperationsPopUp username={customer.userName} date={customer.date} inisatId={customer.indiSatPriceId} mobile={customer.mobile} randevuId={randevu.id}/>
              </tr>
                ))
              }
            </tbody>
          </table>
                </div>
            </div>
            </div>
        </div>
         
    </div>
        </>
    )
}
