import React from "react";

interface IProps {
    imgUrl: string;
  }

export default function ImageUploadPreview({imgUrl}: IProps) {
  return (
    <div className="image-upload-single">
      <div className="image-container">
        <img src={imgUrl} alt="" />
      </div>
      <div className="text-right">
      </div>
    </div>
  );
}