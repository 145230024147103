import React,  { Dispatch, SetStateAction, useRef, useState } from 'react';
import { IUpload } from "./markaInterface";
import ImageUploadPreview from "./imaeUploadPreview" ;
import { MdCancel } from "react-icons/md";
import Resizer from "react-image-file-resizer";

interface IProps {
    file: IUpload[];
    setFile: Dispatch<SetStateAction<IUpload[]>>;
    multiple?: boolean;
    id: string;
  }

function ImageUpload ({
    file,
    setFile,
    multiple,
    id
  }: IProps) {


  const fileInput = useRef<HTMLInputElement>(null);
  let combinedFiles: IUpload[] = [];

  const resizeFile = (file:any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      80,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

  function uploadSingleFile(e: React.ChangeEvent<HTMLInputElement>) {
    let files = e.target.files;
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    
    if (files && files.length > 0) {
      if (multiple && files.length + file.length <= 16) {
        
        for (let i = 0; i < files.length; i++) {
          if (allowedExtensions.exec(files[i].name)) {
            resizeFile(files[i])
            combinedFiles.push({
              file: files[i],
              url: URL.createObjectURL(files[i]),
              idd: i
            });
          } else {
            console.log("error");
          }
        }
        console.log(combinedFiles + 'sahol');
        setFile([...file, ...combinedFiles]);
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      } else if (!multiple && files.length > 0) {
        let combinedFiles: IUpload[] = [];
        if (allowedExtensions.exec(files[0].name)) {
          resizeFile(files[0])
          combinedFiles.push({
            file: files[0],
            url: URL.createObjectURL(files[0]),
            idd: 0
          });
        } else {
          console.log("error");
        }
        console.log(combinedFiles);
        setFile(combinedFiles);
        if (fileInput.current) {
          fileInput.current.value = "";
        }
      } else {
        console.log("error");
      }
    }
  }

  

  const handleDelete = (URL:string) => {
      let image = file.filter((item:IUpload) => item.url !== URL)
      setFile(image)
  }

    return (
        <>
        <div className="form-group">
            <input
                type="file"
                className="form-control"
                onChange={uploadSingleFile}
                name="image"
                id={id}
                accept="image/jpg,image/jpeg,image/png"
                ref={fileInput}
            />
            
        </div>
        <div className={multiple ? "image-upload preview-multiple":"image-upload preview"}>
        {file.length > 0 &&
          file.map((item: IUpload, index: number) => {
            return (
              <div key={index}  style={{position:'relative'}}>
                <ImageUploadPreview
                  key={item.url}
                  imgUrl={item.url}
                />
                <p className="delete" onClick={() => handleDelete(item.url)}><MdCancel size={'medium'} color={'#090B4D'}/></p>
              </div>
            );
          })}
        </div>
      </>
    )
}

export default ImageUpload
