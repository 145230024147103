export const GET_ALL_ELAN = 'GET_ALL_ELAN';
export const GET_ELAN = 'GET_ELAN';
export const GET_PAGINATE_ELAN = 'GET_PAGINATE_ELAN'

export const GET_PAGINATE_INDISAT = 'GET_PAGINATE_INDISAT';
export const GET_ALL_INDISAT = 'GET_ALL_INDISAT'
export const GET_INDISAT_DETAIL = 'GET_INDISAT_DETAIL';
export const GET_DYNAMIC_FIELDS = 'GET_DYNAMIC_FIELDS';

export const GET_ALL_RANDEVU = 'GET_ALL_RANDEVU';
export const GET_RANDEVU_ADDRESS = 'GET_RANDEVU_ADDRESS';

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const GET_CUSTOMER_DETAIL = 'GET_CUSTOMER_DETAIL';

export const GET_DEYERLENDIRMELER = 'GET_DEYERLENDIRMELER';
export const GET_DEYERLENDIRME_DETAIL = 'DEYERLENDIRME_DETAIL'

export const GET_CUSTOMER_CONNECTIONS = 'GET_CUSTOMER_CONNECT'

export const GET_RANDEVU_CONFIG = 'GET_RANDEVU_CONFIG'