import React from 'react';
import './App.css';
import './styles/Style.css'
import './styles/bootstrap.css'
import './styles/bootstrap.min.css'
import { SideNav } from "./components/sidebar/SideNav";
import { Link, Routes, Route } from "react-router-dom";
import { Login } from "./components/Login";

function App() {
  
  return (
    <div >

      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/crm/*' element={<SideNav/>}/>
      </Routes>
    </div>
  );
}

export default App;
