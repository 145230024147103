import React, { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { getTurbo } from "../../api/agent";
import { saveAs } from "file-saver";
import MultipleSelect from "./Select";

const makeOptions = ["Audi", "BAİC", "Bentley", "Bestune", "BMW", "BYD", "Cadillac", "Changan", "Cherry", "Chevralet", "Sitroen", "DFSK", "DODGE", "Dongfeng", "FAW", "Ford", "GAC", "Geely", "GMC", "Greatwall", "Haval", "Honda", "Howo", "Hyundai", "Infiniti", "Isuzu", "Iveco", "JAC", "Jeep", "KIA", "Range rover", "Land Rover", "Lexus", "Mazda", "Mercedes", "Mitshubishi", "Mini", "Nissan", "Opel", "Porshce", "Seat", "Shagman", "Skoda", "Skywell", "Subaru", "Toyota", "Volkswagen", "Volvo"]

const TurboContainer = () => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const handleDownloadExcel = (url: string) => {
        const link = document.createElement("a");
        link.href = `https://tascp2.otomall.az${url}`;
        link.download = `turbo.csv`;
        link.click();
    };

    const [loading, setLoading] = useState(false);
    const [makeArray, setMakeArray] = useState<string[]>([])

    const onSubmit = async (data: any) => {
        setLoading(true);
        const submitData = {
            ...data,
            startDate: new Date(
                data.startDate ? data.startDate : moment().subtract(1, "days")
            ).toISOString(),
            endDate: data.endDate ? new Date(data.endDate).toISOString() : "",
            markas: makeArray
        };
        const filledData = Object.fromEntries(
            Object.entries(submitData).filter(([_, v]:any) => v.length > 0)
        );
        console.log(filledData);
        try {
            const res = await getTurbo(filledData);
            // saveAs(
            //     `http://172.16.10.37:8082${res.data}`,
            //     "turbo.csv"
            //   );
            handleDownloadExcel(res.data);
        } catch (err) {
            alert(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="col-lg-10 col-12 p-0 table-responsive">
            <span className="title">Turbo</span>
            <div className="nav-small">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="">başlama tarixi</label>
                        <input type="date" {...register("startDate")} />
                    </div>
                    <div>
                        <label htmlFor="">bitmə tarixi</label>
                        <input type="date" {...register("endDate")} />
                    </div>
                    <div>
                        <label htmlFor="">maksimum qiymət</label>
                        <input type="text" {...register("maxPrice")} />
                    </div>
                    <div>
                        <label htmlFor="">minimum qiymət</label>
                        <input type="text" {...register("minPrice")} />
                    </div>
                    <div>
                        <label htmlFor="">maksimum il</label>
                        <input type="number" {...register("modelYearMax")} />
                    </div>
                    <div>
                        <label htmlFor="">minimum il</label>
                        <input type="number" {...register("modelYearMin")} />
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <label htmlFor="">markalar</label>
                        <MultipleSelect options={makeOptions} value={makeArray} placeholder={"Marka"} onChange={(e:any) => setMakeArray(e.target.value)}/>
                    </div>
                    <div className="turbo-btn-container">
                        <button className="btn btn-danger text-center w-25 my-4" type="submit">{loading ? "Yüklənir" : "Yüklə"}</button>
                    </div>
                </form>
            </div>
            
        </div>
    );
};

export default TurboContainer;
