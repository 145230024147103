import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getIndisatDetail } from "../redux/actions";
import { Link } from "react-router-dom";
import { DateFormat } from "./DateFormat";

export const OperationsPopUp = ({username, title, date,inisatId, mobile,randevuId}:any) => {

    const dispatch = useDispatch();
    const { indisat } = useSelector((state:any) => state.data)


    useEffect(() => {
      dispatch(getIndisatDetail(inisatId))
      return () => {
       }
    }, [])

    const tesdiqEt = (id:any) => {
      fetch(`https://otomallbusiness.digimall.az/api/Randevu/UpdateRandevu?randevuId=${randevuId}&status=2`,{
        method:'PUT',
        headers:{
          'Content-Type': 'application/json',
          'api-key':'E4246C59-4357-4C5E-A5A9-AF4307C4F751'
      },body:JSON.stringify({'randevuId':id})
      }).then(res=>res.json()).then(data=>console.log(data))
    };
    

    const imtinaEt = (id:any) => {
      fetch(`https://otomallbusiness.digimall.az/api/Randevu/UpdateRandevu?randevuId=${randevuId}&status=1`,{
        method:'PUT',
        headers:{
          'Content-Type': 'application/json',
          'api-key':'E4246C59-4357-4C5E-A5A9-AF4307C4F751'
      },body:JSON.stringify({'randevuId':id})
      }).then(res=>res.json()).then(data=>console.log(data))
    };
    


    return (
        <>
            <div className="modal fade" id={`exampleModal${randevuId}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-body">
          <ul className="">
              <li>
                  <label>Müştəri: </label>
                  <p>{username} </p>
            </li>
              <li>
                  <label>Əlaqə nömrəsi:</label>
                  <p>{mobile}</p>
            </li>
              <li>
                  <label>İndisat qiymətləndirmə:</label>
                  <Link to={`/crm/indisat/${inisatId}`}><span className=" bootstrap_modal_button close_modal" style={{height:'60px'}} data-bs-dismiss="modal">{title}</span></Link>
            </li>
            <li className="randevu_date">
                <span><DateFormat date={date}/></span>
                <span>{String(date).split('T')[1]}</span>
          </li>
          </ul>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button type="button" className=" bootstrap_modal_button close_modal" data-bs-dismiss="modal" onClick={()=>imtinaEt(randevuId)}>İmtina et</button>
          <button type="button" className="confirm_modal_button bootstrap_modal_button" data-bs-dismiss="modal" onClick={()=>tesdiqEt(randevuId)}>Təsdiq et</button>
        </div>
      </div>
    </div>
  </div>
  
        </>
    )
}
