import React, { useState } from 'react'
import '../../../styles/ProductInfo.css'

import  Wheel  from "../../../icons/wheel.svg";
import Abs from "../../../icons/abs.svg";
import CarWash from "../../../icons/car-wash.svg";
import Handbreak from "../../../icons/handbrake.svg";
import Light from "../../../icons/light.svg";
import Snowflake from "../../../icons/snowflake.svg";
import Rightchevron from "../../../icons/right-chevron.svg";
import FingerPrint from "../../../icons/fingerprint 1.svg";
import LyukIcon from "../../../icons/lift 1.svg";
import DeriSalonIcon from "../../../icons/car 10.svg";
import Ventilyasiya from "../../../icons/both 1.svg";
import Kamera from "../../../icons/camera 1.svg";
import Start from "../../../icons/carkey 1.svg";
import Perde from "../../../icons/back 1.svg";
import Parking from "../../../icons/parking.svg";
import Seat from "../../../icons/seat 1.svg";
import situation1 from "../../../icons/situation1.svg";
import situation22 from "../../../icons/situation2.svg";


function ProductInfoAbout({dynamicFields}:any) {

    const [active, setActive] = useState(false);
    const [yungulLehm, setYungulLehm] = useState('false')
    const [abs, setAbs] = useState('false')
    const [yagisSensoru, setYagisSensoru] = useState('false')
    const [merkeziQapanma, setMerkeziQapanma] = useState('false')
    const [ksenon, setKsenon] = useState('false')
    const [kondisioner, setKondisioner] = useState('false')
    const [aciqlama, setAciqlama] = useState('');
    const [keylessGo, setKeylessGo] = useState('')
    const [lyuk, setLyuk] = useState('')
    const [deriSalon, setDeriSalon] = useState('')
    const [oturacaqlarinVentilyasiyasi, setOturacaqlarinVentilyasiyasi] = useState('');
    const [arxaKamera, setArxaKamera] = useState('')
    const [startStop, setStartStop] = useState('')
    const [yanPerdeler, setYanPerdeler] = useState('')
    const [parkRadari, setParkRadari] = useState('')
    const [monitor, setMonitor] = useState('')
    const [oturacaqlarinIsidilmesi, setOturacaqlarinIsidilmesi] = useState('')
    const [situation, setsituation] = useState('')
    const [situation2, setsituation2] = useState('')




    const toggleClass = () => {

dynamicFields.forEach((element:any) => {
    

        if(element.name=='Açiqlama'){
            setAciqlama(element.value)
           }

           if(element.name=='Avtomobilin Statusu'){
            setsituation(element.value)
           }

           if(element.name=='Avtomobilin V?ziyy?ti'){
            setsituation2(element.value)
           }
          
          
           if(element.name=='Lyuk'){
            setLyuk(element.value)
           }
           if(element.name=='Yanp?rd?l?r'){
            setYanPerdeler(element.value)
           }
           if(element.name=='Oturacaqlarinisidilm?si'){
            setOturacaqlarinIsidilmesi(element.value)
           }
           if(element.name=='ABS'){
            setAbs(element.value)
           }
           if(element.name=='Yagissensoru'){
            setYagisSensoru(element.value)
           }
           if(element.name=='KeylessGo')
           {
            setKeylessGo(element.value)
           }
           if(element.name=='Oturacaqlarinventilyasiyasi')
           {
            setOturacaqlarinVentilyasiyasi(element.value)
           }
           if(element.name=='Start/Stop')
           {
            setStartStop(element.value)
           }
           if(element.name=='Monitor')
           {
            setMonitor(element.value)
           }
           if(element.name=='D?risalon')
           {
            setDeriSalon(element.value)
           }
         
           if(element.name=='Yüngüllehimlidiskl?r'){
            setYungulLehm(element.value)
           }
           if(element.name=='M?rk?ziqapanma'){
            setMerkeziQapanma(element.value)
           }
           if(element.name=='Kondisioner'){
            setKondisioner(element.value)
           }
           if(element.name=='Parkradari'){
            setParkRadari(element.value)
           }

           if(element.name=='Arxagörüntükamerasi'){
            setArxaKamera(element.value)
           }

           if(element.name=='Ksenonlampalar'){
            setKsenon(element.value)
           }
        });
        // setYungulLehm(dynamicFields[27].value)
        // setMerkeziQapanma(dynamicFields[24].value)
        // setAbs(dynamicFields[15].value)
        // setKsenon(dynamicFields[23].value)
        // setKondisioner(dynamicFields[25].value)
        // setYagisSensoru(dynamicFields[16].value)
        // setAciqlama(dynamicFields[0].value)
        // setKeylessGo(dynamicFields[17].value)
        // setDeriSalon(dynamicFields[21].value)
        // setLyuk(dynamicFields[12].value)
        // setOturacaqlarinVentilyasiyasi(dynamicFields[18].value)
        // setArxaKamera(dynamicFields[22].value)
        // setStartStop(dynamicFields[19].value)
        // setYanPerdeler(dynamicFields[13].value)
        // setParkRadari(dynamicFields[26].value)
        // setMonitor(dynamicFields[20].value)
        // setOturacaqlarinIsidilmesi(dynamicFields[14].value)

        setActive(!active);
    }
    return (
        <>
        <div className=''>
              <div className="tab-group">
        <div className={!active ? "tab-item" : "tab-item active"} onClick={toggleClass} style={{backgroundColor:'#f8f8f8'}}>
             <span>Avtomobil haqqında </span>
            <img src={Rightchevron}/>
         </div>
        <div className="tab-item-info">
       <p> {aciqlama}</p>
        <div className="product-tags">
            
                  <ul className="tags-ul">
                       {
                          yungulLehm =='true' ? (
                            <li className="tag-li">
                                <img src={Wheel}/>
                                Yüngül lehimli disklər
                            </li>
                          ):null
                      }
                      {
                          abs == 'true' ? (
                            <li className="tag-li">
                                <img src={Abs}/>
                                ABS
                            </li>
                          ):null
                      }
                      {
                          yagisSensoru == 'true' ? (

                            <li className="tag-li">
                                <img src={CarWash}/>
                                Yağış sensoru
                            </li>
                          ):null
                      }
                      {
                          merkeziQapanma == 'true' ? (
                            <li className="tag-li">
                                <img src={Handbreak}/>
                                Mərkəzi qapanma
                            </li>
                          ):null
                      }
                      {
                          ksenon == 'true' ? (
                            <li className="tag-li">
                                <img src={Light}/>
                                Ksenon Lampalar
                            </li>
                          ):null
                      }{
                        keylessGo == 'true' ? (
                          <li className="tag-li">
                              <img src={FingerPrint}/>
                              Keyless go
                          </li>
                        ):null
                    }
                      {
                          kondisioner == 'true' ? (
                            <li className="tag-li">
                                <img src={Snowflake}/>
                                Kondisioner
                            </li>
                          ):null
                      }
                      {
                          lyuk == 'true' ? (
                            <li className="tag-li">
                                <img src={LyukIcon}/>
                                Lyuk
                            </li>
                          ):null
                      }
                      {
                          deriSalon == 'true' ? (
                            <li className="tag-li">
                                <img src={DeriSalonIcon}/>
                                Deri Salon
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinVentilyasiyasi == 'true' ? (
                            <li className="tag-li">
                                <img src={Ventilyasiya}/>
                                Oturacaqlarin ventilyasiyasi
                            </li>
                          ):null
                      }
                      {
                          arxaKamera == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Arxa goruntu kamerasi
                            </li>
                          ):null
                      }
                      {
                          startStop == 'true' ? (
                            <li className="tag-li">
                                <img src={Start}/>
                                Start/Stop
                            </li>
                          ):null
                      }
                      {
                          yanPerdeler == 'true' ? (
                            <li className="tag-li">
                                <img src={Perde}/>
                                Yan perdeler
                            </li>
                          ):null
                      }
                      {
                          parkRadari == 'true' ? (
                            <li className="tag-li">
                                <img src={Parking}/>
                                Park radari
                            </li>
                          ):null
                      }
                      {
                          monitor == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Monitor
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinIsidilmesi == 'true' ? (
                            <li className="tag-li">
                                <img src={Seat}/>
                                Oturacaqlarin isidilmesi
                            </li>
                          ):null
                      }
                      <li className="tag-li">
                            <img src={situation1}/>
                            {situation}
                        </li>
                        <li className="tag-li">
                            <img src={situation22}/>
                            {situation2}
                        </li>
                  </ul>
              </div>
        </div>
        </div>
</div>
        </>
    )
}

export default ProductInfoAbout
