import React from 'react'
import { useDispatch } from 'react-redux';
import { getTestDriveApi } from "../../redux/actions";
import { DateFormat } from "../DateFormat";


export const OneMinuteTable = ({customers}:any) => {
     let dispatch = useDispatch();

    const assignMe = (elan:any) => {
        let userId:any = localStorage.getItem('userId');
        let token:any = localStorage.getItem('token')
        
            fetch(`https://otomallbusiness.digimall.az/api/VisitRequest/AssignVisitRequest`,{
                  method:'PUT',
                  headers:{
                      'Content-Type': 'application/json',
                      'Bearer':String(token),
                      'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
                  },
                  body:JSON.stringify({
                      "id": elan,
                      "userId": userId
                  })
            }).then(res=>res.json()).then(data=>console.log(data))
        
        
      };
  
  
      const unAssignMe = (elan:any) => {
          let userId:any = localStorage.getItem('userId');
          let token:any = localStorage.getItem('token')
              fetch(`https://otomallbusiness.digimall.az/api/VisitRequest/AssignVisitRequest`,{
                  method:'PUT',
                  headers:{
                      'Content-Type': 'application/json',
                      'Bearer':String(token),
                      'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751',
                  },
                  body:JSON.stringify({
                      "id": elan
                  })
            }).then(res=>res.json()).then(data=>dispatch(getTestDriveApi()))
          
      };

  return (
    <>
 
        {customers && customers.map((elan:any,index:any)=>(
             <tr key={elan.id}>
             <th scope="row">{index+1}</th>
       

             {elan.phone?(
                 <td>{elan.phone}</td>

             ):(
                 <td>{elan.cellPhone}</td>

             )}
             {/* <td> {elan.operatorName||elan.operator} </td> */}
             {/* <td> {elan.markaName} </td>
             <td> {elan.modelName} </td> */}
              
             <td> <DateFormat date={elan.createAt} /> </td>
             <td className='btn-group dropend'><i className="fas fa-user-cog dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ></i>
             {
                 elan.operatorName == null ?(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item' onClick={()=>assignMe(elan.id)}>təyin et</button>
                     </ul>
                 ):(
                     <ul className='dropdown-menu'>
                         <button className='dropdown-item' onClick={()=>unAssignMe(elan.id)}>təyinatı ləğv et</button>
                     </ul>
                 )
             }
             </td>
         </tr>
        ))}
    </>
  )
}
