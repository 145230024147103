import React, { useState, useEffect } from 'react'
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// import Rightchevron from "../../icons/right-chevron.svg";
import { getIndisatDetail } from "../../redux/actions";


import '../../styles/Product.css'
import '../../styles/ProductInfo.css'

import '../../styles/ImagePopUp.css'

import Rectangle from "../../icons/rectangle86.svg";
import  Wheel  from "../../icons/wheel.svg";
import Abs from "../../icons/abs.svg";
import CarWash from "../../icons/car-wash.svg";
import Handbreak from "../../icons/handbrake.svg";
import Light from "../../icons/light.svg";
import Snowflake from "../../icons/snowflake.svg";
import Rightchevron from "../../icons/right-chevron.svg";
import FingerPrint from "../../icons/fingerprint 1.svg";
import LyukIcon from "../../icons/lift 1.svg";
import DeriSalonIcon from "../../icons/car 10.svg";
import Ventilyasiya from "../../icons/both 1.svg";
import Kamera from "../../icons/camera 1.svg";
import Start from "../../icons/carkey 1.svg";
import Perde from "../../icons/back 1.svg";
import Parking from "../../icons/parking.svg";
import Seat from "../../icons/seat 1.svg";


import GasStation from "../../icons/gas-station.svg";
import Calendar from "../../icons/calendar.svg";
import Gasoline from "../../icons/gasoline.svg";
import Shifter from "../../icons/shifter.svg";
import Speedometr from "../../icons/speedometer.svg";
import Speedometr2 from "../../icons/speedometer2.svg";
import Transmission from "../../icons/transmission.svg";
import CarPainting from "../../icons/car-painting.svg";
import TrafficJam from "../../icons/traffic-jam.svg";
import  Snow from "../../images/toyota.jpg";



export const IndisatDetail = () => {

    let dispatch = useDispatch();
    
    const { indisat } = useSelector((state:any)=>state.data);
    console.log(indisat)

    const {id}:any = useParams();


    const [display, setDisplay] = useState(false)

    const [image, setImage] = useState('')
    const [yanacaq, setYanacaq] = useState('')
    const [buraxilisIli, setBuraxilisIli] = useState('')
    const [muherrik, setMuherrik] = useState('')
    const [oturucu, setOturucu] = useState('')
    const [yurus, setYurus] = useState('')
    const [atgucu, setAtgucu] = useState('')
    const [suretQutusu, setSuretQutusu] = useState('')
    const [reng, setReng] = useState('')
    const [banNovu, setBanNovu] = useState('')

    const [solOnQanad, setSolOnQanad] = useState('rengli')
    const [solOnQapi, setSolOnQapi] = useState('rengli')
    const [solArxaQapi, setSolArxaQapi] = useState('rengli')
    const [solArxaQanad, setSolArxaQanad] = useState('rengli')
    const [onKapot, setOnKapot] = useState('rengli')
    const [onBufer, setOnBufer] = useState('rengli')
    const [dam, setDam] = useState('rengli')
    const [sagArxaQanad, setSagArxaQanad] = useState('rengli')
    const [sagOnQanad, setSagOnQanad] = useState('rengli')
    const [sagOnQapi, setSagOnQapi] = useState('rengli')
    const [sagArxaQapi, setSagArxaQapi] = useState('rengli')
    const [arxaKapot, setArxaKapot] = useState('rengli')
    const [arxaBufer, setArxaBufer] = useState('rengli')

    const [image2, setImage2] = useState('')   // 1
    const [image3, setImage3] = useState('')   // 2
    const [image4, setImage4] = useState('')   // 3
    const [image5, setImage5] = useState('')   // 4
    const [image6, setImage6] = useState('')   // 5
    const [image7, setImage7] = useState('')   // 6
    const [image8, setImage8] = useState('')   // 7
    const [image9, setImage9] = useState('')   // 8
    const [image10, setImage10] = useState('') // 9
    const [image11, setImage11] = useState('') // 10
    const [image12, setImage12] = useState('') // 11
    const [image13, setImage13] = useState('') // 12
    const [image14, setImage14] = useState('') // 13
    const [image15, setImage15] = useState('') // 14
    const [image16, setImage16] = useState('') // 15
    const [image17, setImage17] = useState('') // 16

    const [yungulLehm, setYungulLehm] = useState('false')
    const [abs, setAbs] = useState('false')
    const [yagisSensoru, setYagisSensoru] = useState('false')
    const [merkeziQapanma, setMerkeziQapanma] = useState('false')
    const [ksenon, setKsenon] = useState('false')
    const [kondisioner, setKondisioner] = useState('false')
    const [aciqlama, setAciqlama] = useState('');
    const [keylessGo, setKeylessGo] = useState('')
    const [lyuk, setLyuk] = useState('')
    const [deriSalon, setDeriSalon] = useState('')
    const [oturacaqlarinVentilyasiyasi, setOturacaqlarinVentilyasiyasi] = useState('');
    const [arxaKamera, setArxaKamera] = useState('')
    const [startStop, setStartStop] = useState('')
    const [yanPerdeler, setYanPerdeler] = useState('')
    const [parkRadari, setParkRadari] = useState('')
    const [monitor, setMonitor] = useState('')
    const [oturacaqlarinIsidilmesi, setOturacaqlarinIsidilmesi] = useState('')




    const [active, setActive] = useState(false);
    const [active1, setActive1] = useState(false);
    const [active2, setActive2] = useState(false);

    useEffect(() => {
        dispatch(getIndisatDetail(id))
    }, [])

     
    const toggleClass = () => {
            // setBuraxilisIli(indisat.year)
         if (indisat.dynamicFiledLists[26] && indisat.dynamicFiledLists[26].value == 'Rəngli') {
            setSolOnQanad('rengli')
        }else if(indisat.dynamicFiledLists[26] && indisat.dynamicFiledLists[26].value == 'Dəyişdirilib'){
            setSolOnQanad('deyisdirilib')
        }else{
            setSolOnQanad('orijinal')
        }

        if (indisat.dynamicFiledLists[28] && indisat.dynamicFiledLists[28].value == 'Rəngli') {
            setSolOnQapi('rengli')
        }else if(indisat.dynamicFiledLists[28] && indisat.dynamicFiledLists[28].value == 'Dəyişdirilib'){
            setSolOnQapi('deyisdirilib')
        }else{
            setSolOnQapi('orijinal')
        }

        if (indisat.dynamicFiledLists[29] && indisat.dynamicFiledLists[29].value == 'Rəngli') {
            setSolArxaQapi('rengli')
        }else if(indisat.dynamicFiledLists[29] && indisat.dynamicFiledLists[29].value == 'Dəyişdirilib'){
            setSolArxaQapi('deyisdirilib')
        }else{
            setSolArxaQapi('orijinal')
        }

        if (indisat.dynamicFiledLists[27] && indisat.dynamicFiledLists[27].value == 'Rəngli') {
            setSolArxaQanad('rengli')
        }else if(indisat.dynamicFiledLists[27] && indisat.dynamicFiledLists[27].value == 'Dəyişdirilib'){
            setSolArxaQanad('deyisdirilib')
        }else{
            setSolArxaQanad('orijinal')
        }

        if (indisat.dynamicFiledLists[30] && indisat.dynamicFiledLists[30].value == 'Rəngli') {
            setOnKapot('rengli')
        }else if(indisat.dynamicFiledLists[30] && indisat.dynamicFiledLists[30].value == 'Dəyişdirilib'){
            setOnKapot('deyisdirilib')
        }else{
            setOnKapot('orijinal')
        }

        if (indisat.dynamicFiledLists[31] && indisat.dynamicFiledLists[31].value == 'Rəngli') {
            setOnBufer('rengli')
        }else if(indisat.dynamicFiledLists[31] && indisat.dynamicFiledLists[31].value == 'Dəyişdirilib'){
            setOnBufer('deyisdirilib')
        }else{
            setOnBufer('orijinal')
        }

        if (indisat.dynamicFiledLists[32] && indisat.dynamicFiledLists[32].value == 'Rəngli') {
            setDam('rengli')
        }else if(indisat.dynamicFiledLists[32] && indisat.dynamicFiledLists[32].value == 'Dəyişdirilib'){
            setDam('deyisdirilib')
        }else{
            setDam('orijinal')
        }

        if (indisat.dynamicFiledLists[33] && indisat.dynamicFiledLists[33].value == 'Rəngli') {
            setSagArxaQanad('rengli')
        }else if(indisat.dynamicFiledLists[33] && indisat.dynamicFiledLists[33].value == 'Dəyişdirilib'){
            setSagArxaQanad('deyisdirilib')
        }else{
            setSagArxaQanad('orijinal')
        }

        if (indisat.dynamicFiledLists[36] && indisat.dynamicFiledLists[36].value == 'Rəngli') {
            setSagOnQanad('rengli')
        }else if(indisat.dynamicFiledLists[36] && indisat.dynamicFiledLists[36].value == 'Dəyişdirilib'){
            setSagOnQanad('deyisdirilib')
        }else{
            setSagOnQanad('orijinal')
        }

        if (indisat.dynamicFiledLists[34] && indisat.dynamicFiledLists[34].value == 'Rəngli') {
            setSagOnQapi('rengli')
        }else if(indisat.dynamicFiledLists[34] && indisat.dynamicFiledLists[34].value == 'Dəyişdirilib'){
            setSagOnQapi('deyisdirilib')
        }else{
            setSagOnQapi('orijinal')
        }

        if (indisat.dynamicFiledLists[35] && indisat.dynamicFiledLists[35].value == 'Rəngli') {
            setSagArxaQapi('rengli')
        }else if(indisat.dynamicFiledLists[35] && indisat.dynamicFiledLists[35].value == 'Dəyişdirilib'){
            setSagArxaQapi('deyisdirilib')
        }else{
            setSagArxaQapi('orijinal')
        }

        if (indisat.dynamicFiledLists[37] && indisat.dynamicFiledLists[37].value == 'Rəngli') {
            setArxaKapot('rengli')
        }else if(indisat.dynamicFiledLists[37] && indisat.dynamicFiledLists[37].value == 'Dəyişdirilib'){
            setArxaKapot('deyisdirilib')
        }else{
            setArxaKapot('orijinal')
        }

        if (indisat.dynamicFiledLists[38] && indisat.dynamicFiledLists[38].value == 'Rəngli') {
            setArxaBufer('rengli')
        }else if(indisat.dynamicFiledLists[38] && indisat.dynamicFiledLists[38].value == 'Dəyişdirilib'){
            setArxaBufer('deyisdirilib')
        }else{
            setArxaBufer('orijinal')
        }

        setActive(!active);
    }

    
    const toggleClass1 = () => {
        console.log('dynamic field -> ',indisat)
        indisat.dynamicFiledLists[6] && setYanacaq(indisat.dynamicFiledLists[6].value)
        // indisat.dynamicFiledLists[11] && setBuraxilisIli(indisat.dynamicFiledLists[11].value)
        indisat.dynamicFiledLists[9] && setMuherrik(indisat.dynamicFiledLists[9].value)
        indisat.dynamicFiledLists[4] && setOturucu(indisat.dynamicFiledLists[4].value)
        indisat.dynamicFiledLists[3] && setYurus(indisat.dynamicFiledLists[3].value)
        // indisat.dynamicFiledLists[10] && setAtgucu(indisat.dynamicFiledLists[10].value)
        indisat.dynamicFiledLists[8] && setSuretQutusu(indisat.dynamicFiledLists[8].value)
        indisat.dynamicFiledLists[7] && setReng(indisat.dynamicFiledLists[7].value)
        indisat.dynamicFiledLists[5] && setBanNovu(indisat.dynamicFiledLists[5].value)
        indisat.dynamicFiledLists[10] && setLyuk(indisat.dynamicFiledLists[10].value)
        indisat.dynamicFiledLists[16] && setOturacaqlarinVentilyasiyasi(indisat.dynamicFiledLists[16].value)
        indisat.dynamicFiledLists[20] && setArxaKamera(indisat.dynamicFiledLists[20].value)
        indisat.dynamicFiledLists[11] && setYanPerdeler(indisat.dynamicFiledLists[11].value)
        indisat.dynamicFiledLists[24] && setParkRadari(indisat.dynamicFiledLists[24].value)
        indisat.dynamicFiledLists[12] && setOturacaqlarinIsidilmesi(indisat.dynamicFiledLists[12].value)



        indisat.dynamicFiledLists[40] && setImage2(indisat.dynamicFiledLists[40].value)
        indisat.dynamicFiledLists[41] && setImage3(indisat.dynamicFiledLists[41].value)
        indisat.dynamicFiledLists[42] && setImage4(indisat.dynamicFiledLists[42].value)
        indisat.dynamicFiledLists[43] && setImage5(indisat.dynamicFiledLists[43].value)
        indisat.dynamicFiledLists[44] && setImage6(indisat.dynamicFiledLists[44].value)
        indisat.dynamicFiledLists[45] && setImage7(indisat.dynamicFiledLists[45].value)
        indisat.dynamicFiledLists[46] && setImage8(indisat.dynamicFiledLists[46].value)
        indisat.dynamicFiledLists[47] && setImage9(indisat.dynamicFiledLists[47].value)
        indisat.dynamicFiledLists[48] && setImage10(indisat.dynamicFiledLists[48].value)
        indisat.dynamicFiledLists[49] && setImage11(indisat.dynamicFiledLists[49].value)
        indisat.dynamicFiledLists[50] && setImage12(indisat.dynamicFiledLists[50].value)
        indisat.dynamicFiledLists[51] && setImage13(indisat.dynamicFiledLists[51].value)
        indisat.dynamicFiledLists[52] && setImage14(indisat.dynamicFiledLists[52].value)
        indisat.dynamicFiledLists[53] && setImage15(indisat.dynamicFiledLists[53].value)
        indisat.dynamicFiledLists[54] && setImage16(indisat.dynamicFiledLists[54].value)
        indisat.dynamicFiledLists[55] && setImage17(indisat.dynamicFiledLists[55].value)
        setActive1(!active1)
    }

    const toggleClass2 = () => {
        indisat.dynamicFiledLists[25] && setYungulLehm(indisat.dynamicFiledLists[25].value)
        indisat.dynamicFiledLists[22] && setMerkeziQapanma(indisat.dynamicFiledLists[22].value)
        indisat.dynamicFiledLists[13] && setAbs(indisat.dynamicFiledLists[13].value)
        indisat.dynamicFiledLists[21] && setKsenon(indisat.dynamicFiledLists[21].value)
        indisat.dynamicFiledLists[23] && setKondisioner(indisat.dynamicFiledLists[23].value)
        indisat.dynamicFiledLists[14] && setYagisSensoru(indisat.dynamicFiledLists[14].value)
        indisat.dynamicFiledLists[15] && setKeylessGo(indisat.dynamicFiledLists[15].value)
        indisat.dynamicFiledLists[2]  && setAciqlama(indisat.dynamicFiledLists[2].value)
        indisat.dynamicFiledLists[19] && setDeriSalon(indisat.dynamicFiledLists[19].value)
        indisat.dynamicFiledLists[17] && setStartStop(indisat.dynamicFiledLists[17].value)
        indisat.dynamicFiledLists[18] && setMonitor(indisat.dynamicFiledLists[18].value)
          setActive2(!active2);
    }
    

    const PopUp =( modalId:string ) =>{
        console.log('modal id -> ',modalId)
        setDisplay(true)
        setImage(modalId)
        console.log(`data -> `, indisat)
        
    }

    const PopDown = () => {
        setDisplay(false)
        setImage('')
    }

    console.log(image, 'image');
console.log(image2, 'image2');
console.log(image3, 'image3');
console.log(image4, 'image3');


     return (
        <>
            <div className="col-lg-10 col-12 p-0">
        <div className="product-info-container">
              <div className="info-top d-flex">
                  <h2 className="product-name">{indisat.markaName} {indisat.modelName}</h2>
                  <div className="product-price m-auto">
                      <img src={Rectangle}/>
                      <p className="product-price">{indisat.price} 
                      <span className="currency-span">AZN</span>
                      </p>
                  </div>
              </div>
              <div className={!active1 ? "tab-item" : "tab-item active"} onClick={toggleClass1}>
              <span>Məlumat</span>
            <img src={Rightchevron}/></div>
            <div className="tab-item-info">
              <div className="d-flex">
              <div className="all__images">
                      {
                        image2!='null'&& image2!='false' && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image2} className="all__images--image" id='img01' onClick={()=>PopUp(image2)}/>
                     }
                    {
                        image3!='null'&& image2!='false' && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image3} className="all__images--image" id='img02' onClick={()=>PopUp(image3)}/>
                    }
                    {
                        image4!='null'&& image2!='false' && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image4} className="all__images--image" id='img03' onClick={()=>PopUp(image4)}/>
                    }
                    {
                        image5 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image5} className="all__images--image" id='img04' onClick={()=>PopUp(image5)}/>
                    }
                    {
                        image6 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image6} className="all__images--image" id='img05' onClick={()=>PopUp(image6)}/>
                    }
                    {
                        image7 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image7} className="all__images--image" id='img06' onClick={()=>PopUp(image7)}/>
                    }
                    {
                        image8 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image8} className="all__images--image" id='img07' onClick={()=>PopUp(image8)}/>
                    }
                    {
                        image9 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image9} className="all__images--image" id='img08' onClick={()=>PopUp(image9)}/> 
                    }
                    {
                        image10 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image10} className="all__images--image" id='img09' onClick={()=>PopUp(image10)}/>
                    }
 
                    {
                        image11 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image11} className="all__images--image" id='img10' onClick={()=>PopUp(image11)}/>
                    }
                    {
                        image12 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image12} className="all__images--image" id='img11' onClick={()=>PopUp(image12)}/>
                    }
                    {
                        image13 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image13} className="all__images--image" id='img12' onClick={()=>PopUp(image13)}/>
                    }
                    {
                        image14 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image14} className="all__images--image" id='img13' onClick={()=>PopUp(image14)}/>
                    }
                    {
                        image15 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image15} className="all__images--image" id='img14' onClick={()=>PopUp(image15)}/>
                    }
                     {
                        image16 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image16} className="all__images--image" id='img15' onClick={()=>PopUp(image16)}/>
                    }
                    {
                        image17 && <img src={"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+image17} className="all__images--image" id='img16' onClick={()=>PopUp(image17)}/>
                    }
              </div>
              <div className='mt-4'>
                  <div>
              <div className="customer__info">
                <label htmlFor="name">
                    Müştəri: 
                    </label>
                    <input type="text" disabled value={indisat.fullName}/>
                
                </div> 

                
</div>


                
                </div> 
                </div> 
              <div id="myModal" className="modal" style={{display:display ? 'block' : 'none' }}>
                <span className="close" onClick={PopDown}>&times;</span>
                <img className="modal-content" id="modalImg" src={image}/>
            </div>
              <div className="product-tags">
                  <ul className="tags-ul">

                      <li className="tag-li">
                          <img src={GasStation}/>
                          {yanacaq}
                      </li>
                      <li className="tag-li">
                          <img src={Gasoline}/>
                           {muherrik} L
                      </li>
                      <li className="tag-li">
                          <img src={Shifter}/>
                          {oturucu}
                      </li>
                      <li className="tag-li">
                          <img src={Speedometr}/>
                          {yurus} km
                      </li>
                      <li className="tag-li">
                          <img src={Transmission}/>
                          {suretQutusu}
                      </li>
                      <li className="tag-li">
                          <img src={CarPainting}/>
                          {reng}
                      </li>
                      <li className="tag-li">
                      <img src={TrafficJam}/>
                          {banNovu}
                      </li>

                      <li className="tag-li">
                      <img src={Calendar}/>
                          {indisat.year}
                      </li>
                  </ul>
              </div>
              </div>
              <div className="tab-group">
    <div className={!active ? "tab-item" : "tab-item active"} onClick={toggleClass}>
             <span>Vəziyyəti </span>
            <img src={Rightchevron}/>
         </div>
         <div className="tab-item-info">
         <div className="color-tags-container">
              <ul className="color-tags">
                 <li className={solOnQanad}>Sol ön qanad</li>
                 <li className={solOnQapi}>Sol ön qapı</li>
                 <li className={solArxaQapi}>Sol arxa qapı</li>
                 <li className={solArxaQanad}>Sol arxa qanad</li>
                 <li className={onKapot}>Ön kapot</li>
                 <li className={onBufer}>Ön bufer</li>
                 <li className={dam}>Dam</li>
                 <li className={sagArxaQanad}>Sağ arxa qanad</li>
                 <li className={sagOnQanad}>Sağ ön qanad</li>
                 <li className={sagOnQapi}>Sağ ön qapı</li>
                 <li className={sagArxaQapi}>Sağ arxa qapı</li>
                 <li className={arxaKapot}>Arxa kapot</li>
                 <li className={arxaBufer}>Arxa bufer</li>
             </ul>
        </div>
         </div>
    </div>
    <div className=''>
              <div className="tab-group">
        <div className={!active2 ? "tab-item" : "tab-item active"} onClick={toggleClass2} style={{backgroundColor:'#f8f8f8'}}>
             <span>Avtomobil haqqında </span>
            <img src={Rightchevron}/>
         </div>
        <div className="tab-item-info">
       <p> {aciqlama}</p>
        <div className="product-tags">
                  <ul className="tags-ul">
                  {
                          yungulLehm =='true' ? (
                            <li className="tag-li">
                                <img src={Wheel}/>
                                Yüngül lehimli disklər
                            </li>
                          ):null
                      }
                      {
                          abs == 'true' ? (
                            <li className="tag-li">
                                <img src={Abs}/>
                                ABS
                            </li>
                          ):null
                      }
                      {
                          yagisSensoru == 'true' ? (

                            <li className="tag-li">
                                <img src={CarWash}/>
                                Yağış sensoru
                            </li>
                          ):null
                      }
                      {
                          merkeziQapanma == 'true' ? (
                            <li className="tag-li">
                                <img src={Handbreak}/>
                                Mərkəzi qapanma
                            </li>
                          ):null
                      }
                      {
                          ksenon == 'true' ? (
                            <li className="tag-li">
                                <img src={Light}/>
                                Ksenon Lampalar
                            </li>
                          ):null
                      }{
                        keylessGo == 'true' ? (
                          <li className="tag-li">
                              <img src={FingerPrint}/>
                              Keyless go
                          </li>
                        ):null
                    }
                       {
                          kondisioner == 'true' ? (
                            <li className="tag-li">
                                <img src={Snowflake}/>
                                Kondisioner
                            </li>
                          ):null
                      }
                      {
                          lyuk == 'true' ? (
                            <li className="tag-li">
                                <img src={LyukIcon}/>
                                Lyuk
                            </li>
                          ):null
                      }
                      {
                          deriSalon == 'true' ? (
                            <li className="tag-li">
                                <img src={DeriSalonIcon}/>
                                Deri Salon
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinVentilyasiyasi == 'true' ? (
                            <li className="tag-li">
                                <img src={Ventilyasiya}/>
                                Oturacaqlarin ventilyasiyasi
                            </li>
                          ):null
                      }
                      {
                          arxaKamera == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Arxa goruntu kamerasi
                            </li>
                          ):null
                      }
                      {
                          startStop == 'true' ? (
                            <li className="tag-li">
                                <img src={Start}/>
                                Start/Stop
                            </li>
                          ):null
                      }
                      {
                          yanPerdeler == 'true' ? (
                            <li className="tag-li">
                                <img src={Perde}/>
                                Yan perdeler
                            </li>
                          ):null
                      }
                      {
                          parkRadari == 'true' ? (
                            <li className="tag-li">
                                <img src={Parking}/>
                                Park radari
                            </li>
                          ):null
                      }
                      {
                          monitor == 'true' ? (
                            <li className="tag-li">
                                <img src={Kamera}/>
                                Monitor
                            </li>
                          ):null
                      }
                      {
                          oturacaqlarinIsidilmesi == 'true' ? (
                            <li className="tag-li">
                                <img src={Seat}/>
                                Oturacaqlarin isidilmesi
                            </li>
                          ):null
                      }
                  </ul>
              </div>
        </div>
        </div>
</div>
              </div>
</div>
        </>
    )
}
