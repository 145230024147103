import axios, { AxiosResponse } from "axios";

const responseBody = <T>(response: AxiosResponse<T>) => {
  return response.data
}

axios.defaults.baseURL = "https://apis.digimall.az/api";

const Model_Marka = {
  getMarka: () =>
    axios
      .get<any>('/Products/GetBrandsByParentId', {
        headers: { 'api-key': "e4246c59-4357-4c5e-a5a9-af4307c4f751" },
      })
      .then(responseBody),

  getModel: (parentId: number | null) =>
    axios.get<any>('/Products/GetBrandsByParentId?parentId=' + parentId,
      { headers: { 'api-key': "e4246c59-4357-4c5e-a5a9-af4307c4f751" } })
      .then(responseBody)
}

const Elan = {
  CreateElan: (body: any) =>
    axios.post<any>("/Otomall/CreateNewElan", body,
      { headers: { 'api-key': "e4246c59-4357-4c5e-a5a9-af4307c4f751" } })
      .then(responseBody),
  ElanPictureAdd: (formData: any) => axios.post("https://otomallbusiness.digimall.az/api/Image/UploadImageViaFtp", formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'api-key': "e4246c59-4357-4c5e-a5a9-af4307c4f751"
    }
  })
    .then((response) => {

      if (response.status >= 200 && response.status <= 220) {
        return response.data
      }
      return false

    })
}

export const getTurbo = async (body: any) => {
  try {
    return await axios.post(
      `https://tascp2.otomall.az/api/TurboAz`,
      body
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

const agent = {
  Model_Marka,
  Elan,
};

export default agent;
