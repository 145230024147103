import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getActiveRandevuApi, getCancelledRandevuApi, getDoneRandevuApi, getPendingRandevuApi } from "../../redux/actions";
import { DateFormat } from "../DateFormat";
import { OperationsPopUp } from "../OperationsPopUp";
import {
  ExcelExport,
  ExcelExportColumn
} from "@progress/kendo-react-excel-export";
  

import {dateSplitterForRandevuArray, changeDateInArray} from "../functions"
import {
  process
} from "@progress/kendo-data-query";

export const Randevu = () => {

    const dispatch = useDispatch();
    const { randevular } = useSelector((state:any) => state.data);

    const [pendingActive, setPendingActive] = useState(true)
    const [isActive, setIsActive] = useState(false)
    const [cancelledActive, setCancelledActive] = useState(false)
    const [doneActive, setDoneActive] = useState(false)


    useEffect(() => {
      dispatch(getPendingRandevuApi());     
      return () => {
      }
    }, [])



    const getPendingRandevu = () => {
      dispatch(getPendingRandevuApi())
      setPendingActive(true)
      setIsActive(false)
      setCancelledActive(false)
      setDoneActive(false)
    }

    const getCancelledRandevu = () => {
      dispatch(getCancelledRandevuApi())
      setPendingActive(false)
      setIsActive(false)
      setCancelledActive(true)
      setDoneActive(false)
    }

    const getActiveRandevu = () => {
      dispatch(getActiveRandevuApi())
      setPendingActive(false)
      setIsActive(true)
      setCancelledActive(false)
      setDoneActive(false)
    }

    const getDoneRandevu = () => {
      dispatch(getDoneRandevuApi())
      setPendingActive(false)
      setIsActive(false)
      setCancelledActive(false)
      setDoneActive(true)
    }
    




    const data = process(dateSplitterForRandevuArray(changeDateInArray(randevular)), {
    }).data;


    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
        _exporter.current.save();
        }
    };
   
    return (
        <>
        
            <div className="col-lg-10 col-12 p-0 table-responsive">
        <span className="title"> Randevular  </span>
        <div className="nav-small">
            <ul className="d-flex p-0 flex-wrap">
                <li className={pendingActive ? 'active_nav' : ''} onClick={getPendingRandevu}>
                    Pending
                </li>
                <li className={isActive? 'active_nav':''} onClick={getActiveRandevu}>
                    Active
                </li>
                <li className={cancelledActive? 'active_nav':''} onClick={getCancelledRandevu}>
                    Cancelled
                </li>
                <li className={doneActive? 'active_nav':''} onClick={getDoneRandevu}>
                    Done
                </li>
                <div>
                <button className="k-button" onClick={exportExcel}>
                    Export to Excel
                </button>

                <ExcelExport
                    data={data}
                    fileName="Randevular.xlsx"
                    ref={_exporter}
                >
                    <ExcelExportColumn
                    field="id"
                    title="ID"
                    locked={true}
                    width={60}
                    />
                    <ExcelExportColumn
                    field="userName"
                    title="Müştəri"
                    width={100}
                    />
                    <ExcelExportColumn
                        field="mobile"
                        title="telefon nomresi"
                        // cellOptions={{ format: "$#,##0.00" }}
                        // width={150}
                        // footerCellOptions={{ wrap: true, textAlign: "center" }}
                        // groupFooterCellOptions={{ textAlign: "right" }}
                        width={100}
                    />

                  <ExcelExportColumn
                        field="gun"
                        title="görüş günü"
                        width={100}
                    />
                        <ExcelExportColumn
                        field="saat"
                        title="görüş saatı"
                        width={100}
                    />
                    <ExcelExportColumn field="createAt" title="Yaranma tarixi" width={200}/>
                    {/* <ExcelExportColumn field="status" title="Status" /> */}
                    <ExcelExportColumn
                    field="Discontinued"
                    title="Discontinued"
                    hidden={true}
                    />
                </ExcelExport>
                        </div>
            </ul>
        </div>
        {console.log('randevular',randevular)}
        <table className="table table-sm table-bordered text-center">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">Müştəri </th>
                <th scope="col">Telefon nomresi</th>
                <th scope="col">Saat</th>
                <th scope="col">Gün</th>
                <th scope="col">Əməliyyatlar</th>
              </tr>
            </thead>
            <tbody>
         
              {randevular && randevular.map((data:any,index:any)=>(
                <>
              <tr key={index}>
                <th scope="row">{index+1}</th>
                <td>{data.userName}</td>
                <td>+994{data.mobile}</td>
                <td>{String(data.date).split('T')[1]}</td>
                <td><DateFormat date={data.date}/></td>
                
                  <span className="modalButton" data-bs-toggle="modal" data-bs-target={`#exampleModal${data.id}`}>
                      <i className="fas fa-user-cog"></i>
                  </span>
              
              </tr>
                <OperationsPopUp username={data.userName} title={data.indisatTitle} date={data.date} inisatId={data.indiSatPriceId} mobile={data.mobile} randevuId={data.id}/>

              </>
              ))}
            </tbody>
          </table>
    </div>
    
        </>
    )
}
