import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getElanById } from "../../../redux/actions";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../../styles/Product.css";
import "../../../styles/ImagePopUp.css";
import Rectangle from "../../../icons/rectangle86.svg";
import GasStation from "../../../icons/gas-station.svg";
import Calendar from "../../../icons/calendar.svg";
import Gasoline from "../../../icons/gasoline.svg";
import Speedometr from "../../../icons/speedometer.svg";
import Speedometr2 from "../../../icons/speedometer2.svg";
import Transmission from "../../../icons/transmission.svg";
import CarPainting from "../../../icons/car-painting.svg";
import TrafficJam from "../../../icons/traffic-jam.svg";
import Rightchevron from "../../../icons/right-chevron.svg";
import Snow from "../../../images/toyota.jpg";
import ProductCreditInfo from "./ProductCreditInfo";
import ProductInfoAbout from "./ProductInfoAbout";
import ProductSituation from "./ProductSituation";

export const Product = () => {
    let dispatch: any = useDispatch();
    const { elan, dynamicFields } = useSelector((state: any) => state.data);
    console.log(elan);

    // "10108:Açiqlama:fcgdxrt:1;10112:Yürüs:345780:2;10125:Avtomobilv?ziyy?tii:0 km:4;10107:Ötürücü:Arxa:4;10110:Bannövü:SUV:4;10105:Yanacaqtipi:Benzin:4;10113:R?ng:Qara:4;10106:Sür?tl?rqutusu:Robotlaşdırılmış:4;10114:S?h?r:Bakı:4;10103:Müh?rrikh?cmi:2200:4;10111:Atgücü:222:2;10104Buraxilisili:undefined:2;10163:Lyuk:false:6;10173:Yanp?rd?l?r:false:6;10168:Oturacaqlarinisidilm?si:false:6;10162:ABS:false:6;10172:Yagissensoru:false:6;10167:KeylessGo:false:6;10161:Oturacaqlarinventilyasiyasi:false:6;10171::Start/Stop:false:6;10166:Monitor:true:6;10160:D?risalon:false:6;10170:Arxagörüntükamerasi:false:6;10165:Ksenonlampalar:false:6;10159:M?rk?ziqapanma:false:6;10169:Kondisioner:false:6;10164:Parkradari:false:6;10158:Yüngüllehimlidiskl?r:true:6;10144::Solönqanad:Orijinal:4;10145:Solönqapi:Orijinal:4;10146:Solarxaqapi:Orijinal:4;10147:Önkapot:R?ngli:4;10148:Önbufer:Orijinal:4;10149:Dam:Orijinal:4;10150:Sagarxaqanad:Orijinal:4;10151:Sagönqapi:Orijinal:4;10152::Sagarxaqapi:Orijinal:4;10153:Sagönqanad:Orijinal:4;10154:Arxakapot:R?ngli:4;10155:Arxabufer:Orijinal:4;10156:Mühərrik:Orijinal:4;;15: OtoGallery Value : true:6;-1: The Priority Value :false:6;"

    const { id } = useParams();

    const [display, setDisplay] = useState<boolean>(false);
    const [isDeactivated, setIsDeactivated] = useState<boolean>(false);

    const [image, setImage] = useState<string>("");
    const [yanacaq, setYanacaq] = useState<string>("");
    const [buraxilisIli, setBuraxilisIli] = useState<string>("");
    const [muherrik, setMuherrik] = useState<string>("");
    const [oturucu, setOturucu] = useState<string>("");
    const [yurus, setYurus] = useState<string>("");
    const [atgucu, setAtgucu] = useState<string>("");
    const [suretQutusu, setSuretQutusu] = useState<string>("");
    const [reng, setReng] = useState<string>("");
    const [banNovu, setBanNovu] = useState<string>("");

    const [selectStatus, setSelectStatus] = useState(1);
    const [image2, setImage2] = useState("");
    const [image3, setImage3] = useState("");
    const [image4, setImage4] = useState("");
    const [image5, setImage5] = useState("");
    const [image6, setImage6] = useState("");
    const [image7, setImage7] = useState("");
    const [image8, setImage8] = useState("");
    const [image9, setImage9] = useState("");
    const [image10, setImage10] = useState("");
    const [image11, setImage11] = useState("");
    const [image12, setImage12] = useState("");
    const [image13, setImage13] = useState("");
    const [image14, setImage14] = useState("");
    const [image15, setImage15] = useState("");
    const [image16, setImage16] = useState("");
    const [image17, setImage17] = useState("");
    const [creditPercent, setCreditPercent] = useState("20");
    const [metaTitle, setMetaTitle] = useState(elan.title)
    const [metaKeyword, setMetaKeyword] = useState("")
    const [metaDesc, setMetaDesc] = useState("")

    const navigate = useNavigate();

    const [active, setActive] = useState<boolean>(false);

    const toggleClass = () => {
        setActive(!active);

        dynamicFields.forEach((element: any) => {
            if (element.name == "Yanacaqtipi") {
                setYanacaq(element.value);
            }
            if (element.name == "Ötürücü") {
                setOturucu(element.value);
            }

            if (element.name == "Bannövü") {
                setBanNovu(element.value);
            }

            if (element.name == "R?ng") {
                setReng(element.value);
            }

            if (element.name == "Buraxilisili") {
                setBuraxilisIli(element.value);
            }

            if (element.name == "Yürüs") {
                setYurus(element.value);
            }

            if (element.name == "Atgücü") {
                setAtgucu(element.value);
            }

            if (element.name == "Sür?tl?rqutusu") {
                setSuretQutusu(element.value);
            }

            if (element.name == "Müh?rrikh?cmi") {
                setMuherrik(element.value);
            }

            if (element.name.includes("mainimage")) {
                setImage2(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image1")) {
                setImage3(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image2")) {
                setImage4(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image3")) {
                setImage5(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image4")) {
                setImage6(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image5")) {
                setImage7(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image6")) {
                setImage8(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image7")) {
                setImage9(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image8")) {
                setImage10(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image9")) {
                setImage11(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image10")) {
                setImage12(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image11")) {
                setImage13(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image12")) {
                setImage14(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image13")) {
                setImage15(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image12")) {
                setImage16(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }

            if (element.name.includes("Image15")) {
                setImage17(
                    "https://ferrumcapital.s3.eu-north-1.amazonaws.com" +
                        element.value
                );
            }
        });
    };

    const PopUp = (modalId: string) => {
        setDisplay(true);
        setImage(modalId);
    };

    const PopDown = () => {
        setDisplay(false);
        setImage("");
    };

    useEffect(() => {
        dispatch(getElanById(id));
    }, []);

    let tempDynamic = elan.dynamicFieldList;

    useEffect(() => {
        console.log(tempDynamic);
        if (tempDynamic) {
            const objIndex1 = tempDynamic.findIndex(
                (obj: any) => obj.name == "Image2"
            );
            console.log(objIndex1);
            if (objIndex1 !== -1) {
                tempDynamic[objIndex1].name = "mainimage";
                tempDynamic[objIndex1].id = -2;
                tempDynamic[objIndex1].type = 5;
            }
        }
    }, [tempDynamic]);

    const Deactive = () => {
        let data = {
            id: elan.id,
            recordStatus: elan.recordStatus,
            createAt: elan.createAt,
            userId: elan.userId,
            apiKey: elan.apiKey,
            fullName: elan.fullName,
            phoneNumber: elan.phoneNumber,
            modelId: elan.modelId,
            markaId: elan.markaId,
            title: elan.title,
            price: elan.price,
            dynamicFields: elan.dynamicFields,
            status: 4,
        };

        fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "api-key": "e4246c59-4357-4c5e-a5a9-af4307c4f751",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.errorCode == null) {
                    console.log(data.errorCode);
                    setIsDeactivated(true);
                    getElanById(id);
                }
            });
    };

    const deleteElan = () => {
        fetch(
            `https://otomallbusiness.digimall.az/api/Elan/DeleteElan/${elan.id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "api-key": "e4246c59-4357-4c5e-a5a9-af4307c4f751",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => navigate("/crm/elanlar"));
    };

    const NoqteCheck = (e: any) => {
        if (e.target.checked) {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields + "4:100noqta:true:6;",
                status: elan.status,
            };

            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        } else {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields.replace(
                    "100noqta:true",
                    "100noqta:false"
                ),
                status: elan.status,
            };

            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        }
    };

    const BarterCheck = (e: any) => {
        if (e.target.checked) {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields + "10115:SatışNövü:Barter:4;",
                status: elan.status,
            };

            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        } else {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields.replace("Barter", "Kredit"),
                status: elan.status,
            };

            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        }
    };

    const TeciliCheck = (e: any) => {
        if (e.target.checked) {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields + "-1:Priority:true:-1;",
                status: elan.status,
            };

            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        } else {
            let data = {
                id: elan.id,
                recordStatus: elan.recordStatus,
                createAt: elan.createAt,
                userId: elan.userId,
                apiKey: elan.apiKey,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: elan.modelId,
                markaId: elan.markaId,
                title: elan.title,
                price: elan.price,
                dynamicFields: elan.dynamicFields.replace(
                    "Priority:true",
                    "Priority:false"
                ),
                status: elan.status,
            };
            fetch("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.errorCode == null) {
                        console.log(data);
                    }
                });
        }
    };

    console.log(elan)

    const changeStatus: any = async (e: any) => {
        let info = e.target.value;
        let dynamicfieldlist = [
            ...elan.dynamicFieldList,
            ...elan.elanFields.map((item: any) => {
                return {
                    id: item.attId,
                    name: item.name,
                    value: item.value,
                    type: +item.type,
                };
            }),
            {
                id: 15,
                name: "OtoGallery Value",
                value: creditPercent == "20" ? "true" : "false",
                type: 6,
            },
            {
                id: -1,
                name: "The Priority Value",
                value: "false",
                type: 6,
            },
            {
                id: 1,
                name: "musteriadi",
                value: elan.fullName,
                type: 0,
            },
            {
                id: 2,
                name: "musteritelefonu",
                value: elan.phoneNumber,
                type: 0,
            },
            {
                id: 10118,
                name: "satilib",
                value: "false",
                type: 6,
            },
        ];

        if (info == 2) {
            setSelectStatus(2);
            let convert_data = {
                id: Number(id),
                recordStatus: 0,
                userId: elan.userId,
                markaName: elan.markaName,
                modelName: elan.modelName,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: Number(elan.modelId),
                markaId: elan.markaId,
                title: elan.title,
                price: Number(elan.price),
                dynamicFields: "",
                status: 2,
                dynamicFieldList: dynamicfieldlist,
                elanFields: [
                    ...elan.elanFields,
                    {
                        id: 15,
                        name: "OtoGallery Value",
                        value: creditPercent == "20" ? "true" : "false",
                        type: "6",
                    },
                    {
                        id: 10179,
                        name: "Meta title",
                        value: metaTitle ? metaTitle : elan.title,
                        type: "0"
                    },
                    {
                        id: 10178,
                        name: "Meta keyword",
                        value: metaKeyword ? metaKeyword : "car",
                        type: "0" 
                    },
                     {
                        id: 10177,
                        name: "Meta description",
                        value: metaDesc ? metaDesc : "car",
                        type: "0" 
                    },
                    {
                        id: -1,
                        name: "The Priority Value",
                        value: "false",
                        type: "6",
                    },
                    {
                        id: 1,
                        name: "musteriadi",
                        value: elan.fullName,
                        type: "0",
                    },
                    {
                        id: 2,
                        name: "musteritelefonu",
                        value: elan.phoneNumber,
                        type: "0",
                    },
                    {
                        id: 10118,
                        name: "satilib",
                        value: "false",
                        type: "6",
                    },
                ],
            };

            let update_data = {
                id: Number(id),
                userId: elan.userId,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: Number(elan.modelId),
                modelName: elan.modelName,
                markaId: elan.markaId,
                markaName: elan.markaName,
                title: elan.title,
                price: Number(elan.price),
                dynamicFields:
                    elan.dynamicFields +
                    `15: OtoGallery Value : ${
                        creditPercent == "20" ? "true" : "false"
                    }:6;-1: The Priority Value :false:6;1:musteriadi:${
                        elan.fullName
                    }:0;2:mustertelefonu:${
                        elan.phoneNumber
                    }:0;10118:satilib:false:6`,
                dynamicFieldList: dynamicfieldlist,
                status: 2,
                elanFields: [
                    ...elan.elanFields,
                    {
                        id: 15,
                        name: "OtoGallery Value",
                        value: creditPercent == "20" ? "false" : "true",
                        type: "6",
                    },
                    {
                        id: -1,
                        name: "The Priority Value",
                        value: "false",
                        type: "6",
                    },
                    {
                        id: 1,
                        name: "musteriadi",
                        value: elan.fullName,
                        type: "0",
                    },
                    {
                        id: 2,
                        name: "musteritelefonu",
                        value: elan.phoneNumber,
                        type: "0",
                    },
                    {
                        id: 10118,
                        name: "satilib",
                        value: "false",
                        type: "6",
                    },
                    ,
                    {
                        id: 10179,
                        name: "Meta title",
                        value: metaTitle ? metaTitle : elan.title,
                        type: "0"
                    },
                    {
                        id: 10178,
                        name: "Meta keyword",
                        value: metaKeyword ? metaKeyword : "car",
                        type: "0" 
                    },
                     {
                        id: 10177,
                        name: "Meta description",
                        value: metaDesc ? metaDesc : "car",
                        type: "0" 
                    }
                ],
            };

            delete update_data.userId;
            delete convert_data.userId;

            await fetch(
                "https://otomallbusiness.digimall.az/api/Elan/ElanSaveIntoProductsWithArray",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "api-key": "e4246c59-4357-4c5e-a5a9-af4307c4f751",
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                    body: JSON.stringify(convert_data),
                }
            )
                .then((res) => res.text())
                .then((data) => {
                    fetch(
                        "https://otomallbusiness.digimall.az/api/Elan/UpdateElan",
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                "api-key":
                                    "e4246c59-4357-4c5e-a5a9-af4307c4f751",
                                Bearer: String(localStorage.getItem("token")),
                            },
                            body: JSON.stringify(update_data),
                        }
                    )
                        .then((res) => res.json())
                        .then((data) => console.log(data));
                })
                .catch((error) => console.log(error));
        } else if (info == 0) {
            setSelectStatus(0);
            let update_data = {
                id: Number(id),
                userId: elan.userId,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: Number(elan.modelId),
                modelName: elan.modelName,
                markaId: elan.markaId,
                markaName: elan.markaName,
                title: elan.title,
                price: Number(elan.price),
                dynamicFields:
                    elan.dynamicFields +
                    `15: OtoGallery Value : ${
                        creditPercent == "20" ? "true" : "false"
                    }:6;-1: The Priority Value :false:6;1:musteriadi:${
                        elan.fullName
                    }:0;2:mustertelefonu:${
                        elan.phoneNumber
                    }:0;10118:satilib:false:6`,
                dynamicFieldList: dynamicfieldlist,
                status: 0,
                elanFields: [
                    ...elan.elanFields,
                    {
                        id: 15,
                        name: "OtoGallery Value",
                        value: creditPercent == "20" ? "false" : "true",
                        type: "6",
                    },
                    {
                        id: -1,
                        name: "The Priority Value",
                        value: "false",
                        type: "6",
                    },
                    {
                        id: 1,
                        name: "musteriadi",
                        value: elan.fullName,
                        type: "0",
                    },
                    {
                        id: 2,
                        name: "musteritelefonu",
                        value: elan.phoneNumber,
                        type: "0",
                    },
                    {
                        id: 10118,
                        name: "satilib",
                        value: "false",
                        type: "6",
                    },
                    ,
                    {
                        id: 10179,
                        name: "Meta title",
                        value: metaTitle ? metaTitle : elan.title,
                        type: "0"
                    },
                    {
                        id: 10178,
                        name: "Meta keyword",
                        value: metaKeyword ? metaKeyword : "car",
                        type: "0" 
                    },
                     {
                        id: 10177,
                        name: "Meta description",
                        value: metaDesc ? metaDesc : "car",
                        type: "0" 
                    }
                ],
            };

            delete update_data.userId;

            fetch(
                "https://otomallbusiness.digimall.az/api/Elan/UpdateElan",
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "api-key":
                            "e4246c59-4357-4c5e-a5a9-af4307c4f751",
                        Bearer: String(localStorage.getItem("token")),
                    },
                    body: JSON.stringify(update_data),
                }
            )
                .then((res) => res.json())
                .then((data) => console.log(data))
                .catch((error) => console.log(error));
        } else if (info == 1) {
            setSelectStatus(1);
            let update_data = {
                id: Number(id),
                userId: elan.userId,
                fullName: elan.fullName,
                phoneNumber: elan.phoneNumber,
                modelId: Number(elan.modelId),
                modelName: elan.modelName,
                markaId: elan.markaId,
                markaName: elan.markaName,
                title: elan.title,
                price: Number(elan.price),
                dynamicFields:'',
                dynamicFieldList: dynamicfieldlist,
                status: 1,
                elanFields: [
                    ...elan.elanFields,
                    {
                        id: 15,
                        name: "OtoGallery Value",
                        value: creditPercent == "20" ? "false" : "true",
                        type: "6",
                    },
                    {
                        id: -1,
                        name: "The Priority Value",
                        value: "false",
                        type: "6",
                    },
                    {
                        id: 1,
                        name: "musteriadi",
                        value: elan.fullName,
                        type: "0",
                    },
                    {
                        id: 2,
                        name: "musteritelefonu",
                        value: elan.phoneNumber,
                        type: "0",
                    },
                    {
                        id: 10118,
                        name: "satilib",
                        value: "false",
                        type: "6",
                    },
                    ,
                    {
                        id: 10179,
                        name: "Meta title",
                        value: metaTitle ? metaTitle : elan.title,
                        type: "0"
                    },
                    {
                        id: 10178,
                        name: "Meta keyword",
                        value: metaKeyword ? metaKeyword : "car",
                        type: "0" 
                    },
                    {
                        id: 10177,
                        name: "Meta description",
                        value: metaDesc ? metaDesc : "car",
                        type: "0" 
                    }
                ],
            };

            delete update_data.userId;

            fetch(
                "https://otomallbusiness.digimall.az/api/Elan/UpdateElan",
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "api-key":
                            "e4246c59-4357-4c5e-a5a9-af4307c4f751",
                        Bearer: String(localStorage.getItem("token")),
                    },
                    body: JSON.stringify(update_data),
                }
            )
                .then((res) => res.json())
                .then((data) => console.log(data))
                .catch((error) => console.log(error));
        }
    };
    return (
        <>
            <div className="col-lg-10 col-12 p-0">
                <div className="product-info-container">
                    <div className="info-top d-flex w-100 justify-content-around">
                        <h2 className="product-name">{elan.title}</h2>
                        <div className="product-price">
                            <img src={Rectangle} />
                            <p className="product-price">
                                {elan.price}
                                <span className="currency-span">AZN</span>
                            </p>
                        </div>
                    </div>
                    <div className="common__div mt-5">
                        <div className="customer__info">
                            <label htmlFor="name">Müştəri:</label>
                            <input type="text" disabled value={elan.fullName} />
                            <label
                                htmlFor="name"
                                style={{ marginLeft: "30px" }}
                            >
                                Telefon:
                            </label>
                            <input
                                type="text"
                                disabled
                                value={elan.phoneNumber}
                            />

                            {elan.status == 2 ? (
                                <button
                                    onClick={deleteElan}
                                    type="button"
                                    className="btn btn-danger btn-sm mx-3"
                                >
                                    Sil
                                </button>
                            ) : null}
                        </div>

                        {(
                            <div className="mt-2">
                                <label htmlFor="">Status</label>
                                <select
                                    className="select text-center w-75 d-block"
                                    value={elan.status}
                                    onChange={(e) => changeStatus(e)}
                                    aria-label="Default select example"
                                    style={{
                                        borderRadius: "8px",
                                        height: "30px",
                                    }}
                                >
                                    {elan.status == 0 ? (
                                        <option selected value={0}>
                                            Təsdiq gözləyir{" "}
                                        </option>
                                    ) : (
                                        <option value={0}>
                                            Təsdiq gözləyir
                                        </option>
                                    )}

                                    {elan.status == 2 ? (
                                        <option selected value={2}>
                                            Təsdiq edildi
                                        </option>
                                    ) : (
                                        <option value={2}>Təsdiq edildi</option>
                                    )}

                                    {elan.status == 1 ? (
                                        <option selected value={1}>
                                            Təsdiq edilmədi
                                        </option>
                                    ) : (
                                        <option value={1}>
                                            Təsdiq edilmədi
                                        </option>
                                    )}

                                    {elan.status == 3 ? (
                                        <option selected value={3}>
                                            Satildi
                                        </option>
                                    ) : (
                                        <option value={3}>Satildi</option>
                                    )}
                                </select>
                            </div>
                        )}
                    </div>

                    <br />

                    <div className="d-flex umumi__veziyyet">
                        <label htmlFor="">
                            <b>Credit percent</b>
                            <select
                                onChange={(e) => {
                                    setCreditPercent(e.target.value);
                                }}
                                name=""
                                id=""
                            >
                                <option value="20">20%</option>
                                <option value="30">30%</option>
                            </select>
                        </label>
                    </div>

                    <div className="d-flex umumi__veziyyet">
                        <label htmlFor="">
                            <b>Meta title</b>
                            <input type="text" defaultValue={elan.title} onChange={(e) => setMetaTitle(e.target.value)}/>
                        </label>
                    </div>

                    <div className="d-flex umumi__veziyyet">
                        <label htmlFor="">
                            <b>Meta keyword</b>
                            <input type="text" onChange={(e) => setMetaKeyword(e.target.value)}/>
                        </label>
                    </div>

                    <div className="d-flex umumi__veziyyet">
                        <label htmlFor="">
                            <b>Meta description</b>
                            <input type="text" onChange={(e) => setMetaDesc(e.target.value)}/>
                        </label>
                    </div>

                    {elan.status !== 4 ? (
                        <>
                            <div className="operation__buttons d-flex mt-3 justify-content-center w-75">
                                <Link to={`/crm/edit-elan/${elan.id}`}>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-sm mx-3"
                                    >
                                        Dəyiş
                                    </button>
                                </Link>
                                <button
                                    onClick={deleteElan}
                                    type="button"
                                    className="btn btn-danger btn-sm mx-3"
                                >
                                    Sil
                                </button>

                                <button
                                    onClick={Deactive}
                                    type="button"
                                    className="btn btn-danger btn-sm mx-3"
                                >
                                    Deaktivləşdir
                                </button>
                            </div>

                            <div className="check__buttons w-75 d-flex mt-3 m-auto justify-content-start">
                                <div className="form-check mx-3 text-center">
                                    <input
                                        onClick={(e) => TeciliCheck(e)}
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck1"
                                    >
                                        Təcili
                                    </label>
                                </div>
                                <div className="form-check mx-3 text-center">
                                    <input
                                        onClick={(e) => BarterCheck(e)}
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck2"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck2"
                                    >
                                        Barter
                                    </label>
                                </div>
                                <div className="form-check mx-3 text-center w-25">
                                    <input
                                        onClick={(e) => NoqteCheck(e)}
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck3"
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="defaultCheck3"
                                    >
                                        100 nöqtə yoxlanışı
                                    </label>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="product-tabs mt-5">
                    <div className="tab-group">
                        <div
                            className={!active ? "tab-item" : "tab-item active"}
                            onClick={toggleClass}
                        >
                            <span>Xüsusiyyətlər </span>
                            <img src={Rightchevron} />
                        </div>
                        <div className="tab-item-info">
                            <div className="all__images w-100 mt-5">
                                {image2 && (
                                    <img
                                        src={image2}
                                        className="all__images--image"
                                        id="img01"
                                        onClick={() => PopUp(image2)}
                                    />
                                )}
                                {image3 && (
                                    <img
                                        src={image3}
                                        className="all__images--image"
                                        id="img02"
                                        onClick={() => PopUp(image3)}
                                    />
                                )}
                                {image4 && (
                                    <img
                                        src={image4}
                                        className="all__images--image"
                                        id="img03"
                                        onClick={() => PopUp(image4)}
                                    />
                                )}
                                {image5 && (
                                    <img
                                        src={image5}
                                        className="all__images--image"
                                        id="img04"
                                        onClick={() => PopUp(image5)}
                                    />
                                )}
                                {image6 && (
                                    <img
                                        src={image6}
                                        className="all__images--image"
                                        id="img05"
                                        onClick={() => PopUp(image6)}
                                    />
                                )}
                                {image7 && (
                                    <img
                                        src={image7}
                                        className="all__images--image"
                                        id="img06"
                                        onClick={() => PopUp(image7)}
                                    />
                                )}
                                {image8 && (
                                    <img
                                        src={image8}
                                        className="all__images--image"
                                        id="img07"
                                        onClick={() => PopUp(image8)}
                                    />
                                )}
                                {image9 && (
                                    <img
                                        src={image9}
                                        className="all__images--image"
                                        id="img08"
                                        onClick={() => PopUp(image9)}
                                    />
                                )}
                                {image10 && (
                                    <img
                                        src={image10}
                                        className="all__images--image"
                                        id="img09"
                                        onClick={() => PopUp(image10)}
                                    />
                                )}
                                {image11 && (
                                    <img
                                        src={image11}
                                        className="all__images--image"
                                        id="img10"
                                        onClick={() => PopUp(image11)}
                                    />
                                )}
                                {image12 && (
                                    <img
                                        src={image12}
                                        className="all__images--image"
                                        id="img11"
                                        onClick={() => PopUp(image12)}
                                    />
                                )}
                                {image13 && (
                                    <img
                                        src={image13}
                                        className="all__images--image"
                                        id="img12"
                                        onClick={() => PopUp(image13)}
                                    />
                                )}
                                {image14 && (
                                    <img
                                        src={image14}
                                        className="all__images--image"
                                        id="img13"
                                        onClick={() => PopUp(image14)}
                                    />
                                )}
                                {image15 && (
                                    <img
                                        src={image15}
                                        className="all__images--image"
                                        id="img14"
                                        onClick={() => PopUp(image15)}
                                    />
                                )}
                                {image16 && (
                                    <img
                                        src={image16}
                                        className="all__images--image"
                                        id="img15"
                                        onClick={() => PopUp(image16)}
                                    />
                                )}
                                {image17 && (
                                    <img
                                        src={image17}
                                        className="all__images--image"
                                        id="img16"
                                        onClick={() => PopUp(image17)}
                                    />
                                )}
                            </div>
                            <div
                                id="myModal"
                                className="modal"
                                style={{ display: display ? "block" : "none" }}
                            >
                                <span className="close" onClick={PopDown}>
                                    &times;
                                </span>
                                <img
                                    className="modal-content"
                                    id="modalImg"
                                    src={image}
                                />
                            </div>
                            <div className="product-tags">
                                <ul className="tags-ul">
                                    <li className="tag-li">
                                        <img src={GasStation} />
                                        {yanacaq}
                                    </li>
                                    <li className="tag-li">
                                        <img src={Calendar} />
                                        {buraxilisIli}
                                    </li>
                                    <li className="tag-li">
                                        <img src={Gasoline} />
                                        {muherrik} L
                                    </li>
                                    {/* <li className="tag-li">
                          <img src={Shifter}/>
                          {oturucu}
                      </li> */}
                                    <li className="tag-li">
                                        <img src={Speedometr} />
                                        {yurus} km
                                    </li>
                                    <li className="tag-li">
                                        <img src={Speedometr2} />
                                        {atgucu} a.g
                                    </li>
                                    <li className="tag-li">
                                        <img src={Transmission} />
                                        {oturucu}
                                    </li>
                                    <li className="tag-li">
                                        <img src={CarPainting} />
                                        {reng}
                                    </li>
                                    <li className="tag-li">
                                        <img src={TrafficJam} />
                                        {banNovu}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ProductInfoAbout dynamicFields={dynamicFields} />
                    {/* <ProductSituation dynamicFields={dynamicFields}/> */}
                </div>
            </div>
        </>
    );
};
