import * as types from './actionType'


const initialState = {
    customers:[],
    customer:{},
    elanlar : [],
    customerConnections: [],
    paginateElanlar:[],
    deyerlendirmeler : [],
    deyerlendirme : [],
    dynamicFields : [],
    randevular:[],
    elan:{},
    indisatlar:[],
    paginateIndisatlar:[],
    indisat:{},
    address:[],
    randevuConfig:[]
}

const elanReducers = (state=initialState, action: { type: any; payload:any;}) =>{
    switch (action.type) {
        case types.GET_ALL_ELAN:
            return {
                ...state,
                elanlar:action.payload
            };
        case types.GET_PAGINATE_ELAN:
            return {
                ...state,
                paginateElanlar:action.payload
            }
        case types.GET_ELAN:
            return {
                ...state,
                elan: action.payload
            }
        case types.GET_ALL_INDISAT:
            return{
                ...state,
                indisatlar: action.payload
            }
        case types.GET_INDISAT_DETAIL:
            return{
                ...state,
                indisat:action.payload
            }
        case types.GET_PAGINATE_INDISAT:
            return{
                ...state,
                paginateIndisatlar:action.payload
            }
        case types.GET_DYNAMIC_FIELDS:
            return {
                ...state,
                dynamicFields: action.payload
            }

        case types.GET_ALL_RANDEVU:
            return{
                ...state,
                randevular:action.payload
            }
        case types.GET_DEYERLENDIRMELER:
            return{
                ...state,
                deyerlendirmeler:action.payload
            }
        case types.GET_DEYERLENDIRME_DETAIL:
            return{
                ...state,
                deyerlendirme:action.payload
            }

        case types.GET_RANDEVU_ADDRESS:
            return{
                ...state,
                address:action.payload
            }
        
        case types.GET_ALL_CUSTOMERS:
            return{
                ...state,
                customers:action.payload
            }
        
        case types.GET_CUSTOMER_CONNECTIONS:
            return{
                ...state,
                customerConnections:action.payload
            }

        case types.GET_CUSTOMER_DETAIL:
            return{
                ...state,
                customer:action.payload
            }

        case types.GET_RANDEVU_CONFIG:
            return{
                ...state,
                randevuConfig:action.payload
            }

        default:{
            return state
        }
    }
}

export default elanReducers