import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getElanById } from "../../redux/actions";
import '../../styles/Product.css'
import '../../styles/ProductInfo.css'
import Agent from "../../api/agent"
import Rectangle from "../../icons/rectangle86.svg";
import Car from "../../images/car.svg";
import Sekiller from "../../images/sekiller.svg";
import Rightchevron from "../../icons/right-chevron.svg";
import { markaInter } from "./markaInterface"
import ImageUpload from "./imageUpload"
import "./style.css"
import axios from "axios";
import upload from "../../uploads/upload.svg"
import agent from "../../api/agent"
import { useNavigate } from 'react-router-dom';
 
export const EditAnnounce = () => {

    const dispatch = useDispatch ();

    const { elan, dynamicFields } = useSelector((state:any) => state.data)
    const [active, setActive] = useState(false);
    const [update, setUpdate] = useState(false)

    const [solOnQanadColor, setSolOnQanadColor] = useState('orijinal');
    const [solOnQapiColor, setSolOnQapiColor] = useState('orijinal')
    const [solArxaQapiColor, setSolArxaQapiColor] = useState('orijinal')
    const [solArxaQanadColor, setSolArxaQanadColor] = useState('orijinal')
    const [onKapotColor, setOnKapotColor] = useState('orijinal')
    const [onBuferColor, setOnBuferColor] = useState('orijinal')
    const [damColor, setDamColor] = useState('orijinal')
    const [sagArxaQanadColor, setSagArxaQanadColor] = useState('orijinal')
    const [sagOnQanadColor, setSagOnQanadColor] = useState('orijinal')
    const [sagOnQapiColor, setSagOnQapiColor] = useState('orijinal')
    const [sagArxaQapiColor, setSagArxaQapiColor] = useState('orijinal')
    const [arxaKapotColor, setArxaKapotColor] = useState('orijinal')
    const [arxaBuferColor, setArxaBuferColor] = useState('orijinal')
    const [dynamicFieldsImages, setDynamicFieldsImages] = useState<any>({})
    const [images, setImages] = useState<any>([])
    const [creditPercent, setCreditPercent]=useState("20")

     const toggleClass = () => {
        setActive(!active);
    }
  
    const {id} = useParams()
    const [marks, setMarks] = useState<markaInter[]|[]>([])
    const [models, setModels] = useState<markaInter[]|[]>([])

const modelFetch=async () => {
    if(elan.markaId){
       const Modelss=await Agent.Model_Marka.getModel(elan.markaId)
setModels(Modelss)
    }
}
useEffect(()=>{
modelFetch()

},[elan])
 
 

const  getMarka=async ()=>{
 const response= await Agent.Model_Marka.getMarka()
 setMarks(response)
 }

 
    useEffect(() => {
        dispatch(getElanById(id))
        getMarka()
     }, [])


     const [file1, setFile1] = useState<any>([]);
     const [file2, setFile2] = useState<any[]>([]);
     const [file3, setFile3] = useState<any[]>([]);
     const [file4, setFile4] = useState<any[]>([]);
     const [file5, setFile5] = useState<any[]>([]);
 
  
useEffect(() => {
    
    if(file5.length==0){
 images.forEach((element:any, index:number) => {
                   
        if(element[1]=='Image2'){
           setFile1([
             { "file":'',
              "url":"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+ element[2],
              "idd": 0}
          ])
           }
          else if(element[1]=='Image3'){
            setFile2([
              { "file":'',
               "url":"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+ element[2],
               "idd": 0}
           ])
            }
            else if(element[1]=='Image4'){
                setFile3([
                  { "file":'',
                   "url":"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+ element[2],
                   "idd": 0}
               ])
                }

             else if(+element[1].replace("Image",'')==5){
                    setFile4([
                      { "file":'',
                       "url":"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+ element[2],
                       "idd": 0}
                   ])
                    }
                    else if(+element[1].replace("Image",'')>=6) {
                        setMultiple(true)
                        let newArr=file5
                        newArr.push({ "file":'',
                        "url":"https://ferrumcapital.s3.eu-north-1.amazonaws.com"+ element[2],
                        "idd": 0})

                        setFile5([
                          ...newArr
                       ])
                        }
  });
    }

}, [images])
 
     useEffect(() => {
        const lastIndex=dynamicFields.length-1
        if(lastIndex>1){
           let imageArr:any[]=[]
        dynamicFields.forEach((element:any[], index:number)=>{
 if(element[1] && element[1].includes("Image")){
    imageArr.push(element)
 }
            })
setImages(imageArr)
        }
        }, [dynamicFields])
     
        const navigate = useNavigate();


     const [multiple, setMultiple] = useState<boolean>(false)
 
      useEffect(() => {
   setDynamicFieldsImages([...file1, ...file2, ...file3, ...file4, ...file5])
         }, [file1, file2, file3 , file4, file5]);
    // MARKA-MODEL

      // states for select options
    const [title, setTitle] = useState('')
    const [price, setPrice] = useState('')
    const [aciqlama, setAciqlama] = useState('')
    const [avtoVeziyyet, setAvtoVeziyyet] = useState('')
    const [oturucu, setOturucu] = useState('');
    const [buraxilisIli, setBuraxilisIli] = useState('')
    const [banNovu, setBanNovu] = useState('')
    const [yanacaqTipi, setYanacaqTipi] = useState('')
    const [reng, setReng] = useState('')
    const [suretlerQutusu, setSuretlerQutusu] = useState('')
    const [yurus, setYurus] = useState('')
    const [seher, setSeher] = useState('')
    const [atGucu, setAtGucu] = useState('')
    const [solOnQanad, setSolOnQanad] = useState('')
    const [solOnQapi, setSolOnQapi] = useState('')
    const [solArxaQapi, setSolArxaQapi] = useState('')
    const [solArxaQanad, setSolArxaQanad] = useState('orijinal')
    const [onKapot, setOnKapot] = useState('')
    const [onBufer, setOnBufer] = useState('')
    const [dam, setDam] = useState('')
    const [sagArxaQanad, setSagArxaQanad] = useState('')
    const [sagOnQanad, setSagOnQanad] = useState('')
    const [sagOnQapi, setSagOnQapi] = useState('')
    const [sagArxaQapi, setSagArxaQapi] = useState('')
    const [arxaKapot, setArxaKapot] = useState('')
    const [arxaBufer, setArxaBufer] = useState('')
    const [yungulLehm, setYungulLehm] = useState('false')
    const [merkeziQapanma, setMerkeziQapanma] = useState('false')
    const [deriSalon, setDeriSalon] = useState('false')
    const [oturacaqlarinVentilyasiyasi, setOturacaqlarinVentilyasiyasi] = useState('false')
    const [abs, setAbs] = useState('false')
    const [lyuk, setLyuk] = useState('false')
    const [parkRadari, setParkRadari] = useState('false')
    const [ksenonLampalar, setKsenonLampalar] = useState('false')
    const [monitor, setMonitor] = useState('false')
    const [keylessGo, setKeylessGo] = useState('false')
    const [oturacaqlarinIsidilmesi, setOturacaqlarinIsidilmesi] = useState('false')
    const [kondisioner, setKondisioner] = useState('false')
    const [arxaGoruntuKamerasi, setarxaGoruntuKamerasi] = useState('false')
    const [startStop, setStartStop] = useState('false')
    const [yagisSensoru, setYagisSensoru] = useState('false')
    const [yanPerdeler, setYanPerdeler] = useState('false')
    const [muherrikHecmi, setMuherrikHecmi] = useState('')
     const changeYungulLehm = (e:any) => {
        if (e.target.checked == true) {
            setYungulLehm('true')            
        } else {
            setYungulLehm('false')
        }
    }


    const changeMerkeziQapanma = (e:any) => {
        if (e.target.checked == true) {
            setMerkeziQapanma('true')            
        } else {
            setMerkeziQapanma('false')
        }
    }
 
    const changeDeriSalon = (e:any) => {
        if (e.target.checked == true) {
            setDeriSalon('true')
        } else {
            setDeriSalon('false')
        }
    }

    const changeOturacaqIsidilme = (e:any) => {
           if (e.target.checked == 'true') {
            setOturacaqlarinIsidilmesi('true')            
        } else {
            setOturacaqlarinIsidilmesi('false')            
        }
    }

    const changeAbs = (e:any) => {
        if (e.target.checked == true) {
            setAbs('true')            
        } else {
            setAbs('false')            
        }
    }

    const changeLyuk = (e:any) => {
        if (e.target.checked) {
            setLyuk('true')            
        } else {
            setLyuk('false')
        }
    }

    const changeParkRadari = (e:any) => {
        if (e.target.checked == true) {
            setParkRadari('true')            
        } else {
            setParkRadari('false')
        }
    }

    const changeKsenon = (e:any) => {
        if (e.target.checked == true) {
            setKsenonLampalar('true')            
        } else {
            setKsenonLampalar('false')
        }
    }
    
    const changeMonitor = (e:any) => {
         
        if (e.target.checked == true) {
            setMonitor('true')
        } else {
              setMonitor('false')          
        }
    }

    const changeKeylessGo = (e:any) => {
        if (e.target.checked == true) {
            setKeylessGo('true')
        } else {
              setKeylessGo('false')          
        }
    }

    const changeOturacaqlarinIsidilmesi = (e:any) => {
        if (e.target.checked == true) {
            setOturacaqlarinIsidilmesi('true')
        } else {
              setOturacaqlarinIsidilmesi('false')          
        }
    }

    const changeKondisioner = (e:any) => {
        if (e.target.checked == true) {
            setKondisioner('true')
        } else {
              setKondisioner('false')          
        }
    }

    const changeArxaGoruntuKamerasi = (e:any) => {
        if (e.target.checked == true) {
            setarxaGoruntuKamerasi('true')
        } else {
              setarxaGoruntuKamerasi('false')          
        }
    }

    const changeOturacaqlarinVentilyasiyasi=(e:any)=>{
         
        if (e.target.checked == true) {
            setOturacaqlarinVentilyasiyasi('true')
        } else {
              setOturacaqlarinVentilyasiyasi('false')          
        }
    }

    const changeStartStop = (e:any) => {
        if (e.target.checked == true) {
            setStartStop('true')
        } else {
              setStartStop('false')          
        }
    }

    const changeYagisSensoru = (e:any) => {
        if (e.target.checked == true) {
            setYagisSensoru('true')
        } else {
              setYagisSensoru('false')          
        }
    }

    const changeYanPerdeleri = (e:any) => {
        if (e.target.checked == true) {
            setYanPerdeler('true')
        } else {
              setYanPerdeler('false')          
        }
    }
    
    const Update = () => {
        setUpdate(true)
        setTitle(elan.title)
        setPrice(elan.price)

dynamicFields.forEach((element:any) => {
     
   if(element[1]=='Açiqlama'){
    setAciqlama(element[2])
   }
   if(element[1]=='Yürüs'){
    setYurus(element[2])
   }
   if(element[1]=='Avtomobilv?ziyy?ti'){
    setAvtoVeziyyet(element[2])
   }
   if(element[1]=='Ötürücü'){
    setOturucu(element[2])
   }

   if(element[1]=='Bannövü'){
    setBanNovu(element[2])
   }

   if(element[1]=='Yanacaqtipi'){
    setYanacaqTipi(element[2])
   }
   if(element[1]=='R?ng'){
    setReng(element[2])
   }
   if(element[1]=='Sür?tl?rqutusu'){
    setSuretlerQutusu(element[2])
   }
   if(element[1]=='S?h?r'){
    setSeher(element[2])
   }
   if(element[1]=='Atgücü'){
    setAtGucu(element[2])
   }
   if(element[1]=='Buraxilisili'){
    setBuraxilisIli(element[2])
   }
   if(element[1]=='Lyuk'){
    setLyuk(element[2])
   }
   if(element[1]=='Yanp?rd?l?r'){
    setYanPerdeler(element[2])
   }
   if(element[1]=='Oturacaqlarinisidilm?si'){
    setOturacaqlarinIsidilmesi(element[2])
   }
   if(element[1]=='ABS'){
    setAbs(element[2])
   }
   if(element[1]=='Yagissensoru'){
    setYagisSensoru(element[2])
   }
   if(element[1]=='KeylessGo')
   {
    setKeylessGo(element[2])
   }
   if(element[1]=='Oturacaqlarinventilyasiyasi')
   {
    setOturacaqlarinVentilyasiyasi(element[2])
   }
   if(element[1]=='Start/Stop')
   {
    setStartStop(element[2])
   }
   if(element[1]=='Monitor')
   {
    setMonitor(element[2])
   }
   if(element[1]=='D?risalon')
   {
    setDeriSalon(element[2])
   }
   if(element[1]=='Arxagörüntükamerasi'){
    setarxaGoruntuKamerasi(element[2])
   }
   if(element[1]=='Ksenonlampalar'){
    setKsenonLampalar(element[2])
   }

   if(element[1]=='Yüngüllehimlidiskl?r'){
    setYungulLehm(element[2])
   }
   if(element[1]=='M?rk?ziqapanma'){
    setMerkeziQapanma(element[2])
   }
   if(element[1]=='Kondisioner'){
    setKondisioner(element[2])
   }
   if(element[1]=='Parkradari'){
    setParkRadari(element[2])
   }

   if(element[1]=='Müh?rrikh?cmi'){
    setMuherrikHecmi(element[2])
   }

   if(element[1]=='Solönqanad'){
    setSolOnQanad(element[2])
    if (element[2] == 'Rəngli') {
         setSolOnQanadColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setSolOnQanadColor('deyisdirilib')
    }else{
         setSolOnQanadColor('orijinal')
    }
   }
   if(element[1]=='Solönqapi'){
    setSolOnQapi(element[2])
    if (element[2] == 'Rəngli') {
 
        setSolOnQapiColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
     
        setSolOnQapiColor('deyisdirilib')
    }else{
     
        setSolOnQapiColor('orijinal')
    }
   }
   if(element[1]=='Solarxaqapi'){
    setSolArxaQapi(element[2])
    if (element[2] == 'Rəngli') {
         setSolArxaQapiColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setSolArxaQapiColor('deyisdirilib')
    }else{
         setSolArxaQapiColor('orijinal')
    }
 
   }

   
   if(element[1]=='Önkapot'){

    if (element[2] == 'Rəngli') {
         setOnKapotColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setOnKapotColor('deyisdirilib')
    }else{
         setOnKapotColor('orijinal')
    }


    setOnKapot(element[2])
   }

   if(element[1]=='Önbufer'){
    setOnBufer(element[2])


    if (element[2] == 'Rəngli') {
         setOnBuferColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setOnBuferColor('deyisdirilib')
    }else{
         setOnBuferColor('orijinal')
    }
   }

   if(element[1]=='Dam'){
    setDam(element[2])
     if (element[2] == 'Rəngli') {
 
        setDamColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
  
        setDamColor('deyisdirilib')
    }else{
         setDamColor('orijinal')
    }
   }

   if(element[1]=='Sagarxaqanad'){
    setSagArxaQanad(element[2])
    if (element[2] == 'Rəngli') {
         setSagArxaQanadColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
 
        setSagArxaQanadColor('deyisdirilib')
    }else{
         setSagArxaQanadColor('orijinal')
    }
   }


   if(element[1]=='Sagönqapi'){
    setSagOnQapi(element[2])


    if (element[2] == 'Rəngli') {
        setSagOnQapiColor('rengli')
   }else if(element[2] == 'Dəyişdirilib'){
        setSagOnQapiColor('deyisdirilib')
   }else{
    setSagOnQapiColor('orijinal')
   }
   }


   if(element[1]=='Sagarxaqapi'){
    setSagArxaQapi(element[2])

    if (element[2] == 'Rəngli') {
         setSagArxaQapiColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setSagArxaQapiColor('deyisdirilib')
    }else{
         setSagArxaQapiColor('orijinal')
    }
   }


   if(element[1]=='Sagönqanad'){
    setSagOnQanad(element[2])

    if (element[2] == 'Rəngli') {
         setSagOnQanadColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setSagOnQanadColor('deyisdirilib')
    }else{
         setSagOnQanadColor('orijinal')
    }
   }

   if(element[1]=='Arxakapot'){
    setArxaKapot(element[2])

    if (element[2] == 'Rəngli') {
    
        setArxaKapotColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setArxaKapotColor('deyisdirilib')
    }else{
         setArxaKapotColor('orijinal')
    }
   }
   if(element[1]=='Arxabufer'){
    setArxaBufer(element[2])
    if (element[2] == 'Rəngli') {
         setArxaBuferColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
         setArxaBuferColor('deyisdirilib')
    }else{
        setArxaBufer('orijinal')
        setArxaBuferColor('orijinal')
    }
   }
 


   if(element[1]=='Solarxaqanad'){
    setSolArxaQanad(element[2])
    if (element[2] == 'Rəngli') {
         setSolArxaQanadColor('rengli')
    }else if(element[2] == 'Dəyişdirilib'){
        setSolArxaQanadColor('deyisdirilib')
    }else{
         setSolArxaQanadColor('orijinal')
    }
   }
 

});
 
        // setYungulLehm(dynamicFields[27][2])
        // setMerkeziQapanma(dynamicFields[24][2])
        // setDeriSalon(dynamicFields[21][2])
        // setOturacaqlarinVentilyasiyasi(dynamicFields[18][2])
        // setAbs(dynamicFields[15][2])
        // setLyuk(dynamicFields[12][2])
        // setParkRadari(dynamicFields[26][2])
        // setKsenonLampalar(dynamicFields[23][2])
        // setMonitor(dynamicFields[10][2])
        // setKeylessGo(dynamicFields[17][2])
        // setOturacaqlarinIsidilmesi(dynamicFields[14][2])
        // setKondisioner(dynamicFields[25][2])
        // setarxaGoruntuKamerasi(dynamicFields[22][2])
        // setStartStop(dynamicFields[19][2])
        // setYagisSensoru(dynamicFields[16][2])
        // setYanPerdeler(dynamicFields[13][2])
     }

    const changeSolOnQanadColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSolOnQanad('Rəngli')
            setSolOnQanadColor('rengli')
        }else if(e.target.className == 'rengli'){
            setSolOnQanadColor('deyisdirilib')
            setSolOnQanad('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSolOnQanadColor('orijinal')
            setSolOnQanad('orijinal')
        }
    }

    const changeSolOnQapiColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSolOnQapiColor('rengli')
            setSolOnQapi('Rəngli')
        }else if(e.target.className == 'rengli'){
            setSolOnQapiColor('deyisdirilib')
            setSolOnQapi('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSolOnQapiColor('orijinal')
            setSolOnQapi('orijinal')
            
        }
    }

    const changeSolArxaQapiColor = (e:any) => {
         
        if (e.target.className == 'orijinal') {
            setSolArxaQapiColor('rengli')
            setSolArxaQapi("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSolArxaQapiColor('deyisdirilib')
            setSolArxaQapi("Dəyişdirilib")
        }else if(e.target.className == 'deyisdirilib'){
            setSolArxaQapiColor('orijinal')
            setSolArxaQapi('orijinal')
        }
    }
    //  burdan basladi
    const changeSolArxaQanadColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSolArxaQanadColor('rengli')
            setSolArxaQanad("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSolArxaQanadColor('deyisdirilib')
            setSolArxaQanad('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSolArxaQanadColor('orijinal')
            setSolArxaQanad('orijinal')
        } 
    }

    const changeOnKapotColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setOnKapotColor('rengli')
            setOnKapot("Rəngli")
        }else if(e.target.className == 'rengli'){
            setOnKapotColor('deyisdirilib')
            setOnKapot('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setOnKapotColor('orijinal')
            setOnKapot('orijinal')
        } 
    }

    const changeOnBuferColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setOnBuferColor('rengli')
            setOnBufer("Rəngli")
        }else if(e.target.className == 'rengli'){
            setOnBuferColor('deyisdirilib')
            setOnBufer('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setOnBuferColor('orijinal')
            setOnBufer('orijinal')
        } 
    }

    const changeDamColor = (e:any) => {
         if (e.target.className == 'orijinal') {
            setDamColor('rengli')
            setDam("Rəngli")
        }else if(e.target.className == 'rengli'){
            setDamColor('deyisdirilib')
            setDam('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setDamColor('orijinal')
            setDam('orijinal')
        }
    }
    
    const changeSagArxaQanadColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSagArxaQanadColor('rengli')
            setSagArxaQanad("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSagArxaQanadColor('deyisdirilib')
            setSagArxaQanad('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSagArxaQanadColor('orijinal')
            setSagArxaQanad('orijinal')
        }
    }

    const changeSagOnQanadColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSagOnQanadColor('rengli')
            setSagOnQanad("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSagOnQanadColor('deyisdirilib')
            setSagOnQanad('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSagOnQanadColor('orijinal')
            setSagOnQanad('orijinal')
        }
    }
     const changeSagOnQapiColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSagOnQapiColor('rengli')
            setSagOnQapi("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSagOnQapiColor('deyisdirilib')
            setSagOnQapi('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSagOnQapiColor('orijinal')
            setSagOnQapi('orijinal')
        }
    }

    const changeSagArxaQapiColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setSagArxaQapiColor('rengli')
            setSagArxaQapi("Rəngli")
        }else if(e.target.className == 'rengli'){
            setSagArxaQapiColor('deyisdirilib')
            setSagArxaQapi('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setSagArxaQapiColor('orijinal')
            setSagArxaQapi('orijinal')
        }
    }

    const changeArxaKapotColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setArxaKapotColor('rengli')
            setArxaKapot("Rəngli")
        }else if(e.target.className == 'rengli'){
            setArxaKapotColor('deyisdirilib')
            setArxaKapot('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setArxaKapotColor('orijinal')
            setArxaKapot('orijinal')
        }
    }
    
    const changeArxaBuferColor = (e:any) => {
        if (e.target.className == 'orijinal') {
            setArxaBuferColor('rengli')
            setArxaBufer("Rəngli")
        }else if(e.target.className == 'rengli'){
            setArxaBuferColor('deyisdirilib')
            setArxaBufer('Dəyişdirilib')
        }else if(e.target.className == 'deyisdirilib'){
            setArxaBuferColor('orijinal')
            setArxaBufer('orijinal')
        }
    }
    
     const changeTitle = (e:any) => {
        setTitle(e.target.value)
    }
    

    const changePrice = (e:any) => {
        setPrice(e.target.value)
    }

    const [markaa, setmarkaa] = useState('')
    const [modelll, setmodelll] = useState('')

    useEffect(() => {
        marks.map((mark)=>{
            if(mark.id==elan.markaId){
               setmarkaa(mark.name)
            }
        })
        
        models.map((model)=>{
            if(model.id==elan.modelId){
               setmodelll(model.name)
            }
        })
        
    }, [models])
      
    const yaddaSaxla =async () => {
         const wholeFiles=[...file1, ...file2, ...file3, ...file4, ...file5]
         let failedImages=false
         const imagesLinks:string[]=[]
         let dynamicEndString=''
let count=0
         wholeFiles.forEach(async (image:any, index:number)=>{
if(image['file']){
    let formData = new FormData()
    formData.append("image", image.file as any);
    const response=await agent.Elan.ElanPictureAdd(formData)
    if(response){
       imagesLinks.push(response)
       dynamicEndString=dynamicEndString + `${10128+index}:Image${index+2}:${response}:2;`
         }else{
     }
     count ++
}else{
    let {url}=image
    url=url.replace("https://ferrumcapital.s3.eu-north-1.amazonaws.com", "")
    dynamicEndString=dynamicEndString + `${10128+index}:Image${index+2}:${url}:2;`
    count ++
 }
            if(count==wholeFiles.length){
       setTimeout(async ()=>{
         dynamicEndString=dynamicEndString.substring(0, dynamicEndString.length - 1)
          let data = {
            "id":Number(id),
            "recordStatus":0,
            "createAt":elan.createAt,
            "userId":elan.userId,
            "apiKey":elan.apiKey,
            "fullName":elan.fullName,
            "phoneNumber":elan.phoneNumber,
            "modelId":Number(elan.modelId),
            "title":title,
            "price":Number(price),
            "dynamicFields":`10108:Açiqlama:${aciqlama}:1;10112:Yürüs:${yurus}:2;10107:Ötürücü:${oturucu}:4;10110:Bannövü:${banNovu}:4;10105:Yanacaqtipi:${yanacaqTipi}:4;10113:R?ng:${reng}:4;10106:Sür?tl?rqutusu:${suretlerQutusu}:4;10111:Atgücü:${atGucu}:2;10104:Buraxilisili:${buraxilisIli}:2;10163:Lyuk:${lyuk}:6;10173:Yanp?rd?l?r:${yanPerdeler}:6;10168:Oturacaqlarinisidilm?si:${oturacaqlarinIsidilmesi}:6;10162:ABS:${abs}:6;10172:Yagissensoru:${yagisSensoru}:6;10167:KeylessGo:${keylessGo}:6;10161:Oturacaqlarinventilyasiyasi:${oturacaqlarinIsidilmesi}:6;10171:Start/Stop:${startStop}:6;10166:Monitor:${monitor}:6;10160:D?risalon:${deriSalon}:6;10170:Arxagörüntükamerasi:${arxaGoruntuKamerasi}:6;10165:Ksenonlampalar:${ksenonLampalar}:6;10159:M?rk?ziqapanma:${merkeziQapanma}:6;10169:Kondisioner:${kondisioner}:6;10164:Parkradari:${parkRadari}:6;10158:Yüngüllehimlidiskl?r:${yungulLehm}:6;10144:Solönqanad:${solArxaQanad}:4;10145:Solönqapi:${solOnQapi}:4;10108:Solarxaqanad:${solArxaQanad}:1;10146:Solarxaqapi:${solArxaQapi}:4;10147:Önkapot:${onKapot}:4;10148:Önbufer:${onBufer}:4;10149:Dam:${dam}:4;10150:Sagarxaqanad:${sagArxaQanad}:4;10151:Sagönqapi:${sagOnQapi}:4;10152:Sagarxaqapi:${sagArxaQapi}:4;10153:Sagönqanad:${sagOnQanad}:4;10154:Arxakapot:${arxaKapot}:4;10155:Arxabufer:${arxaBufer}:4;10156:Mühərrik:Orijinal:4;`,
            "status":Number(elan.status)
        }
 
data.dynamicFields+=dynamicEndString

        axios.put("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", data, {
            headers:{
                "api-key":"e4246c59-4357-4c5e-a5a9-af4307c4f751"
            }
        })
        .then((res)=>{
            navigate('/crm/elan/'+id);
          })
 
         }, 500)
            }
     
          })
 
if(images.length==0){
    let data = {
        "id":Number(id),
        "recordStatus":0,
        "createAt":elan.createAt,
        "userId":elan.userId,
        "apiKey":elan.apiKey,
        "fullName":elan.fullName,
        "phoneNumber":elan.phoneNumber,
        "modelId":Number(elan.modelId),
        "title":title,
        "price":Number(price),
        "dynamicFields":`10108:Açiqlama:${aciqlama}:1;10112:Yürüs:${yurus}:2;10107:Ötürücü:${oturucu}:4;10110:Bannövü:${banNovu}:4;10105:Yanacaqtipi:${yanacaqTipi}:4;10113:R?ng:${reng}:4;10106:Sür?tl?rqutusu:${suretlerQutusu}:4;10111:Atgücü:${atGucu}:2;10104:Buraxilisili:${buraxilisIli}:2;10163:Lyuk:${lyuk}:6;10173:Yanp?rd?l?r:${yanPerdeler}:6;10168:Oturacaqlarinisidilm?si:${oturacaqlarinIsidilmesi}:6;10162:ABS:${abs}:6;10172:Yagissensoru:${yagisSensoru}:6;10167:KeylessGo:${keylessGo}:6;10161:Oturacaqlarinventilyasiyasi:${oturacaqlarinIsidilmesi}:6;10171:Start/Stop:${startStop}:6;10166:Monitor:${monitor}:6;10160:D?risalon:${deriSalon}:6;10170:Arxagörüntükamerasi:${arxaGoruntuKamerasi}:6;10165:Ksenonlampalar:${ksenonLampalar}:6;10159:M?rk?ziqapanma:${merkeziQapanma}:6;10169:Kondisioner:${kondisioner}:6;10164:Parkradari:${parkRadari}:6;10158:Yüngüllehimlidiskl?r:${yungulLehm}:6;10144:Solönqanad:${solArxaQanad}:4;10145:Solönqapi:${solOnQapi}:4;10108:Solarxaqanad:${solArxaQanad}:1;10146:Solarxaqapi:${solArxaQapi}:4;10147:Önkapot:${onKapot}:4;10148:Önbufer:${onBufer}:4;10149:Dam:${dam}:4;10150:Sagarxaqanad:${sagArxaQanad}:4;10151:Sagönqapi:${sagOnQapi}:4;10152:Sagarxaqapi:${sagArxaQapi}:4;10153:Sagönqanad:${sagOnQanad}:4;10154:Arxakapot:${arxaKapot}:4;10155:Arxabufer:${arxaBufer}:4;10156:Mühərrik:Orijinal:4;`,
        "status":Number(elan.status)
    }
  
    axios.put("https://otomallbusiness.digimall.az/api/Elan/UpdateElan", data )
    .then((res)=>{
        console.log(res, 'resssss');
     })



}


       
    }

const uploadImage = (e:any) => {
  console.log(e.target.files[0]);
  const formData = new FormData();
  formData.append("file", e.target.files[0])
  fetch('https://otomallbusiness.digimall.az/api/Image/UploadImageViaFtp',{
      method:"POST",
      headers:{
        'Content-Type': 'multipart/form-data',
        'api-key':'e4246c59-4357-4c5e-a5a9-af4307c4f751'
    },
    body:JSON.stringify(formData)
  }).then(res=>res.json()).then(data=>console.log('is image uploaded',data))
};

    
    
      

    return (
        <>
            <div className='col-lg-10 col-12 p-0'>
                <div className="product-info-container">
                <div className="info-top">
                    <h2 className="product-name">{elan.title}</h2>
                    <div className="product-price">
                        <img src={Rectangle}/>
                        <p className="product-price">{elan.price}
                        <span className="currency-span">AZN</span>
                        </p>
                    </div>
                </div>

                <div className="datetime mb-5">
                    {/* <p> 03.12.2021 11:59 </p> */}
                </div>
                {!update && (
                    <button className='btn btn-danger w-100' onClick={Update} type="submit">Düzəliş et</button>

                )}
                
                {update && (

                <>

                <div className="marka">
                   
                    <div className="sekiller mt-3">
                    <div className="bg-light mb-3">
                        <img src={Sekiller} alt="car.svg"/>
                        <b> Şəkillər </b>
                    </div>
                        <b> Minimum - 3 şəkil, maks - 16 şəkil (ön, arxa, bütöv ön panelin görüntüsü mütləqdir). Optimal ölçü - 1024x768 piksel </b>

                        <div className="cars__png">
<div className="image-upload-container">
<div className="image-upload-content">
<div className="flexbox">
  <div className="file-img first">
                    <label htmlFor="11" className="sample-img">
                    <div className="upload-icon" >
                      <img src={upload}/>
                    </div>
                    </label>
                     <ImageUpload id="11" file={file1} setFile={setFile1}/>
                 </div>  

 
                 <div className="file-img second">
                    <label htmlFor="2" className="sample-img">
                    <div className="upload-icon">
                      <img src={upload}/>
                    </div>
                    </label>
                    <ImageUpload id="2" file={file2} setFile={setFile2} />
                 </div>

                  <div className="file-img third">
                   <label htmlFor="3" className="sample-img">
                   <div className="upload-icon">
                      <img src={upload}/>
                    </div>
                   </label>
                   <ImageUpload id="3" file={file3} setFile={setFile3} />
                 </div>
                 <div className="file-img fourth">
                   <label htmlFor="4" className="sample-img">
                   <div className="upload-icon">
                      <img src={upload}/>
                    </div>
                   </label>
                   <ImageUpload id="4" file={file4} setFile={setFile4} />
                 </div>


</div>


{console.log(file5, 'file5 555')
}


{multiple && <div className="multiple-container">
                 <ImageUpload id="5" file={file5} setFile={setFile5} multiple/>
                </div>}
            

                <div className="file-img multiple">
                    <label htmlFor="5" className="sample-img">

                    <div className="upload-icon" onClick={() => {
                     if(file1.length !== 0 && file2.length !== 0 && file3.length !== 0 && file4.length !== 0){
                      setMultiple(true)
                    }else{
                      setMultiple(false)
                    }
                   }}>
                      <img src={upload}/>
                    </div>

                   </label>

                    <p>Əlavə şəkil yüklə</p>
                </div>
 </div>
</div>
                        </div>
                    </div>

                    <div className="car__info my-3">
                        <img src={Car} alt="car.svg"/>
                        <b> Avtomobil məlumatları </b>

                        
                    </div>

                 
                        <b> Avtomobilin rəngi, mühərriki, yanacaq tipi və digər xüsusiyyətlərini əskiksiz daxil etməkç satış potensialını da artırır </b>
                        <br />
                        



<br />
                  
<br /> 


                        <div className="car__info">
                            <div className="d-flex umumi__veziyyet">
                                <label htmlFor=""><b>Elan Başlığı</b>
                                <input type="text" name="" id="elan_basligi" placeholder='ELan Başlığı' value={markaa + ' ' + modelll} onChange={(e)=>changeTitle(e)}/>
                                </label>
                                <label htmlFor=""><b>Qiymət</b>
                                <input type="text"  value={price} placeholder='Qiymət' onChange={(e)=>changePrice(e)}/>
                                </label>
                            </div>
                            <label htmlFor="">Açıqlama
                            <textarea name="" value={aciqlama} className='my-1' onChange={(e)=>setAciqlama(e.target.value)} placeholder='Açıqlama' cols={158} rows={10}/>
                            </label>

                            <div className="d-flex umumi__veziyyet my-1">
                                <label id="elan_basligi" htmlFor=""> <b>Avtomobilin vəziyyəti</b>
                                <input type="text" id="elan_basligi" placeholder='Avtomobilin vəziyyəti' value={avtoVeziyyet} onChange={(e)=>setAvtoVeziyyet(e.target.value)}/>
                                </label>

                                <label htmlFor=""><b>Ötürücü</b>
                                <input type="text" placeholder='Ötürücü' value={oturucu} onChange={(e)=>setOturucu(e.target.value)}/>
                                </label>
                            </div>
                            <div className="d-flex umumi__veziyyet my-1">
                                <label id="elan_basligi" htmlFor=""><b>Buraxılış ili</b>
                                <input type="text" id="elan_basligi" placeholder='Buraxılış ili' value={buraxilisIli} onChange={(e)=>setBuraxilisIli(e.target.value)}/>
                                </label>
                                <label htmlFor=""><b>Ban növü</b>
                                <input type="text" placeholder='Ban növü' value={banNovu} onChange={(e)=>setBanNovu(e.target.value)}/>
                                </label>
                            </div>
                            <div className="d-flex umumi__veziyyet my-1">
                                <label id="elan_basligi" htmlFor=""><b>Yanacaq tipi</b>
                                <input type="text" id="elan_basligi" placeholder='Yanacaq tipi' value={yanacaqTipi} onChange={(e)=>setYanacaqTipi(e.target.value)}/>
                                </label>
                                <label htmlFor=""><b>Rəng</b>
                                <input type="text" placeholder='Rəng' value={reng} onChange={(e)=>setReng(e.target.value)}/>
                                </label>
                            </div>
                            <div className="d-flex umumi__veziyyet my-1">
                                <label id="elan_basligi" htmlFor=""><b>Sürətlər qutusu</b>
                                <input type="text" id="elan_basligi" placeholder='Sürətlər qutusu' value={suretlerQutusu} onChange={(e)=>setSuretlerQutusu(e.target.value)}/>
                                </label>
                                <label htmlFor=""><b>Yürüş</b>
                                <input type="text" placeholder='Yürüş' value={yurus} onChange={(e)=>setYurus(e.target.value)}/>
                                </label>
                            </div>
                            <div className="d-flex umumi__veziyyet my-1">
                                <label id="elan_basligi" htmlFor=""><b>Şəhər</b>
                                <input type="text" id="elan_basligi" placeholder='Şəhər' value={seher} onChange={(e)=>setSeher(e.target.value)}/>
                                </label>
                                <label htmlFor=""><b>At gücü</b>
                                <input type="text" placeholder='At gücü' value={atGucu} onChange={(e)=>setAtGucu(e.target.value)}/>
                                </label>
                            </div>


                            <div className="d-flex umumi__veziyyet my-1">
                                 <label htmlFor=""><b>Muherrik hecmi</b>
                                <input type="text" placeholder='Muherrik hecmi' value={muherrikHecmi} onChange={(e)=>setMuherrikHecmi(e.target.value)}/>
                                </label>
                            </div>
                        </div>

                </div>

                <div className="tab-group">
                <div className={!active ? "tab-item" : "tab-item active"} onClick={toggleClass}>
                        <span>Vəziyyəti  </span>
                        <img src={Rightchevron}/>
                    </div>
                    <div className="tab-item-info">
                    
                    <div className="color-tags-container">
                        <ul className="color-tags">
                             <li className={solOnQanadColor} onClick={(e)=>changeSolOnQanadColor(e)}> Sol ön qanad </li>
                            <li className={solOnQapiColor} onClick={(e)=>changeSolOnQapiColor(e)}>Sol ön qapı</li>
                            <li className={solArxaQapiColor} onClick={(e)=>changeSolArxaQapiColor(e)}>Sol arxa qapı</li>
                            <li className={solArxaQanadColor} onClick={(e)=>changeSolArxaQanadColor(e)}>Sol arxa qanad</li>
                            <li className={onKapotColor} onClick={(e)=>changeOnKapotColor(e)}>Ön kapot</li>
                            <li className={onBuferColor} onClick={(e)=>changeOnBuferColor(e)}>Ön bufer</li>
                            <li className={damColor} onClick={(e)=>changeDamColor(e)}>Dam</li>
                            <li className={sagArxaQanadColor} onClick={(e)=>changeSagArxaQanadColor(e)}>Sağ arxa qanad</li>
                            <li className={sagOnQanadColor} onClick={(e)=>changeSagOnQanadColor(e)}>Sağ ön qanad</li>
                            <li className={sagOnQapiColor} onClick={(e)=>changeSagOnQapiColor(e)}>Sağ ön qapı</li>
                            <li className={sagArxaQapiColor} onClick={(e)=>changeSagArxaQapiColor(e)}>Sağ arxa qapı</li>
                            <li className={arxaKapotColor} onClick={(e)=>changeArxaKapotColor(e)}>Arxa kapot</li>
                            <li className={arxaBuferColor} onClick={(e)=>changeArxaBuferColor(e)}>Arxa bufer</li>
                        </ul>
                    </div>
                    </div>
                </div>

                <div className="xususiyyetler my-3">
                    <img src={Car} alt="car.svg"/>
                    <b> Xüsusiyyətlər  </b>
                </div>
                <b> Avtomobilinizin xüsusiyyətləri əksiksiz olaraq tamamlayaraq eyni model digər avtomobillərdən fərqinizi göstərmiş olarsınız. </b>

                <div className="d-flex justify-content-between mt-3">
                    <div className="flex__1">
                    <div className="form-check form-switch w-100 mt-2">
                        {yungulLehm == 'true' ? (
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYungulLehm(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYungulLehm(e)}/>
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Yüngül lehimli disklər </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {merkeziQapanma == 'true' ? (
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeMerkeziQapanma(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeMerkeziQapanma(e)} />
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Mərkəzi qapanma </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {deriSalon == 'true' ? (
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeDeriSalon(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeDeriSalon(e)}/>
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Dəri salon </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            oturacaqlarinVentilyasiyasi == 'true' ? (
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeOturacaqlarinVentilyasiyasi(e)} checked />
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeOturacaqlarinVentilyasiyasi(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Oturacaqların ventilyasiyası </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {abs == 'true' ? (
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeAbs(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeAbs(e)}/>
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> ABS </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {lyuk == 'true' ? (
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeLyuk(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeLyuk(e)}/>
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Lyuk </label>
                    </div>
                    
                    </div>
                    <div className="flex__2">
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            parkRadari == 'true' ? (
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeParkRadari(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeParkRadari(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Park radarı</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            ksenonLampalar == 'true' ?(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKsenon(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKsenon(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Ksenon lampalar</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {monitor == 'true'?(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeMonitor(e)} checked/>
                        ):(
                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeMonitor(e)}/>
                        )}
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Monitor </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            keylessGo == 'true' ? (
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKeylessGo(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKeylessGo(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Keyless Go </label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            oturacaqlarinIsidilmesi == 'true' ?(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeOturacaqlarinIsidilmesi(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeOturacaqlarinIsidilmesi(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1"> Oturacaqların isidilməsi </label>
                    </div>
                        
                    </div>
                    <div className="flex__3">
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            kondisioner == 'true' ?(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKondisioner(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeKondisioner(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Kondisioner</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            arxaGoruntuKamerasi == 'true' ?(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeArxaGoruntuKamerasi(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeArxaGoruntuKamerasi(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Arxa görüntü kamerası</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            startStop == 'true' ? (
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeStartStop(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeStartStop(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Start/Stop</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            yagisSensoru == 'true' ?(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYagisSensoru(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYagisSensoru(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Yağış sensoru</label>
                    </div>
                    <div className="form-check form-switch w-100 mt-2">
                        {
                            yanPerdeler == 'true' ? (
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYanPerdeleri(e)} checked/>
                            ):(
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" onClick={(e)=>changeYanPerdeleri(e)}/>
                            )
                        }
                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault1">Yan pərdələr</label>
                    </div>
                    </div>
                </div>
                    <div className="text-center">
                <button type="submit" className='btn btn-danger my-5' onClick={yaddaSaxla}> Yadda saxla </button>

                    </div>
                
                </>

                )}

            </div>
            </div>
        </>
    )
}
