import React, {useEffect, useState} from 'react'
import '../../styles/operation.css'
import { OperationsPopUp } from "../OperationsPopUp";
import { DateFormat } from "../DateFormat";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers, getTodayCustomers } from "../../redux/actions";
import { Link } from "react-router-dom";
import {
    ExcelExport,
    ExcelExportColumn,
    ExcelExportColumnGroup,
    } from "@progress/kendo-react-excel-export";
    
    import {
    aggregateBy,
    process,
    GroupDescriptor,
    AggregateDescriptor,
    } from "@progress/kendo-data-query";

    import {dateSplitter, changeDateInArray} from "../functions"
import Loader from '../Loader';
export const Customers = () => {

    let dispatch = useDispatch();

    const {customers} = useSelector((state:any) => state.data)

    const [all, setAll] = useState(true)
    const [today, setToday] = useState(false)

    useEffect(() => {
        dispatch(getAllCustomers())
        return () => {
         }
    }, [])

    const getTodayCustomerApi = () => {
        setAll(false)
        setToday(true)
        dispatch(getTodayCustomers())
    }
    
    const getAllCustomerApi = () => {
        setAll(true)
        setToday(false)
        dispatch(getAllCustomers())
    }

    const data = process((customers.length>0 && changeDateInArray(customers)), {
    }).data;


    const _exporter = React.createRef<ExcelExport>();
    const exportExcel = () => {
        if (_exporter.current) {
        _exporter.current.save();
        }
    };

    console.log(customers)

    return (
        <>
            <div className="col-lg-10 col-12 p-0 table-responsive">
                <span className="title"> Müştərilər</span>
                <div className="nav-small">
                    <ul className="d-flex p-0 flex-wrap">
                        <li className={all ? 'active_nav' : ''} onClick={getAllCustomerApi}>
                            All
                        </li>
                        <li className={today ? 'active_nav' : ''} onClick={getTodayCustomerApi}>
                            Today
                        </li>
                        <div>
                            <button className="k-button" onClick={exportExcel}>
                                Export to Excel
                            </button>

                            <ExcelExport
                                data={data}
                                fileName="Müştərilər.xlsx"
                                ref={_exporter}
                            >
                                <ExcelExportColumn
                                field="id"
                                title="ID"
                                locked={true}
                                width={60}
                                />
                                <ExcelExportColumn
                                field="name"
                                title="Ad"
                                width={100}
                                />
                                <ExcelExportColumn
                                    field="cellPhone"
                                    title="Telefon nömrəsi"
                                    cellOptions={{ format: "$#,##0.00" }}
                                    width={150}
                                    footerCellOptions={{ wrap: true, textAlign: "center" }}
                                    groupFooterCellOptions={{ textAlign: "right" }}
                                />
                                <ExcelExportColumn field="email" title="Email" width={200}/>
                                <ExcelExportColumn field="createAt" title="createAt" width={100} />
                                <ExcelExportColumn
                                field="Discontinued"
                                title="Discontinued"
                                hidden={true}
                                />
                            </ExcelExport>
                        </div>
                    </ul>
                </div>
                <table className="table table-sm table-bordered text-center">
                    <thead>
                    <tr>
                        <th scope="col">№</th>
                        <th scope="col">Adı, Soyadı</th>
                        <th scope="col">Telefon nömrəsi</th>
                        <th scope="col">Email adresi</th>
                        <th scope="col">Qeydiyyat tarixi</th>
                        <th scope="col">Əməliyyatlar</th>
                    </tr>
                    </thead>
                    <tbody>
   
                        {customers && customers !== "empty" && customers.length > 0 ? customers.map((customer:any,index:any)=>(
                            <tr key={index}>
                                <th scope="row">{customer.id}</th>
                                <td>{customer.name} {customer.family}</td>
                                <td>{customer.cellPhone}</td>
                                <td>{customer.email}</td>
                                <td>
                                    <DateFormat date={customer.createAt}/>
                                 </td>
                                <td>
                                    <Link to={`/crm/customer/${customer.userId}`} style={{color:'#212529', textDecoration:'none'}}>
                                        <i className="fas fa-user-cog"></i>
                                    </Link>
                                </td>
                            </tr>
                        )):<Loader/>}
                    </tbody>
                </table>
            </div>
            
        </>
    )
}
